import { GetListParams, GetListResult } from 'react-admin';
import { getQueryString } from '../../dataProvider/utils';
import { ResourceHandlerContext } from '../../dataProvider/types';
import { ApiCardHolder, CardHolder } from '.';

export async function getList(context: ResourceHandlerContext, companyId: string, params: GetListParams): Promise<GetListResult<CardHolder>> {
  const filterQueryParams = [];
  const tags: string[] = params?.filter?.tags?.trim().split(' ');

  if (tags) {
    for (const tag of tags) {
      const trimmedTag = tag.trim();
      if (trimmedTag) {
        filterQueryParams.push('tags=' + trimmedTag);
      }
    }
  }

  const nameStartsWith = params?.filter?.nameStartsWith?.trim();
  if (nameStartsWith) {
    filterQueryParams.push('nameStartsWith=' + nameStartsWith);
  }

  const surnameStartsWith = params?.filter?.surnameStartsWith?.trim();
  if (surnameStartsWith) {
    filterQueryParams.push('surnameStartsWith=' + surnameStartsWith);
  }

  const url = `companies/${companyId}/card-holders` + getQueryString(params?.pagination, filterQueryParams);
  const { json } = await context.request(url, {});
  const response: { total: number; cardHolders: ApiCardHolder[] } = json;
  return {
    total: response.total,
    data: response.cardHolders.map((c) => {
      return { ...c, tags: c.tags || [] };
    }),
  };
}

export async function requestExport(context: ResourceHandlerContext, companyId: string): Promise<Record<string, any>> {
  const { json } = await context.request(`companies/${companyId}/jobs/export-card-holders`, {
    method: 'POST',
  });

  return { data: json };
}
