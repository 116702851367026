import { AppBar, TitlePortal, useAuthProvider, useGetList, usePermissions } from 'react-admin';
import Box from '@mui/material/Box';

import { Logo } from './logo';
import { Impersonation } from './Impersonation';
import { useEffect, useState } from 'react';

export const KuruAppBar = () => {
  const { permissions } = usePermissions() || {};
  const { data } = useGetList('companies');
  const authProvider = useAuthProvider();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthStatus = async () => {
      if (!authProvider) return;

      try {
        await authProvider.checkAuth({});
        setIsAuthenticated(true);
      } catch {
        setIsAuthenticated(false);
      }
    };

    checkAuthStatus().catch(console.error);
  }, [authProvider]);

  return (
    <AppBar
      sx={{
        img: { display: 'block' },
        '.RaAppBar-menuButton': { display: 'none' },
      }}
    >
      <Logo />

      <TitlePortal
        sx={{
          marginLeft: 1,
        }}
      />
      <Box flex="1" />
      {isAuthenticated && !permissions?.is_kuru_admin && data && data.length > 1 ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
          <Impersonation />
        </Box>
      ) : null}
      <Box flex="1" />
    </AppBar>
  );
};
