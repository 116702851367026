import { DateTimeInput, DateTimeInputProps, SelectInput, SelectInputProps, TextInput, TextInputProps, useTranslate } from 'react-admin';

import { TransactionType } from '../resources/transactions';

export const CardHolderTagsFilter = (props: TextInputProps) => {
  const { source, ...otherProps } = props;
  return <TextInput {...otherProps} label="Etiquetas de colaborador/a" source={source} resettable={true} />;
};

export const TxTypeFilter = (props: SelectInputProps & { values: TransactionType[] }) => {
  const translate = useTranslate();
  const { values, ...defaultProps } = props;

  const choices = values.map((type) => ({
    id: type,
    name: translate('resources.transactions.type.' + type),
  }));

  return <SelectInput {...defaultProps} choices={choices} source="type" label="Tipo" />;
};

export const DateTimeFilter = (props: DateTimeInputProps) => {
  // NOTE: source is not used, but React Admin needs filters to have the prop to set them up properly
  const { source, ...otherProps } = props;

  const styles = {
    '&.MuiFormControl-root': {
      marginRight: '0.75rem',
    },
  };

  return (
    <>
      <DateTimeInput {...otherProps} source="fromDate" label="Desde" sx={styles} />
      <DateTimeInput {...otherProps} source="toDate" label="Hasta" />
    </>
  );
};
