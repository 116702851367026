import { usePermissions, useRedirect } from 'react-admin';

import { isKuruUser } from '../utils';
import { isImpersonating } from './Impersonation';
import ClientUserDashboard from './clientUserDashboard';
import { useEffect } from 'react';

export const Dashboard = () => {
  const { permissions } = usePermissions() || {};
  const currentIsKuruUser = permissions && isKuruUser(permissions);
  const isCurrentlyImpersonating = isImpersonating();

  if (!permissions) return null;

  return <>{currentIsKuruUser && !isCurrentlyImpersonating ? <KuruAdminDashboard /> : <ClientUserDashboard />}</>;
};

const KuruAdminDashboard = () => {
  const redirect = useRedirect();

  useEffect(() => {
    redirect('list', 'companies');
  });

  return <></>;
};
