import SvgIcon from '@mui/material/SvgIcon';

export default function KuruArrowIcon() {
  return (
    <SvgIcon>
      <svg width="18" height="25" viewBox="0 0 18 25" xmlns="http://www.w3.org/2000/svg" style={{ fill: 'currentColor' }}>
        <path d="M0 0.513672H10.9481V10.7065L8.37808 8.39784L2.88949 12.6811C2.88949 12.6811 0.900244 14.1621 0.798604 16.6305C0.696963 19.0988 0.798604 7.75897 0.798604 7.75897C0.798604 7.75897 0.566283 5.24707 2.67169 3.02557L0 0.513672Z" />
        <path d="M4.60237 13.0008L17.6849 24.9941H7.02722L3.67308 21.8869C2.6712 21.0157 2.0904 19.796 2.0178 18.5328C1.91616 16.8486 2.25012 14.598 4.60237 12.9863V13.0008Z" />
      </svg>
    </SvgIcon>
  );
}
