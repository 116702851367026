export type Card = {
  id: string;
  cardHolderId: string;
  pomeloId: string;
  balance: number;
  isActive: boolean;
  isPomeloActive: boolean;
  lastFour: string;
  ruleId: string;
  type: CardType;
};

export enum CardType {
  VIRTUAL = 'virtual',
  PHYSICAL = 'physical',
}
