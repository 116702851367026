import {
  Datagrid,
  EmailField,
  List,
  Show,
  TopToolbar,
  SimpleForm,
  TextInput,
  Create,
  required,
  email,
  SelectInput,
  Labeled,
  useNotify,
  useRefresh,
  useDataProvider,
  FunctionField,
  Confirm,
  regex,
} from 'react-admin';

import { Stack, Box } from '@mui/system';

import { KuruCreateToolbar } from '../components/toolbars';
import { getErrorHandler, regexesConfig } from '../utils';
import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

export type Administrator = {
  id: string;
  email: string;
  surname: string;
  companyId: string;
  role: string;
};

export type AppMetaData = {
  version: '1.0';
  is_kuru_admin: boolean;
  company_access: Record<string, AdminRole>;
};

export enum AdminRole {
  ADMIN = 'Admin',
  MANAGER = 'Manager',
  CONTROLLER = 'Controller',
}

export type ApiAdministrator = {
  user_id: string;
  email: string;
  app_metadata: AppMetaData;
};

export const AdministratorList = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState<Administrator | null>(null);

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>, admin: Administrator) => {
    event.stopPropagation();
    setSelectedAdmin(admin);
    setShowDeleteConfirmation(true);
  };

  const handleClose = () => {
    setShowDeleteConfirmation(false);
    setSelectedAdmin(null);
  };

  const handleConfirmDelete = async () => {
    if (!selectedAdmin) return;
    try {
      await dataProvider.delete('administrators', { id: selectedAdmin.id });
      notify('Administrador eliminado con éxito');
      refresh();
    } catch (_) {
      notify('Error al eliminar el administrador', { type: 'warning' });
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <List queryOptions={{ onError: getErrorHandler(notify) }} exporter={false}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
          <EmailField source="email" sortable={false} />
          <FunctionField
            source="id"
            label="Acciones"
            sortable={false}
            render={(record) => (
              <Tooltip title="Eliminar administrador">
                <IconButton onClick={(event) => handleDeleteClick(event, record)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          />
        </Datagrid>
      </List>
      <Confirm
        isOpen={showDeleteConfirmation}
        title="Confirmar eliminación"
        content={
          <Box>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              ¿Está seguro de que desea eliminar este administrador?
            </Typography>
          </Box>
        }
        onConfirm={handleConfirmDelete}
        onClose={handleClose}
      />
    </>
  );
};

const AdministratorShowActions = () => <TopToolbar></TopToolbar>;

export const AdministratorShow = () => {
  const notify = useNotify();

  return (
    <Show actions={<AdministratorShowActions />} title="Administradores" queryOptions={{ onError: getErrorHandler(notify) }}>
      <Stack className="kuru-fields-group" spacing={3} divider={<Box className="kuru-fields-group-divider" component="hr" />}>
        <Stack spacing={3} direction={'row'}>
          <Labeled>
            <EmailField source="email" />
          </Labeled>
        </Stack>
      </Stack>
    </Show>
  );
};

export const AdministratorCreate = () => {
  const notify = useNotify();

  return (
    <Create title="Crear administrador" redirect="show" mutationOptions={{ onError: getErrorHandler(notify) }}>
      <AdministratorForm mode="create" />
    </Create>
  );
};

const AdministratorForm = ({ mode }: { mode: string }) => {
  let toolbar, emailReadOnly;
  if (mode === 'create') {
    toolbar = <KuruCreateToolbar />;
    emailReadOnly = false;
  }

  const emailNoTildePattern = regexesConfig.invalidAccents.p;
  const emailNoTildeMsg = regexesConfig.invalidAccents.msg;

  return (
    <SimpleForm toolbar={toolbar}>
      <Stack className="kuru-fields-group" spacing={3}>
        <Stack spacing={3} direction={'row'}>
          <TextInput source="email" label="E-mail" validate={[required(), email(), regex(emailNoTildePattern, emailNoTildeMsg)]} readOnly={emailReadOnly} />
        </Stack>
        <Stack spacing={3} direction={'row'} style={{ display: 'none' }}>
          <SelectInput
            source="role"
            label="Rol"
            defaultValue={'Admin'}
            style={{ display: 'none' }}
            choices={[
              { id: 'Admin', name: 'Super Administrador' },
              { id: 'Manager', name: 'Administrador de Equipo' },
              { id: 'Controller', name: 'Auditor - solo lectura' },
            ]}
            validate={required()}
          />
        </Stack>
      </Stack>
    </SimpleForm>
  );
};
