import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  NumberField,
  SelectInput,
  ReferenceInput,
  SimpleForm,
  Button,
  useListContext,
  useDataProvider,
  useNotify,
  useRefresh,
  AutocompleteInput,
  useGetList,
  TextInput,
  Confirm,
  FunctionField,
  useGetOne,
  AutocompleteInputProps,
  useTranslate,
} from 'react-admin';
import { ChangeEvent, useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, ChangeHandler } from 'react-hook-form';

import Drawer from '@mui/material/Drawer';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import Tooltip from '@mui/material/Tooltip';

import { KuruListActions } from '../../components/toolbars';
import { filtersDisplayString, getErrorHandler } from '../../utils';
import { BulkActionParams } from '../../types';
import { CardHolderTagsFilter } from '../../components/filters';
import ArrayChipsField from '../../components/ArrayChipsField';
import { SelectAllBulkActions } from '../../components/SelectAllBulkActions';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card } from './types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { BulkUpdateBalanceButton } from './CardBalanceUpdate';

const ApplyRuleButton = ({ selectedIds, filterValues, useFullFilterResults }: BulkActionParams) => {
  const [showApplyRule, setShowApplyRule] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const toggleShowApplyRule = (value: boolean) => {
    setShowApplyRule(value);
  };
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    const incompatibleFilters = ['cardHolderNameStartsWith', 'cardHolderSurnameStartsWith'];
    const appliedFiltersNames = Object.keys(filterValues);
    const incompatibleFiltersAreApplied = incompatibleFilters.some((filter) => appliedFiltersNames.includes(filter));
    setIsDisabled(incompatibleFiltersAreApplied && !!useFullFilterResults);
  }, [filterValues, setIsDisabled, useFullFilterResults]);

  // returns the handler to apply a rule with the selected cards already bound
  const assignToCards = (selectedIds: string[], filterValues: Record<string, any>) => {
    const params: Partial<{ ids: string[]; ruleId: string; filters: Record<string, any> }> = {};

    if (useFullFilterResults) {
      params.filters = filterValues;
    } else {
      params.ids = selectedIds;
    }

    return ({ ruleId }: { ruleId: string }) => {
      params.ruleId = ruleId;

      dataProvider
        .bulkApplyRule('cards', params)
        .then(() => {
          notify('resources.cards.updateMany.rule');
          toggleShowApplyRule(false);
          refresh();
        })
        .catch((error: unknown) => getErrorHandler(notify)(error, { resource: 'cards' }));
    };
  };

  return (
    <>
      <Tooltip title={isDisabled ? 'Acción no disponible seleccionando todos los resultados por nombre o apellido de colaborador' : false}>
        {/* span provides anchorage for the tooltip */}
        <span>
          <Button disabled={isDisabled} label="Asociar regla" onClick={() => toggleShowApplyRule(true)} startIcon={<FactCheckIcon />} />
        </span>
      </Tooltip>
      <Drawer className="actionDrawer" open={showApplyRule} anchor="right" onClose={() => toggleShowApplyRule(false)}>
        <h3>Asociar regla a tarjetas</h3>
        <div>
          Vas a asociar una regla a {selectedIds.length} tarjetas
          {useFullFilterResults && ` (todas aquellas que coinciden con el filtro: ${filtersDisplayString(filterValues)})`}.
        </div>
        <SimpleForm onSubmit={assignToCards(selectedIds, filterValues) as SubmitHandler<FieldValues>}>
          <ReferenceInput source="ruleId" reference="rules">
            <SelectInput optionText="name" label="Regla" />
          </ReferenceInput>
        </SimpleForm>
      </Drawer>
    </>
  );
};

const RuleFilter = (props: AutocompleteInputProps) => {
  const { filterValues, setFilters } = useListContext();
  const { data = [], isLoading } = useGetList('rules');
  const choices = isLoading ? [] : data;

  const onRuleSelect = (_: ChangeEvent<HTMLInputElement>, newRuleName: string) => {
    setFilters({ ...filterValues, ruleName: newRuleName });
  };

  return (
    <AutocompleteInput
      {...props}
      label="Filtrar por regla"
      choices={choices}
      source="ruleId"
      optionValue="id"
      optionText="name"
      onInputChange={onRuleSelect as ChangeHandler}
      noOptionsText="No existen reglas"
    />
  );
};

const cardsFilters = [
  <RuleFilter alwaysOn source="ruleId" />,
  <CardHolderTagsFilter alwaysOn source="cardHolderTags" />,
  <TextInput label="Nombre de colaborador/a" source="cardHolderNameStartsWith" alwaysOn />,
  <TextInput label="Apellido de colaborador/a" source="cardHolderSurnameStartsWith" alwaysOn />,
];

const CardBulkActions = () => (
  <SelectAllBulkActions omitFiltersOnDisplay={['ruleId']}>
    {(selectedIds, filterValues, useFullFilterResults) => (
      <>
        <ApplyRuleButton selectedIds={selectedIds} filterValues={filterValues} useFullFilterResults={useFullFilterResults} />
        <BulkUpdateBalanceButton selectedIds={selectedIds} filterValues={filterValues} useFullFilterResults={useFullFilterResults} />
      </>
    )}
  </SelectAllBulkActions>
);

const RuleName = ({ ruleId }: { ruleId: string | undefined }) => {
  const { data, isLoading, error } = useGetOne('rules', { id: ruleId });

  if (isLoading) return <span>Cargando...</span>;
  if (error) return <span>Error recuperando el nombre de regla</span>;

  return <span>{data ? data.name : '-'}</span>;
};

const CardHolderName = ({ cardHolderId }: { cardHolderId: string | undefined }) => {
  const { data, isLoading, error } = useGetOne('cardHolders', { id: cardHolderId });

  if (isLoading) return <span>Cargando...</span>;
  if (error) return <span>Error recuperando el nombre y apellido del colaborador/a</span>;

  return <span>{data ? `${data.name} ${data.surname}` : 'Sin información'}</span>;
};

export const CardList = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedCard, setSelectedCard] = useState<Card | null>(null);
  const translate = useTranslate();

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>, card: Card) => {
    event.stopPropagation();
    setSelectedCard(card);
    setShowDeleteConfirmation(true);
  };

  const handleClose = () => {
    setShowDeleteConfirmation(false);
    setSelectedCard(null);
  };

  const handleConfirmDelete = async () => {
    if (!selectedCard) return;
    try {
      await dataProvider.delete('cards', { id: selectedCard.id });
      notify('Tarjeta eliminada con éxito');
      refresh();
    } catch (_) {
      notify('Error al eliminar la tarjeta', { type: 'warning' });
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <List actions={<KuruListActions />} queryOptions={{ onError: getErrorHandler(notify) }} filters={cardsFilters}>
        <Datagrid rowClick="show" bulkActionButtons={<CardBulkActions />}>
          <ReferenceField source="cardHolderId" reference="cardHolders" link={false} label="Colaborador/a" sortable={false} />
          <ReferenceField source="cardHolderId" reference="cardHolders" link={false} label="Etiquetas de Colaborador/a" sortable={false}>
            <ArrayChipsField source="tags" />
          </ReferenceField>
          <TextField source="lastFour" label="Últimos dígitos" sortable={false} />
          <FunctionField label="Tipo" render={({ type }) => translate('resources.cards.type.' + type)} />
          <ReferenceField source="ruleId" reference="rules" link={false} label="Regla" emptyText="-" sortable={false} />
          <NumberField
            source="balance"
            sortable={false}
            label="Saldo"
            locales="es-AR"
            options={{ style: 'currency', currency: 'ARS', minimumFractionDigits: 2 }}
          />
          <FunctionField
            source="id"
            label="Acciones"
            sortable={false}
            render={(record) => (
              <Tooltip title="Eliminar tarjeta">
                <IconButton onClick={(event) => handleDeleteClick(event, record)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          />
        </Datagrid>
      </List>
      <Confirm
        isOpen={showDeleteConfirmation}
        title="Confirmar eliminación"
        content={
          <Box>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              ¿Está seguro de que desea eliminar esta tarjeta?
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="textSecondary">
                  Últimos 4 dígitos:
                </Typography>
                <Typography variant="body1">{selectedCard?.lastFour || '-'}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" color="textSecondary">
                  Colaborador/a:
                </Typography>
                <Typography variant="body1">
                  <CardHolderName cardHolderId={selectedCard?.cardHolderId} />
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" color="textSecondary">
                  Regla:
                </Typography>
                <Typography variant="body1">
                  <RuleName ruleId={selectedCard?.ruleId} />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        }
        onConfirm={handleConfirmDelete}
        onClose={handleClose}
      />
    </>
  );
};
