import { GetListParams, GetListResult } from 'react-admin';
import { getQueryString } from '../../dataProvider/utils';
import { ResourceHandlerContext } from '../../dataProvider/types';
import { GiftCard } from '.';

export async function getList(context: ResourceHandlerContext, companyId: string, params: GetListParams): Promise<GetListResult<GiftCard>> {
  const filterQueryParams = [];
  const cardHolderTags: string[] = params?.filter?.cardHolderTags?.trim().split(' ');

  if (cardHolderTags) {
    for (const tag of cardHolderTags) {
      const trimmedTag = tag.trim();
      if (trimmedTag) {
        filterQueryParams.push('cardHolderTags=' + trimmedTag);
      }
    }
  }

  const cardHolderNameStartsWith = params?.filter?.cardHolderNameStartsWith?.trim();
  if (cardHolderNameStartsWith) {
    filterQueryParams.push('cardHolderNameStartsWith=' + cardHolderNameStartsWith);
  }

  const cardHolderSurnameStartsWith = params?.filter?.cardHolderSurnameStartsWith?.trim();
  if (cardHolderSurnameStartsWith) {
    filterQueryParams.push('cardHolderSurnameStartsWith=' + cardHolderSurnameStartsWith);
  }

  const { json } = await context.request(`companies/${companyId}/gift-cards` + getQueryString(params?.pagination, filterQueryParams), {});
  const response: { total: number; cards: GiftCard[] } = json;
  return {
    total: response.total,
    data: response.cards,
  };
}

export async function requestExport(context: ResourceHandlerContext, companyId: string): Promise<Record<string, any>> {
  const { json } = await context.request(`companies/${companyId}/jobs/export-cards`, {
    method: 'POST',
  });

  return { data: json };
}
