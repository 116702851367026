import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  BooleanField,
  useRecordContext,
  useNotify,
  FunctionField,
  useDataProvider,
  useRefresh,
  Confirm,
} from 'react-admin';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { RuleForm, transformRuleFormData } from './RuleForm';
import { dayNames, timeConditionEndpointDisplayString } from './conditions';
import { getErrorHandler } from '../../utils';

import type { CategoriesCondition, DaysCondition, ExpirationDate, MaxAmountCondition, Rule, TimeCondition, TransactionLimitsCondition } from './types';
import { useState } from 'react';

export const RuleList = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedRule, setSelectedRule] = useState<string | null>(null);

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    event.stopPropagation();
    setSelectedRule(id);
    setShowDeleteConfirmation(true);
  };

  const handleClose = () => {
    setShowDeleteConfirmation(false);
    setSelectedRule(null);
  };

  const handleConfirmDelete = async () => {
    if (!selectedRule) return;
    try {
      await dataProvider.delete('rules', { id: selectedRule });
      notify('resources.rules.success.deleteRule');
      refresh();
    } catch (_) {
      notify('resources.rules.errors.deleteRule');
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <List exporter={false} queryOptions={{ onError: getErrorHandler(notify) }}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <TextField source="name" label="Nombre" sortable={false} />
          <ConditionsField label="Condiciones" />
          <BooleanField source="receiptRequired" label="Requiere comprobante" sortable={false} />
          <FunctionField
            label="Acciones"
            sortable={false}
            render={(record) =>
              record.isPhysicalCardDefault ? (
                'No se puede eliminar esta regla.'
              ) : (
                <Tooltip title="Eliminar regla">
                  <IconButton onClick={(event) => handleDeleteClick(event, record.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )
            }
          />
        </Datagrid>
      </List>
      <Confirm
        isOpen={showDeleteConfirmation}
        title="Confirmar eliminación"
        content={
          <Box>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              ¿Estás seguro de que deseás eliminar esta regla?
            </Typography>
          </Box>
        }
        onConfirm={handleConfirmDelete}
        onClose={handleClose}
      />
    </>
  );
};

const formatMaxAmountTooltip = (maxAmount: MaxAmountCondition) => {
  return '$' + maxAmount?.maxAmount;
};

const formatDaysTooltip = (days: DaysCondition) => {
  const sortedDays: number[] = days.daysAllowed.toSorted().map((d) => parseInt(d, 10));

  return sortedDays.map((dayIndex) => dayNames[dayIndex]).join('-');
};

const formatTimeTooltip = (time: TimeCondition) => {
  return `${timeConditionEndpointDisplayString(time[0])}-${timeConditionEndpointDisplayString(time[1])}`;
};

const formatTxLimitsTooltip = (txLimits: TransactionLimitsCondition) => {
  return [
    txLimits.limitByDay ? txLimits.limitByDay + ' por día' : null,
    txLimits.limitByWeek ? txLimits.limitByWeek + ' por semana' : null,
    txLimits.limitByMonth ? txLimits.limitByMonth + ' por mes' : null,
  ]
    .filter((l) => l)
    .join(', ');
};

const formatCategoriesTooltip = (categories: CategoriesCondition) => {
  return categories.categories
    .map((cat) => {
      return cat[0] + cat.substring(1).toLowerCase();
    })
    .join(', ');
};

const formatExpirationDateTooltip = (expirationDate: ExpirationDate) => {
  return 'Hasta ' + expirationDate;
};

const ConditionsField = (_: { label: string }) => {
  const record = useRecordContext();
  const conditions = record?.conditions;

  if (!conditions) return null;

  let displayCurrency;

  if (conditions.currency) {
    displayCurrency = (conditions.currency.allowLocalCurrency || '') && 'ARS';
    displayCurrency += ((conditions.currency.allowLocalCurrency && conditions.currency.allowForeignCurrency) || '') && ' + ';
    displayCurrency += (conditions.currency.allowForeignCurrency || '') && 'Extranjera';
  }

  return (
    <>
      {conditions.maxAmount?.maxAmount && (
        <Tooltip title={formatMaxAmountTooltip(conditions.maxAmount)} placement="top-end">
          <Chip label="Monto" size="small" />
        </Tooltip>
      )}
      {conditions.days?.daysAllowed && (
        <Tooltip title={formatDaysTooltip(conditions.days)} placement="top-end">
          <Chip label="Días" size="small" />
        </Tooltip>
      )}
      {conditions.time && (
        <Tooltip title={formatTimeTooltip(conditions.time)} placement="top-end">
          <Chip label="Horario" size="small" />
        </Tooltip>
      )}
      {conditions.transactionLimits && (
        <Tooltip title={formatTxLimitsTooltip(conditions.transactionLimits)} placement="top-end">
          <Chip label="Límites" size="small" />
        </Tooltip>
      )}
      {displayCurrency && <Chip label={displayCurrency} size="small" />}
      {conditions.categories && (
        <Tooltip title={formatCategoriesTooltip(conditions.categories)} placement="top-end">
          <Chip label="Rubros" size="small" />
        </Tooltip>
      )}
      {record.expirationDate && (
        <Tooltip title={formatExpirationDateTooltip(record.expirationDate)} placement="top-end">
          <Chip label="Período" size="small" />
        </Tooltip>
      )}
    </>
  );
};

export const RuleCreate = () => {
  const notify = useNotify();

  return (
    <>
      <Create transform={transformRuleFormData} redirect="list" component={Box} mutationOptions={{ onError: getErrorHandler(notify) }}>
        <RuleForm />
      </Create>
    </>
  );
};

const RuleTitle = () => {
  const record: Rule | undefined = useRecordContext();

  return <span>Regla &ldquo;{record ? record.name : ''}&rdquo;</span>;
};

export const RuleEdit = () => {
  const notify = useNotify();
  const onError = getErrorHandler(notify);

  return (
    <Edit
      transform={transformRuleFormData}
      mutationMode="pessimistic"
      component={Box}
      title={<RuleTitle />}
      queryOptions={{ onError }}
      mutationOptions={{ onError }}
    >
      <RuleForm />
    </Edit>
  );
};
