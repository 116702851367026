import { stringify } from 'query-string';

export function getQueryString(pagination: Record<string, any> | null | undefined = null, filterQueryParams: string[] | null | undefined = null): string {
  let queryString = '';
  const paginationQueryString = getPaginationQueryString(pagination);
  const filterQueryString = filterQueryParams?.join('&');

  queryString += paginationQueryString ? '?' + paginationQueryString : '';
  queryString += filterQueryString ? (queryString ? '&' : '?') + filterQueryString : '';

  return queryString;
}

// Returns a pagination query string (without preceding '?' or '&')
function getPaginationQueryString(pagination: Record<string, any> | null | undefined): string | null {
  if (!pagination) {
    return null;
  }

  const { page, perPage } = pagination;

  const paginationQueryString = {
    limit: perPage,
    offset: perPage * (page - 1),
  };

  return stringify(paginationQueryString);
}
