import {
  EmailField,
  DateField,
  Show,
  TextField,
  FunctionField,
  ReferenceManyField,
  EditButton,
  TopToolbar,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  DateInput,
  required,
  regex,
  email,
  Labeled,
  useNotify,
  useRecordContext,
  BooleanField,
} from 'react-admin';

import { Stack, Box } from '@mui/system';

import { CardHolder } from './types';
import { CardSimpleList } from '../cards';
import { GiftCardSimpleList } from '../giftcards';
import { KuruCreateToolbar, KuruEditCancelToolbar, KuruEditToolbar } from '../../components/toolbars';
import { getErrorHandler, regexesConfig } from '../../utils';
import { TagsCreationInput } from '../../components/tags';
import ArrayChipsField from '../../components/ArrayChipsField';
import { displayString, displayExternalId } from '.';

const CardHolderShowActions = () => (
  <TopToolbar>
    <EditButton />
  </TopToolbar>
);

export const CardHolderShow = () => {
  const notify = useNotify();

  return (
    <Show actions={<CardHolderShowActions />} title="Colaboradores" queryOptions={{ onError: getErrorHandler(notify) }}>
      <Stack className="kuru-fields-group" spacing={3} divider={<Box className="kuru-fields-group-divider" component="hr" />}>
        <Stack spacing={3} direction={'row'}>
          <Labeled>
            <FunctionField source="name" render={displayString} label="Nombre" />
          </Labeled>
          <Labeled>
            <EmailField source="email" />
          </Labeled>
          <Labeled>
            <TextField source="phoneNumber" label="Teléfono" />
          </Labeled>
          <Labeled>
            <DateField source="createdAt" label="Fecha de alta" locales="es" />
          </Labeled>
          <Labeled>
            <BooleanField source="phoneValidated" label="Teléfono validado" />
          </Labeled>
          <Labeled>
            <FunctionField source="externalId" label="Id externa" render={displayExternalId} sortable={false} />
          </Labeled>
        </Stack>
        <Stack spacing={3} direction={'row'}>
          <Labeled
            label="Etiquetas"
            sx={{
              'p.RaLabeled-label': { marginBottom: '.5rem' },
            }}
          >
            <ArrayChipsField source="tags" />
          </Labeled>
        </Stack>
        <Stack>
          <Labeled>
            <ReferenceManyField reference="cards" target="cardHolderId" label="Tarjetas" className="kuru-cards-table">
              <CardSimpleList />
            </ReferenceManyField>
          </Labeled>
        </Stack>
        {import.meta.env.VITE_FEATURE_GIFTCARD == 'enabled' ? (
          <Stack>
            <Labeled>
              <ReferenceManyField reference="giftcards" target="cardHolderId" label="GiftCards" className="kuru-cards-table">
                <GiftCardSimpleList />
              </ReferenceManyField>
            </Labeled>
          </Stack>
        ) : (
          <span></span>
        )}
      </Stack>
    </Show>
  );
};

export const CardHolderEdit = () => {
  const notify = useNotify();
  const onError = getErrorHandler(notify);

  return (
    <Edit title="Editar colaborador" queryOptions={{ onError }} mutationOptions={{ onError }} mutationMode="pessimistic" actions={<KuruEditCancelToolbar />}>
      <CardHolderForm mode="edit" />
    </Edit>
  );
};

export const CardHolderCreate = () => {
  const notify = useNotify();

  return (
    <Create title="Crear colaborador" redirect="show" mutationOptions={{ onError: getErrorHandler(notify) }}>
      <CardHolderForm mode="create" />
    </Create>
  );
};

const CardHolderForm = ({ mode }: { mode: string }) => {
  const record: CardHolder | undefined = useRecordContext();
  const tags = record?.tags?.map((tag) => ({ id: tag })) || [];
  let toolbar, isEditing;

  if (mode === 'create') {
    toolbar = <KuruCreateToolbar />;
    isEditing = false;
  } else if (mode === 'edit') {
    toolbar = <KuruEditToolbar showDelete={false} />;
    isEditing = true;
  }

  // international phone numbering plan (ITU-T E.164): phone numbers are between 7-15 digits
  const phoneRegexPattern = regexesConfig.phoneIntlMobile.p;
  const phoneRegexMsg = regexesConfig.phoneIntlMobile.msg;
  const phoneRegex = new RegExp(phoneRegexPattern);
  const emailNoTildePattern = regexesConfig.invalidAccents.p;
  const emailNoTildeMsg = regexesConfig.invalidAccents.msg;

  return (
    <SimpleForm toolbar={toolbar}>
      <Stack className="kuru-fields-group" spacing={3} divider={<Box className="kuru-fields-group-divider" component="hr" />}>
        <Stack spacing={3} direction={'row'}>
          <TextInput source="name" label="Nombre" validate={required()} />
          <TextInput source="surname" label="Apellido" validate={required()} />
          <TextInput source="email" label="E-mail" validate={[required(), email(), regex(emailNoTildePattern, emailNoTildeMsg)]} />
          <TextInput source="phoneNumber" label="Teléfono móvil" validate={[required(), regex(phoneRegex, phoneRegexMsg)]} placeholder="+54911######" />
          <TextInput source="externalId" label="Id externa"></TextInput>
        </Stack>
        <Stack spacing={3} direction={'row'}>
          <TagsCreationInput choices={tags} />
        </Stack>
        {!isEditing && (
          <Stack spacing={3} direction={'row'}>
            <DateInput source="birthdate" label="Fecha de nacimiento" validate={required()} />
            <TextInput source="identificationValue" label="DNI" validate={required()} />
            <TextInput source="taxIdentificationValue" label="CUIL" validate={required()} />
          </Stack>
        )}
      </Stack>
    </SimpleForm>
  );
};
