import { CardHolder } from './types';

export * from './cardHolders';
export * from './types';

export { CardHolderList } from './CardHolderList';

export const displayString = (record: Pick<CardHolder, 'name' | 'surname'>) => `${record?.name} ${record?.surname}`;

export const displayExternalId = (record: Pick<CardHolder, 'externalId'>) => record.externalId ?? '-';
