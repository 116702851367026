import { Control, useWatch, UseFormSetValue, FieldValues, UseFormTrigger } from 'react-hook-form';
import { createContext } from 'react';

export * from './KuruForm';

// Do we need to initialize with some content?
export type KuruFormContext = {
  control: Control<Record<string, any>, any>;
  setValue: UseFormSetValue<FieldValues>;
  trigger: UseFormTrigger<Record<string, any>>;
};
export const KuruFormContext = createContext({} as KuruFormContext);

export const useFieldWatch = (control: Control<Record<string, any>, any>, name: string) => {
  // NOTE: not sure why this didn't work, too costly to research now
  // const { control } = useContext(KuruFormContext);
  return useWatch({ control, name });
};

// Field rules/validations
// TODO: support rules as array of validation objects like this
// TODO: Add '*' to labels if required
// TODO: support more rules
export const required = () => ({ required: 'Este campo es requerido' });
