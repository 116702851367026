import { useEffect, useState } from 'react';
import {
  Datagrid,
  FunctionField,
  ListBase,
  ListToolbar,
  Pagination,
  useTranslate,
  useDataProvider,
  useNotify,
  minValue,
  Form,
  Button,
  useGetIdentity,
  required,
  SaveHandler,
} from 'react-admin';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import MuiTextField from '@mui/material/TextField';
import EmailIcon from '@mui/icons-material/Email';

import { displayDateTime, getErrorHandler } from '../utils';
import CurrencyDisplay, { useFormatCurrency } from '../components/CurrencyDisplay';
import { Currency, Transaction, cardTransactionTypes, companyTransactionTypes, displayTxCardLink, TransactionType } from '../resources/transactions';
import { DateTimeFilter, TxTypeFilter } from '../components/filters';
import { KuruListActions } from '../components/toolbars';
import { AltFormattedFloatInput } from '../components/FormattedFloatInput';
import { FundingRequestForForm } from '../types';

export default function MyKuru() {
  const dataProvider = useDataProvider();
  const formatCurrency = useFormatCurrency('ARS');
  const [balances, setBalances] = useState({ cards: 0, company: 0 });
  const notify = useNotify();
  useEffect(() => {
    dataProvider
      .getDashboardData('dashboard')
      .then((response: any) => {
        setBalances({
          cards: response.data.balance.cards,
          company: response.data.balance.company,
        });
      })
      .catch((error: any) => getErrorHandler(notify)(error));
  }, [dataProvider, notify]);

  return (
    <Card>
      <Typography variant="h4" sx={{ marginBottom: '1rem' }}>
        Mi Kuru
      </Typography>
      <Stack className="kuru-fields-group" spacing={3} divider={<Divider orientation="horizontal" />}>
        <Stack>
          <Typography variant="h5" sx={{ marginBottom: '1rem' }}>
            Saldos
          </Typography>
          <Stack direction="row" spacing={3}>
            <MuiTextField label="Saldo total" value={formatCurrency(balances.cards + balances.company)} disabled className="display-field" />
            <MuiTextField label="Saldo disponible" value={formatCurrency(balances.company)} disabled className="display-field" />
            <MuiTextField label="Saldo asignado a tarjetas" value={formatCurrency(balances.cards)} disabled className="display-field" />
          </Stack>
        </Stack>
        <Stack>
          <CompanyTransactionsList />
        </Stack>
        <Stack>
          <FundingRequestForm />
        </Stack>
      </Stack>
    </Card>
  );
}

const CompanyTransactionsList = () => {
  const translate = useTranslate();
  const filters = [
    <TxTypeFilter alwaysOn source="type" values={companyTransactionTypes} label="Tipo de transacción" />,
    <DateTimeFilter alwaysOn source="date" label="Fecha" />,
  ];

  const displayTxAmountForCompanyPov = (record: Transaction) => {
    const currency = Currency.ARS;
    const { type, amount } = record;
    // Card balance modification txs should revert their sign when shown in the Company transactions
    // list (negative if they reduce the company balance, positive if they add to it).
    const sign = cardTransactionTypes.includes(type) || type === TransactionType.GIFT_CARD_CREATION ? -1 : 1;
    const amountRelativeToCompanyBalance = amount * sign;

    return (
      <>
        {currency} <CurrencyDisplay amount={amountRelativeToCompanyBalance} currency={currency} />
      </>
    );
  };

  return (
    <>
      <Typography variant="h5" sx={{ marginBottom: '1rem' }}>
        Transacciones de la compañía
      </Typography>
      <ListBase resource="companyTransactions">
        <ListToolbar filters={filters} actions={<KuruListActions exporter={false} create={false} showFilters={true} />} />
        <Datagrid rowClick={false} bulkActionButtons={false}>
          <FunctionField label="Tipo" render={({ type }: Record<string, string>) => translate('resources.transactions.type.' + type)} />
          <FunctionField label="Monto" source="data" render={displayTxAmountForCompanyPov} sortable={false} />
          <FunctionField label="Tarjeta" render={displayTxCardLink} sortable={false} />
          <FunctionField label="Fecha" source="time" render={displayDateTime} sortable={false} />
        </Datagrid>
        <Pagination />
      </ListBase>
    </>
  );
};

const FundingRequestForm = () => {
  const { isPending, identity } = useGetIdentity();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const submitForm = (record: FundingRequestForForm) => {
    dataProvider.requestFunding({ ...record, email: identity?.id }).then(() => {
      notify('Solicitud de fondeo enviada exitosamente');
    });
  };

  return (
    <>
      <Typography variant="h5" sx={{ marginBottom: '1rem' }}>
        Solicitud de fondeo
      </Typography>
      <p>
        Ingresá el monto que quieras agregar al saldo de tu compañía, y el equipo de Administración de Kuru se comunicará por correo electrónico con la
        documentación necesaria.
      </p>
      <Form onSubmit={submitForm as SaveHandler<FundingRequestForForm>}>
        <AltFormattedFloatInput source="amount" label="Monto" validate={[required(), minValue(1000)]} />
        <Button label="Enviar" type="submit" startIcon={<EmailIcon />} disabled={isPending} sx={{ height: '30px', margin: '5px .5rem' }} />
      </Form>
    </>
  );
};
