import { Admin, CustomRoutes, Resource } from 'react-admin';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { IntlProvider } from 'react-intl';
// TODO: find how to provide i18n with an augmented+corrected version of locale
// es-AR, to fix things like the `&nbsp;` after the currency sign, or choose to
// format currency like `ARS $1.234`
// RawIntlProvider looks like it allows customization, but didn't find yet where
// to import the base locales from
// https://formatjs.io/docs/react-intl/components#rawintlprovider

import SnackbarContent from '@mui/material/SnackbarContent';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import GiftCardIcon from '@mui/icons-material/LocalMall';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import UserIcon from '@mui/icons-material/Group';
import AdministratorIcon from '@mui/icons-material/AdminPanelSettings';
import CompanyIcon from '@mui/icons-material/Apartment';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { isKuruUser } from './utils';
import { getImpersonationCompany } from './components/Impersonation';
import { createDataProvider } from './dataProvider/kuruDataProvider';
import { i18nProvider } from './i18n/i18nProvider';
import { kuruTheme } from './theme/kuru-theme.js';
import { KuruLayout } from './layout/layout';
import { CardHolderList, CardHolderShow, CardHolderCreate, CardHolderEdit, displayString as cardHolderDisplayString } from './resources/cardHolders';
import { CardList, CardEdit, CardCreate, CardShow, displayString as cardDisplayString } from './resources/cards';
import { GiftCardList, GiftCardCreate, GiftCardShow, displayString as giftCardDisplayString } from './resources/giftcards';
import { RuleCreate, RuleEdit, RuleList } from './resources/rules';
import { TransactionList } from './resources/transactions';
import { Dashboard } from './components/dashboard';
import { AdministratorCreate, AdministratorList, AdministratorShow } from './resources/administrators';
import { CompanyCreate, CompanyList, CompanyShow } from './resources/companies';
import { NotificationsList } from './resources/notifications';
import { createAuthClient, createAuthProvider } from './authProvider';
import { ImpersonationCompany } from './types';
import { TerroristMatches } from './views/TerroristMatches';
import MyKuru from './views/MyKuru';
import { ScheduledOperationsList } from './resources/scheduledOperations/ScheduledOperationsList';
import ScheduleIcon from '@mui/icons-material/Schedule';

const authClient = createAuthClient();
const authProvider = createAuthProvider(authClient);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 3,
    },
  },
});

export const App = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [impersonationCompany, setImpersonationCompany] = useState<ImpersonationCompany | undefined>(getImpersonationCompany());
  const [currentIsKuruUser, setCurrentIsKuruUser] = useState<boolean>();

  return (
    <BrowserRouter>
      <IntlProvider locale="es-AR">
        <Admin
          layout={KuruLayout}
          theme={kuruTheme}
          authProvider={authProvider}
          dataProvider={createDataProvider(authClient)}
          i18nProvider={i18nProvider}
          dashboard={Dashboard}
          queryClient={queryClient}
          requireAuth
        >
          {(permissions) => {
            if (!permissions) return null;

            setCurrentIsKuruUser(isKuruUser(permissions));

            const customRoutes = [];

            if (currentIsKuruUser) {
              customRoutes.push(<Route path="/compliance-lists" element={<TerroristMatches />} />);
            }
            if (impersonationCompany || !currentIsKuruUser) {
              customRoutes.push(<Route path="/my-kuru" element={<MyKuru />} />);
            }

            const kuruResources = [
              <Resource name="companies" list={CompanyList} show={CompanyShow} create={CompanyCreate} icon={CompanyIcon} recordRepresentation="name" />,
            ];

            const clientCompanyResources = [
              <Resource name="notifications" list={NotificationsList} icon={NotificationsIcon} />,
              <Resource name="administrators" list={AdministratorList} show={AdministratorShow} create={AdministratorCreate} icon={AdministratorIcon} />,
              <Resource
                name="cardHolders"
                list={CardHolderList}
                show={CardHolderShow}
                create={CardHolderCreate}
                edit={CardHolderEdit}
                recordRepresentation={cardHolderDisplayString}
                icon={UserIcon}
              />,
              <Resource
                name="cards"
                list={CardList}
                edit={CardEdit}
                create={CardCreate}
                show={CardShow}
                recordRepresentation={cardDisplayString}
                icon={CreditCardIcon}
              />,
              <Resource
                name="giftcards"
                list={GiftCardList}
                create={GiftCardCreate}
                show={GiftCardShow}
                recordRepresentation={giftCardDisplayString}
                icon={GiftCardIcon}
              />,
              <Resource name="rules" list={RuleList} create={RuleCreate} edit={RuleEdit} recordRepresentation={'name'} icon={FactCheckIcon} />,
              <Resource name="transactions" list={TransactionList} icon={PaidOutlinedIcon} />,
              <Resource name="companyTransactions" />,
              <Resource name="categories" />,
              <Resource name="scheduledJobs" list={ScheduledOperationsList} icon={ScheduleIcon} />,
            ];

            return (
              <>
                {/*
                  NOTE: the array spread below prevents a React error about missing
                  'key' props in listed children (https://reactjs.org/link/warning-keys)
                  */}
                <CustomRoutes>{...customRoutes}</CustomRoutes>
                {currentIsKuruUser && <>{...kuruResources}</>}
                {(!currentIsKuruUser || impersonationCompany) && <>{...clientCompanyResources}</>}
              </>
            );
          }}
        </Admin>
        {currentIsKuruUser && impersonationCompany && (
          <SnackbarContent
            id="impersonation"
            message={'Administrando empresa: ' + impersonationCompany.name}
            sx={{ zIndex: '1000', position: 'absolute', left: '40%', top: '0', marginTop: '-0.5rem' }}
          />
        )}
      </IntlProvider>
    </BrowserRouter>
  );
};
