import { Form, RaRecord, Identifier, SelectInput, useGetList, usePermissions } from 'react-admin';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';

import { ImpersonationCompany } from '../types';

export const isImpersonating = () => !!getImpersonationCompany();

export const getImpersonationCompany = (): ImpersonationCompany | undefined => {
  let impersonationCompany: ImpersonationCompany;

  try {
    impersonationCompany = JSON.parse(sessionStorage.getItem('impersonationCompany') || '');
    if (!impersonationCompany?.id || !impersonationCompany.name) {
      console.debug('Malformed impersonation company found in session storage.');
      throw Error;
    }
    return impersonationCompany;
  } catch (_) {
    clearImpersonation();
    return undefined;
  }
};

export const clearImpersonation = () => {
  console.debug('Removing impersonation company from session storage.');
  sessionStorage.removeItem('impersonationCompany');
};

// TODO: move to row action on companies list
export const Impersonation = () => {
  const { permissions } = usePermissions() || {};
  const setMockCompanyId = (event: RaRecord<Identifier> | ChangeEvent<HTMLInputElement>) => {
    const companyId = event?.target?.value;
    if (companyId) {
      sessionStorage.setItem(
        'impersonationCompany',
        JSON.stringify({
          id: companyId,
          name: data?.find((item) => item?.id === companyId)?.name,
        }),
      );
    } else {
      sessionStorage.removeItem('impersonationCompany');
    }

    window.location.reload();
  };

  const { data, isLoading } = useGetList('companies');

  const impersonationCompany = getImpersonationCompany();

  const textStyle = { color: 'black' };

  const renderCompanyAccessText = () => {
    if (permissions?.is_kuru_admin) return 'Ingresar a empresa';
    return getDefaultValue() ? 'Administrando empresa:' : 'Ingresar a empresa';
  };

  const getDefaultValue = useCallback(() => {
    const value = JSON.parse(sessionStorage.getItem('impersonationCompany') || '{}')?.id;
    if (!permissions?.is_kuru_admin && !value) {
      const companyAccessKeys = Object.keys(permissions?.company_access);
      const extractedCompanyId = companyAccessKeys[0];
      return extractedCompanyId;
    }
    return value;
  }, [permissions]);

  const [currentValue, setCurrentValue] = useState();

  useEffect(() => {
    if (data?.length) {
      setCurrentValue(getDefaultValue());
    }
  }, [data, getDefaultValue]);

  return (
    <Form className="impersonation-form">
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <div style={textStyle}>{renderCompanyAccessText()}</div>

        <SelectInput
          source="id"
          choices={data}
          label={false}
          onChange={setMockCompanyId}
          isLoading={isLoading}
          optionText="name"
          emptyText={impersonationCompany && permissions?.is_kuru_admin ? '<Salir de la empresa>' : undefined}
          defaultValue={currentValue}
        />
      </Box>
    </Form>
  );
};
