import {
  useTranslate,
  CheckboxGroupInput,
  NumberInput,
  useInput,
  BooleanInput,
  DateInput,
  InputProps,
  useRecordContext,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import { ChangeEvent, useState } from 'react';
import get from 'lodash/get';

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

import type { RuleFormRecord } from './types';
import { conditionDescriptionStyles, dateConditionDetailsStyles } from './styles';

// For condition-enabling toggles
export const getToggleChangeHandler = (stateSetter: React.Dispatch<React.SetStateAction<boolean>>) => {
  return (event: ChangeEvent<HTMLInputElement>) => {
    stateSetter(event.target.checked);
  };
};

export const conditionIsNotEmpty = (condition: Record<string, any>) => {
  return (
    !!condition &&
    Object.keys(condition).some((key) => {
      const conditionConfig = get(condition, key);
      return !!conditionConfig && (!Array.isArray(conditionConfig) || conditionConfig.length !== 0);
    })
  );
};

export const dayNames = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];

export const DaysConditionTile = () => {
  const choices = [];
  for (const index in dayNames) {
    choices.push({ id: index.toString(), name: dayNames[index] });
  }

  const theStyles = {
    [`& .MuiFormControlLabel-root`]: {
      margin: '0 2px',
      flexDirection: 'column-reverse',
      '& span.MuiCheckbox-root': {},
      '& span.MuiFormControlLabel-label': {
        fontSize: '.75em',
      },
    },
  };

  const record = useRecordContext();
  const defaultEnabled = !!record?.conditions.days;
  const [enabled, setEnabled] = useState(defaultEnabled);

  return (
    <Card sx={{}}>
      <h3>
        Días habilitados para uso
        <BooleanInput
          source="enabled.days"
          defaultValue={defaultEnabled}
          className="ruleConditionToggle hideHelperText"
          onChange={getToggleChangeHandler(setEnabled)}
          label={false}
        />
      </h3>
      <CheckboxGroupInput source="conditions.days.daysAllowed" sx={theStyles} choices={choices} label={false} disabled={!enabled} />
    </Card>
  );
};

export const timeConditionEndpointDisplayString = (value: number) => {
  return `${Math.trunc(value)}:${(value % 1) * 6}0hs`;
};

export const TimeConditionTile = () => {
  const record = useRecordContext();
  const defaultEnabled = !!record?.conditions.time;
  const [enabled, setEnabled] = useState(defaultEnabled);

  return (
    <Card>
      <h3>
        Horario habilitado para uso
        {/* // TODO revisar este cambio de switch a booleaninput  */}
        <BooleanInput
          source="enabled.time"
          defaultValue={defaultEnabled}
          className="ruleConditionToggle hideHelperText"
          onChange={getToggleChangeHandler(setEnabled)}
          label={false}
        />
      </h3>
      <TimeInput source="conditions.time" disabled={!enabled} />
    </Card>
  );
};

const TimeInput = (props: InputProps) => {
  const record = useRecordContext();

  const defaultValue = [9, 18];
  // use default for creation, and current value for edition
  const startingValue = record?.conditions?.time ? record.conditions.time : defaultValue;
  const [range, setRange] = useState(startingValue);

  const marks = [
    { value: defaultValue[0], label: `${defaultValue[0]}:00hs` },
    { value: defaultValue[1], label: `${defaultValue[1]}:00hs` },
  ];

  const onChange = (_: ChangeEvent, value: number[]) => setRange(value);

  const { id, field } = useInput({ ...props, defaultValue, onChange });

  return (
    <Slider
      sx={{ marginTop: '1rem' }}
      id={id}
      {...field}
      value={range}
      size="small"
      getAriaLabel={() => 'Horario habilitado para uso'}
      getAriaValueText={timeConditionEndpointDisplayString}
      valueLabelDisplay="on"
      valueLabelFormat={timeConditionEndpointDisplayString}
      marks={marks}
      step={0.5}
      shiftStep={1}
      max={24}
      disabled={props.disabled}
    />
  );
};

export const MaxAmountConditionTile = () => {
  const record = useRecordContext();
  const defaultEnabled = !!record?.conditions.maxAmount;
  const [enabled, setEnabled] = useState(defaultEnabled);

  return (
    <Card>
      <h3>
        Montos de transacciones
        <BooleanInput
          source="enabled.maxAmount"
          defaultValue={defaultEnabled}
          className="ruleConditionToggle hideHelperText"
          onChange={getToggleChangeHandler(setEnabled)}
          label={false}
        />
      </h3>
      <Box sx={conditionDescriptionStyles}>Limitar los montos de transacciones</Box>
      <Stack className="hideHelperText" useFlexGap spacing={2}>
        <Stack direction={'row'}>
          <NumberInput disabled={!enabled} source="conditions.maxAmount.maxAmount" label="Por transacción" />
          <NumberInput disabled={!enabled} source="conditions.maxAmount.byDay" label="Por día" />
        </Stack>
        <Stack direction={'row'}>
          <NumberInput disabled={!enabled} source="conditions.maxAmount.byWeek" label="Por semana" />
          <NumberInput disabled={!enabled} source="conditions.maxAmount.byMonth" label="Por mes" />
        </Stack>
      </Stack>
    </Card>
  );
};

export const TransactionLimitsConditionTile = () => {
  const record = useRecordContext();
  const defaultEnabled = !!record?.conditions.transactionLimits;
  const [enabled, setEnabled] = useState(defaultEnabled);

  return (
    <Card>
      <>
        <h3>
          Cantidades de transacciones
          <BooleanInput
            source="enabled.transactionLimits"
            defaultValue={defaultEnabled}
            className="ruleConditionToggle hideHelperText"
            onChange={getToggleChangeHandler(setEnabled)}
            label={false}
          />
        </h3>
        <Box sx={conditionDescriptionStyles}>Limitar las cantidades de transacciones</Box>
        <Stack
          direction={'row'}
          sx={{
            'label[data-shrink=false]': {
              marginTop: '0',
            },
          }}
          className="hideHelperText"
        >
          <NumberInput disabled={!enabled} source="conditions.transactionLimits.limitByDay" label="Por día" />
          <NumberInput disabled={!enabled} source="conditions.transactionLimits.limitByWeek" label="Por semana" />
          <NumberInput disabled={!enabled} source="conditions.transactionLimits.limitByMonth" label="Por mes" />
        </Stack>
      </>
    </Card>
  );
};

const validateCurrency = (_: boolean, allValues: RuleFormRecord) => {
  return Object.values(allValues.conditions.currency as object).some((currency) => currency) ? undefined : 'Seleccioná al menos un tipo de moneda.';
};

// NOTE: validation error always hidden for the local currency control because it's redundant with the error shown for the foreign currency control
export const CurrencyConditionTile = () => (
  <Card>
    <h3>Tipo de moneda</h3>
    <BooleanInput source="conditions.currency.allowLocalCurrency" label="Local (ARS)" validate={validateCurrency} className="hideHelperText" />
    <BooleanInput source="conditions.currency.allowForeignCurrency" label="Extranjera (USD, GBP, etc)" validate={validateCurrency} className="hideHelperText" />
  </Card>
);

export const CategoriesConditionTile = () => {
  const translate = useTranslate();
  const categoryDisplayString = ({ id }: { id: string }) => {
    return translate('resources.categories.labels.' + id);
  };

  const record = useRecordContext();
  const defaultEnabled = !!record?.conditions.categories;
  const [enabled, setEnabled] = useState(defaultEnabled);

  // TODO: disabling and re-enabling the condition when the array input has values, displays selected
  // values visually but submits an empty input
  return (
    <Card>
      <h3 style={{ marginBottom: 0 }}>
        Rubros
        <BooleanInput
          source="enabled.categories"
          defaultValue={defaultEnabled}
          className="ruleConditionToggle hideHelperText"
          onChange={getToggleChangeHandler(setEnabled)}
          label={false}
        />
      </h3>
      <ReferenceArrayInput source="conditions.categories.categories" reference="categories">
        <AutocompleteArrayInput label={false} optionText={categoryDisplayString} disabled={!enabled} className="hideHelperText" />
      </ReferenceArrayInput>
    </Card>
  );
};
/* /Transaction approval conditions */

/* Card usage conditions */
export const ReceiptsConditionTile = () => (
  <Card>
    <h3>Captura de comprobantes</h3>
    <Box sx={conditionDescriptionStyles}>
      <BooleanInput source="receiptRequired" label="Solicitar adjuntar comprobante luego de cada transacción" className="hideHelperText" />
    </Box>
  </Card>
);

export const CashConditionsTile = () => {
  const record = useRecordContext();
  const defaultEnabled = !!record?.conditions.extractionMaxAmount;
  const [enabled, setEnabled] = useState(defaultEnabled);

  return (
    <Card>
      <Grid container spacing={5}>
        {/* Columna 1 */}
        <Grid item xs={12} md={4}>
          <h3>Extracciones de efectivo</h3>
          <Box sx={conditionDescriptionStyles}>Estas acciones sólo tienen efecto en tarjetas físicas.</Box>
        </Grid>

        {/* Columna 2 */}
        <Grid item xs={12} md={4}>
          <h3>Tipos de extracción</h3>
          <Grid container direction="column">
            <Grid item>
              <BooleanInput source="withdrawalEnabled" label="Cajero" className="hideHelperText" />
            </Grid>
            <Grid item>
              <BooleanInput source="extracashEnabled" label="Extracash" className="hideHelperText" />
            </Grid>
          </Grid>
        </Grid>

        {/* Columna 3 */}
        <Grid item xs={12} md={4}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <h3>Montos de extracciones</h3>
            </Grid>
            <Grid item>
              <BooleanInput
                source="enabled.extractionMaxAmount"
                defaultValue={defaultEnabled}
                className="ruleConditionToggle hideHelperText"
                onChange={getToggleChangeHandler(setEnabled)}
                label={false}
              />
            </Grid>
          </Grid>
          <Box sx={conditionDescriptionStyles}>Limitar los montos de extracciones</Box>
          <Grid container spacing={2} sx={{ marginTop: '1rem' }} className="hideHelperText">
            <Grid item xs={12} sm={6}>
              <NumberInput disabled={!enabled} source="conditions.extractionMaxAmount.maxAmount" label="Por transacción" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberInput disabled={!enabled} source="conditions.extractionMaxAmount.byDay" label="Por día" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberInput disabled={!enabled} source="conditions.extractionMaxAmount.byWeek" label="Por semana" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberInput disabled={!enabled} source="conditions.extractionMaxAmount.byMonth" label="Por mes" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export const ExpirationConditionTile = () => {
  const record = useRecordContext();
  const defaultEnabled = !!record?.expirationDate;
  const [enabled, setEnabled] = useState(defaultEnabled);

  return (
    <Card>
      <h3>
        Período de uso
        <BooleanInput
          source="enabled.expirationDate"
          defaultValue={defaultEnabled}
          className="ruleConditionToggle hideHelperText"
          onChange={getToggleChangeHandler(setEnabled)}
          label={false}
        />
      </h3>
      <Box sx={conditionDescriptionStyles}>Colocar una fecha límite de uso</Box>
      <Stack direction="row">
        <DateInput source="expirationDate" label={false} disabled={!enabled} />
        <Box sx={dateConditionDetailsStyles}>
          Pasada la fecha, el saldo de la tarjeta será $0. El saldo remanente estará disponible en tu balance para ser utilizado en otra tarjeta.
        </Box>
      </Stack>
    </Card>
  );
};
/* /Card usage conditions */
