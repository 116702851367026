import { ReactNode, useEffect, useState } from 'react';
import { Button, useListContext } from 'react-admin';

import { set } from '../utils';

type Props = {
  children: (selectedIds: string[], filterValues: Record<string, string>, useFullFilterResults: boolean) => ReactNode;
  omitFiltersOnDisplay?: string[];
};

export const SelectAllBulkActions = ({ children, omitFiltersOnDisplay }: Props) => {
  const { selectedIds, onSelect, filterValues, total, data, perPage } = useListContext();
  // returns and array of the size of all filter results, with the ids of visible items and empty placeholders filling the rest
  const fakeSelectionOfFullResults = (total: number = 0, data: { id: string }[] = []) => Array(total - data.length).concat(data.map((d) => d.id));

  const [useFullFilterResults, setUseFullFilterResults] = useState(false);

  useEffect(() => {
    const notAllVisibleItemsAreSelected = data?.some((d) => !selectedIds.includes(d.id));

    if (useFullFilterResults && notAllVisibleItemsAreSelected) {
      setUseFullFilterResults(false);
      const selection = data?.map((d) => d.id).filter((id) => selectedIds.includes(id)) || [];
      onSelect(selection);
    }
  }, [selectedIds, data, onSelect, useFullFilterResults, setUseFullFilterResults]);

  const resetSelection = () => {
    setUseFullFilterResults(false);
    onSelect([]);
  };

  // reset selection on page size change, for mental sanity (also gmail clears selection on page change, so fair enough)
  // also reset on filters change
  useEffect(() => {
    resetSelection();
  }, [perPage, filterValues]); // eslint-disable-line react-hooks/exhaustive-deps
  // TODO the above doesn't work with exhaustive deps, any selection refreshes automatically

  const selectAllResults = () => {
    setUseFullFilterResults(true);
    onSelect(fakeSelectionOfFullResults(total, data));
  };

  const notAllResultsAreVisible = total && data && total > data.length;
  const anyFilterIsApplied = Object.keys(filterValues).some((filterName) => filterValues[filterName].length > 0);
  const allResultsAreSelected = total === selectedIds.length && useFullFilterResults;
  const allVisibleItemsAreSelected = !data?.some((d) => !selectedIds.includes(d.id));
  const showSelectAllFilterMatches = anyFilterIsApplied && notAllResultsAreVisible && allVisibleItemsAreSelected;

  // NOTE: omitFiltersOnDisplay helps configure which filter values should not appear on messages such as
  // "Select all results for 'a-tag','a-card-rule'". For example, filtering by rule is made of both the rule id and
  // the rule name (for display), and we want to show the name but not the id.
  // This is an old quick and dirty solution, can surely be improved by working with just the rule id for the filter,
  // and sourcing names to match for display.
  let filterValuesForDisplay;
  if (omitFiltersOnDisplay) {
    filterValuesForDisplay = {};
    for (const filter in filterValues) {
      if (!omitFiltersOnDisplay.includes(filter)) set(filterValuesForDisplay, filter, filterValues[filter]);
    }
  } else {
    filterValuesForDisplay = filterValues;
  }

  return (
    <>
      {showSelectAllFilterMatches && (
        <Button variant="text" onClick={selectAllResults}>
          <>
            Selecciona{allResultsAreSelected ? 'dos' : 'r'} todos los resultados para{' '}
            {Object.values(filterValuesForDisplay)
              .map((value) => `"${value}"`)
              .join(', ')}
          </>
        </Button>
      )}
      {children(selectedIds, filterValues, useFullFilterResults)}
    </>
  );
};
