import { useTranslate, SimpleForm, TextInput, required } from 'react-admin';
import get from 'lodash/get';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';

import { set } from '../../utils';
import type { RuleFormRecord, TxConditions, Rule } from './types';
import { conditionsGridStyles } from './styles';
import { KuruEditToolbar } from '../../components/toolbars';
import {
  conditionIsNotEmpty,
  DaysConditionTile,
  CurrencyConditionTile,
  ReceiptsConditionTile,
  TimeConditionTile,
  ExpirationConditionTile,
  CategoriesConditionTile,
  MaxAmountConditionTile,
  TransactionLimitsConditionTile,
  CashConditionsTile,
} from './conditions';

// Remove disabled conditions on form submission
export const transformRuleFormData = (submittedRecord: RuleFormRecord): Rule => {
  const { enabled, conditions, expirationDate, ...ruleRootLevel } = submittedRecord;
  const { currency, ...optionalConditions } = conditions;
  const processedConditions: Partial<TxConditions> = {};

  for (const conditionName in optionalConditions) {
    const condition = get(conditions, conditionName);

    if (get(enabled, conditionName) && conditionIsNotEmpty(condition)) {
      set(processedConditions, conditionName, condition);
    }
  }

  return {
    ...ruleRootLevel,
    expirationDate: enabled.expirationDate && !!expirationDate ? expirationDate : null,
    conditions: { currency, ...processedConditions },
  };
};

export const RuleForm = () => {
  const translate = useTranslate();

  return (
    <>
      <SimpleForm
        toolbar={<KuruEditToolbar showDelete={false} />}
        sx={{
          padding: '0',
          '&+div.MuiToolbar-root': { marginTop: '-1rem' },
        }}
      >
        <Card
          sx={{
            '&.MuiCard-root': {
              padding: '1rem 1rem 0',
              margin: '0 1rem 1rem 0',
            },
          }}
        >
          <Stack direction={'row'} spacing={4}>
            <TextInput source="name" label="Nombre" placeholder={translate('rules.create.defaultName')} validate={required()} />
          </Stack>
        </Card>
        <Grid container spacing={3} sx={conditionsGridStyles}>
          <Grid item xs={4}>
            <CurrencyConditionTile />
            <CategoriesConditionTile />
            <ReceiptsConditionTile />
          </Grid>
          <Grid item xs={4}>
            <TransactionLimitsConditionTile />
            <ExpirationConditionTile />
          </Grid>
          <Grid item xs={4}>
            <MaxAmountConditionTile />
            <DaysConditionTile />
            <TimeConditionTile />
          </Grid>
        </Grid>
        <CashConditionsTile />
      </SimpleForm>
    </>
  );
};
