import { Stack, Chip } from '@mui/material';
import { format, lastDayOfMonth } from 'date-fns';
import CurrencyDisplay from '../../components/CurrencyDisplay';

interface ConditionsChipsFieldProps {
  operation: { type: string; amount: number };
  expr: string;
}

const operationMap: Record<string, string> = {
  SET: 'Llevar el saldo al monto indicado',
  INCREMENT: 'Incrementar el saldo en el monto indicado',
  DECREMENT: 'Disminuir el saldo en el monto indicado',
  ZERO: 'Llevar el saldo a cero',
};

const ConditionsChipsField = ({ operation, expr }: ConditionsChipsFieldProps) => {
  const formattedExpr = formatCronExpression(expr);

  return (
    <Stack direction="row" spacing={1}>
      <Chip label={formattedExpr} size="small" />
      <Chip label={operationMap[operation.type]} size="small" />
      <Chip label={operation.type === 'ZERO' ? '$ 0' : <CurrencyDisplay amount={operation.amount} currency="ARS" />} size="small" />
    </Stack>
  );
};

const formatCronExpression = (expr: string): string => {
  const [minute, hour, dayOfMonth, month, , year] = expr.split(' ');

  const now = new Date();
  let targetDate;
  const targetYear = parseInt(year);

  // Manejar el caso "Último día del mes"
  if (dayOfMonth === 'L') {
    targetDate = new Date(now.getFullYear(), now.getMonth(), lastDayOfMonth(now).getDate(), parseInt(hour), parseInt(minute));
    return `Último día de cada mes a las ${format(targetDate, 'HH:mm')}`;
  }

  // Manejar el caso "Cada mes" (* en el mes)
  if (month === '*') {
    targetDate = new Date(now.getFullYear(), now.getMonth(), parseInt(dayOfMonth), parseInt(hour), parseInt(minute));
    return `${format(targetDate, 'd')} de cada mes a las ${format(targetDate, 'HH:mm')}`;
  }

  // Formateo por defecto (día y hora especificados)
  targetDate = new Date(
    targetYear,
    parseInt(month) - 1, // Meses en cron van de 1 a 12, pero en JS de 0 a 11
    parseInt(dayOfMonth),
    parseInt(hour),
    parseInt(minute),
  );

  return format(targetDate, 'd/MM/yyyy HH:mm');
};

export default ConditionsChipsField;
