// Formatters to adjust rule data from/to API format

import get from 'lodash/get';

import { set } from '../../utils';
import type {
  TimePair,
  TimeCondition,
  DaysCondition,
  Rule,
  ApiRule,
  ApiTxConditions,
  ApiTimeCondition,
  ApiDaysCondition,
  RequestRule,
  TransactionLimitsCondition,
  ApiTransactionLimitsCondition,
  MaxAmountCondition,
  ApiExtractionMaxAmountCondition,
  ExtractionMaxAmountCondition,
} from './types';

export const formatRuleRequest = (rule: Rule): RequestRule => {
  // id and companyId are not part of the request body
  const { id, companyId, expirationDate, conditions, ...restOfRule } = rule;
  const { days, time, transactionLimits, extractionMaxAmount, maxAmount, ...restOfConditions } = conditions;
  const requestBody: Partial<RequestRule> = { ...restOfRule };
  const requestConditions: Partial<ApiTxConditions> = { ...restOfConditions };

  if (expirationDate) {
    requestBody.expirationDate = expirationConditionParse(expirationDate);
  } else {
    requestBody.expirationDate = null;
  }

  if (days) {
    requestConditions.days = daysConditionParse(days);
  }

  if (time) {
    requestConditions.time = timeConditionParse(time);
  }

  if (transactionLimits) {
    requestConditions.transactionLimits = transactionLimitsConditionParse(transactionLimits);
  }

  if (extractionMaxAmount) {
    requestConditions.extractionMaxAmount = extractionLimitsConditionParse(extractionMaxAmount);
  }

  if (maxAmount) {
    requestConditions.maxAmount = maxAmountConditionParse(maxAmount);
  }

  return { ...requestBody, conditions: requestConditions as ApiTxConditions } as RequestRule;
};

export const formatRuleResponse = (rule: ApiRule): Rule => {
  const { expirationDate, conditions, ...restOfRule } = rule;
  const { time, days, ...unchangedConditions } = conditions;
  const response: Partial<ApiRule> = { ...restOfRule, conditions: unchangedConditions };

  const responseRule = response as Rule;

  if (days) {
    responseRule.conditions.days = daysConditionFormat(days);
  }

  if (time) {
    responseRule.conditions.time = timeConditionFormat(time);
  }

  if (expirationDate) {
    responseRule.expirationDate = expirationDateFormat(expirationDate);
  }

  return responseRule;
};

/* Transaction approval conditions */
// Needed because the slider input for usage time returns base 10 numbers for
// time values
const getTimePairFromNumber = (timeNumber: number): TimePair => {
  return {
    hours: Math.trunc(timeNumber),
    minutes: (timeNumber % 1) * 60,
  };
};

// Depending on whether the form record results from a rule creation or edition,
// and whether the input for time has been touched or not, the time values will
// be an array or an object.
export const timeConditionParse = (time: TimeCondition): ApiTimeCondition => {
  return {
    from: getTimePairFromNumber(time[0]),
    to: getTimePairFromNumber(time[1]),
  };
};

export const daysConditionParse = (days: DaysCondition): ApiDaysCondition => {
  return {
    daysAllowed: days.daysAllowed?.map((day) => {
      return parseInt(day, 10);
    }),
  };
};

export const parseLimitsCondition = <TInput, TOutput>(limits: TInput): TOutput => {
  const populatedLimits = {} as TOutput;

  for (const limitName in limits) {
    const limitValue = get(limits, limitName);
    if (limitValue) {
      set(populatedLimits, limitName, limitValue);
    }
  }

  return populatedLimits;
};

export const maxAmountConditionParse = (maxAmountLimits: MaxAmountCondition): MaxAmountCondition => {
  return parseLimitsCondition<MaxAmountCondition, MaxAmountCondition>(maxAmountLimits);
};

export const transactionLimitsConditionParse = (transactionLimits: TransactionLimitsCondition): ApiTransactionLimitsCondition => {
  return parseLimitsCondition<TransactionLimitsCondition, ApiTransactionLimitsCondition>(transactionLimits);
};

export const extractionLimitsConditionParse = (extractionLimits: ExtractionMaxAmountCondition): ApiExtractionMaxAmountCondition => {
  return parseLimitsCondition<ExtractionMaxAmountCondition, ApiExtractionMaxAmountCondition>(extractionLimits);
};

// Depending on whether the form record results from a rule creation or edition,
// and whether the input for expirationDate has been touched or not, the value
// will be a number or a string.
export const expirationConditionParse = (expiration: string): number => {
  return Date.parse(expiration + 'T00:00:00.000-03:00'); // Forcing GMT -3
};

const timePairToNumber = (timePair: TimePair): number => {
  return timePair?.hours + (timePair?.minutes ? timePair?.minutes / 60 : 0);
};

const daysConditionFormat = (days: ApiDaysCondition): DaysCondition => {
  return { daysAllowed: days?.daysAllowed?.map((d) => d.toString()) };
};

const timeConditionFormat = (timePairs: Record<string, TimePair>): number[] => {
  return [timePairToNumber(timePairs.from), timePairToNumber(timePairs.to)];
};

const expirationDateFormat = (expirationDate: number): string => {
  const theDate = !expirationDate ? new Date() : new Date(expirationDate);
  return theDate?.toJSON()?.slice(0, 10); // replace with getFullYear, Month, Date
};
