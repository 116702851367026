import { Button, Datagrid, FunctionField, TextField, useDataProvider, useNotify } from 'react-admin';
import { useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';

import Card from '@mui/material/Card';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import VerifiedIcon from '@mui/icons-material/Verified';

import { CardHolder, displayString as cardHolderDisplayString } from '../resources/cardHolders';
import { getErrorHandler } from '../utils';
import { KuruDataProvider } from '../dataProvider/kuruDataProvider';
import TabsComponent, { TabDefinition } from '../components/TabsComponent';
import { Company } from '../resources/companies';

export enum TerroristMatchEntity {
  COMPANIES = 'companies',
  CARDHOLDERS = 'card-holders',
}

const { COMPANIES, CARDHOLDERS } = TerroristMatchEntity;

export const TerroristMatches = () => {
  return (
    <Card>
      <h3>Coincidencias con listados de terroristas</h3>
      <TabsComponent>
        <TabDefinition label="Personas">
          <TerroristMatchesList type={TerroristMatchEntity.CARDHOLDERS} />
        </TabDefinition>
        <TabDefinition label="Compañías">
          <TerroristMatchesList type={TerroristMatchEntity.COMPANIES} />
        </TabDefinition>
      </TabsComponent>
    </Card>
  );
};

const TerroristMatchesList = ({ type }: { type: TerroristMatchEntity }) => {
  const notify = useNotify();
  const forCompanies = type === COMPANIES;
  const forCardHolders = !forCompanies;
  const responseDataAttribute = forCompanies ? 'companies' : 'cardHolders';

  const dataProvider = useDataProvider();
  const [matches, setMatches] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dataProvider
      .getTerroristMatches(type)
      .then(({ data }: Record<string, any>) => {
        setMatches(data[responseDataAttribute]);
        setLoading(false);
      })
      .catch((error: Error) => {
        setLoading(false);
        getErrorHandler(notify)(error);
      });
  }, [dataProvider, notify, responseDataAttribute, type]);

  return (
    <>
      {loading && <>Cargando resultados...</>}
      {!loading && (
        <Datagrid data={matches} sort={{ field: 'name', order: 'ASC' }}>
          {forCardHolders && <>{...cardHoldersFields}</>}
          {forCompanies && <>{...companiesFields}</>}
        </Datagrid>
      )}
    </>
  );
};

const MarkPersonOkButton = ({ id }: { id: string }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider<KuruDataProvider>();
  const { mutate, isPending } = useMutation({
    mutationFn: (id: string) => {
      return dataProvider.markNotTerrist(CARDHOLDERS, [id]);
    },
    onError: (error) => {
      getErrorHandler(notify)(error);
    },
    onSuccess: () => {
      notify('kuru.actions.terroristMatches.clearPersonSuccess');
    },
  });

  return <Button endIcon={<HowToRegIcon />} onClick={() => mutate(id)} disabled={isPending} />;
};

const MarkCompanyOkButton = ({ id }: { id: string }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider<KuruDataProvider>();
  const { mutate, isPending } = useMutation({
    mutationFn: (id: string) => {
      return dataProvider.markNotTerrorist(COMPANIES, id);
    },
    onError: (error) => {
      getErrorHandler(notify)(error);
    },
    onSuccess: () => {
      notify('kuru.actions.terroristMatches.clearCompanySuccess');
    },
  });

  return <Button endIcon={<VerifiedIcon />} onClick={() => mutate(id)} disabled={isPending} />;
};

const cardHoldersFields = [
  <FunctionField label="Nombre" render={cardHolderDisplayString} />,
  <TextField source="id" />,
  <FunctionField render={(r: CardHolder) => (r ? <MarkPersonOkButton id={r.id} /> : null)} />,
];

const companiesFields = [
  <TextField source="name" />,
  <TextField source="id" />,
  <FunctionField render={(r: Company) => (r ? <MarkCompanyOkButton id={r.id} /> : null)} />,
];
