import { GetListParams, GetListResult } from 'react-admin';
import { ResourceHandlerContext } from './types';
import { Administrator, ApiAdministrator } from '../resources/administrators';
import { getQueryString } from './utils';
import { Transaction, companyTransactionTypes } from '../resources/transactions';
import { ApiRule, Category, Rule } from '../resources/rules';
import { formatRuleResponse } from '../resources/rules/dataHelpers';
import { Notification } from '../resources/notifications';

export async function getAdministrators(context: ResourceHandlerContext, companyId: string, params: GetListParams): Promise<GetListResult<Administrator>> {
  const url = `companies/${companyId}/administrators` + getQueryString(params?.pagination);

  const { json } = await context.request(url, {});
  const response: { total: number; elements: ApiAdministrator[] } = json;

  const data: any[] = response.elements.map((user) => {
    const role = user.app_metadata.company_access ? user.app_metadata.company_access[companyId] : undefined;

    return {
      ...user,
      id: user.user_id,
      role: role,
    };
  });

  return {
    data,
    total: response.total,
  };
}

export async function getCompanyTransactions(context: ResourceHandlerContext, companyId: string, params: GetListParams): Promise<GetListResult<Transaction>> {
  const filterQueryParams = [];
  const { toDate, fromDate, type } = params?.filter || {};

  if (fromDate) {
    filterQueryParams.push('fromDate=' + Date.parse(fromDate));
  }

  if (toDate) {
    filterQueryParams.push('toDate=' + Date.parse(toDate));
  }

  const typeArray = type ? [type] : companyTransactionTypes;
  for (const t of typeArray) {
    filterQueryParams.push('type=' + t);
  }

  const { json } = await context.request(`companies/${companyId}/transactions` + getQueryString(params?.pagination, filterQueryParams), {});
  const response: { total: number; transactions: Transaction[] } = json;
  return { total: response.total, data: json.transactions };
}

export async function getCategories(context: ResourceHandlerContext, params: GetListParams): Promise<GetListResult<{ id: string }>> {
  const url = `categories` + getQueryString(params?.pagination);
  const { json } = await context.request(url, {});
  const response: { total: number; categories: Category[] } = json;

  const searchString = params.filter?.q;
  const filteredCategories =
    (!searchString && response.categories) ||
    response.categories.filter((category: Category) => {
      return category.includes(searchString.toUpperCase());
    });

  return {
    total: response.categories.length,
    data: filteredCategories.map((category) => ({ id: category })),
  };
}

export async function getRules(context: ResourceHandlerContext, companyId: string, params: GetListParams): Promise<GetListResult<Rule>> {
  const url = `companies/${companyId}/rules` + getQueryString(params?.pagination);
  const { json } = await context.request(url, {});
  const response: { total: number; rules: ApiRule[] } = json;
  return {
    total: response.total,
    data: response.rules.map(formatRuleResponse),
  };
}

export async function getNotifications(context: ResourceHandlerContext, companyId: string, params: GetListParams): Promise<GetListResult<Notification>> {
  const url = `companies/${companyId}/notifications` + getQueryString(params?.pagination);
  const { json } = await context.request(url, {});
  const response: { total: number; notifications: Notification[] } = json;
  return {
    total: response.total,
    data: response.notifications,
  };
}
