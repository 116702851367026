import {
  Datagrid,
  TextField,
  ReferenceField,
  NumberField,
  SelectInput,
  ReferenceArrayInput,
  Show,
  BooleanField,
  Edit,
  Create,
  SimpleForm,
  useRecordContext,
  Labeled,
  useNotify,
  FunctionField,
  useTranslate,
} from 'react-admin';

import { Stack, Box } from '@mui/system';

import { displayString as cardHolderDisplayString } from '../cardHolders';
import { KuruEditCancelToolbar, KuruEditToolbar, KuruResourceActions } from '../../components/toolbars';
import { getErrorHandler } from '../../utils';
import { CommonProps } from '../../types';
import { kuruTeal } from '../../theme/kuru-theme';
import { Card } from './types';
import AutoCompleteReference from '../rules/AutoCompleteReference';
import { SingleUpdateBalanceButton } from './CardBalanceUpdate';
import { displayString } from '.';

export { CardList } from './CardList';

/*
  For styling:
  Dtagrid root has the gray border (RaDatagrid-root kuru-cards-table css-1gryonl-RaDatagrid-root)
  round border come from datagrid cells
 */
export const CardSimpleList = () => {
  const translate = useTranslate();

  return (
    <Datagrid
      bulkActionButtons={false}
      rowClick="show"
      className="kuru-cards-table"
      sx={{
        '.RaDatagrid-headerCell': {
          backgroundColor: kuruTeal.light,
        },
      }}
    >
      <TextField source="lastFour" label="Últimos dígitos" />
      <NumberField source="balance" label="Saldo" locales="es-AR" options={{ style: 'currency', currency: 'ARS', minimumFractionDigits: 2 }} />
      <ReferenceField reference="rules" source="ruleId" label="Regla" emptyText="-" />
      <FunctionField label="Tipo" render={({ type }) => translate('resources.cards.type.' + type)} />
    </Datagrid>
  );
};

const CardTitle = () => {
  const record: Card | undefined = useRecordContext();
  if (!record) return;
  return <span>Tarjeta {record ? displayString(record) : ''}</span>;
};

const CardForm = ({ children = undefined }: CommonProps) => {
  return (
    <SimpleForm toolbar={<KuruEditToolbar showDelete={false} />}>
      {children}
      <AutoCompleteReference />
    </SimpleForm>
  );
};

export const CardEdit = () => {
  const notify = useNotify();
  const onError = getErrorHandler(notify);

  return (
    <Edit title="Editar tarjeta" mutationMode="pessimistic" queryOptions={{ onError }} mutationOptions={{ onError }} actions={<KuruEditCancelToolbar />}>
      <Stack className="kuru-fields-group" spacing={3} direction={'row'} sx={{ padding: '16px' }}>
        <Labeled>
          <ReferenceField reference="cardHolders" source="cardHolderId" label="Colaborador/a" />
        </Labeled>
        <Labeled>
          <TextField source="lastFour" label="Últimos dígitos" />
        </Labeled>
      </Stack>
      <CardForm />
    </Edit>
  );
};

export const CardCreate = () => {
  const notify = useNotify();

  return (
    <Create redirect="show" mutationOptions={{ onError: getErrorHandler(notify) }}>
      <CardForm>
        <ReferenceArrayInput source="cardHolderId" reference="cardHolders">
          <SelectInput optionText={(holder) => cardHolderDisplayString(holder)} label="Colaborador/a" />
        </ReferenceArrayInput>
      </CardForm>
    </Create>
  );
};

const CardActions = () => (
  <KuruResourceActions showDelete={false}>
    <SingleUpdateBalanceButton />
  </KuruResourceActions>
);

export const CardShow = () => {
  const notify = useNotify();
  const translate = useTranslate();

  return (
    <Show actions={<CardActions />} title={<CardTitle />} queryOptions={{ onError: getErrorHandler(notify) }}>
      <Stack className="kuru-fields-group" spacing={3} divider={<Box className="kuru-fields-group-divider" component="hr" />}>
        <Stack spacing={6} direction={'row'}>
          <Labeled>
            <ReferenceField source="cardHolderId" reference="cardHolders" link="show" label="Colaborador/a" />
          </Labeled>
          <Labeled>
            <TextField source="lastFour" label="Últimos dígitos" />
          </Labeled>
          <Labeled>
            <FunctionField label="Tipo" render={({ type }) => translate('resources.cards.type.' + type)} />
          </Labeled>
          <Labeled>
            <NumberField source="balance" label="Saldo" locales="es-AR" options={{ style: 'currency', currency: 'ARS', minimumFractionDigits: 2 }} />
          </Labeled>
          <Labeled>
            <ReferenceField reference="rules" source="ruleId" label="Regla" emptyText="-" />
          </Labeled>
          <Labeled>
            <BooleanField source="isActive" label="Activa" />
          </Labeled>
          <Labeled>
            <BooleanField source="isPomeloActive" label="Activa en proveedor" />
          </Labeled>
        </Stack>
      </Stack>
    </Show>
  );
};
