import { forwardRef, MutableRefObject, ReactNode } from 'react';

const WrapWithRef = forwardRef((props: { children: ReactNode }, ref) => {
  const { children, ...defaultProps } = props;
  return (
    <div ref={ref as MutableRefObject<HTMLDivElement>} {...defaultProps}>
      {children}
    </div>
  );
});

WrapWithRef.displayName = 'WrapWithRef';

export default WrapWithRef;
