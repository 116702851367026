import { GetListParams, GetListResult, GetOneParams, GetOneResult } from 'react-admin';
import { getQueryString } from '../../dataProvider/utils';
import { ResourceHandlerContext } from '../../dataProvider/types';
import { Company } from '.';

export async function getList(context: ResourceHandlerContext, params: GetListParams): Promise<GetListResult<Company>> {
  const url = `companies` + getQueryString(params?.pagination);

  const { json } = await context.request(url, {});

  const response: { total: number; companies: Company[] } = json;

  const searchString = params.filter.q;
  const filteredCompanies =
    (!searchString && response.companies) ||
    response.companies.filter((company: Company) => {
      return company.name.toLowerCase().includes(searchString.toLowerCase());
    });

  return {
    data: filteredCompanies,
    total: response.total,
  };
}

export async function getCompany(context: ResourceHandlerContext, params: GetOneParams): Promise<GetOneResult<Company>> {
  const { json } = await context.request(`companies/${params.id}`, {});

  return { data: json };
}
