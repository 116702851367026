import {
  Datagrid,
  EmailField,
  DateField,
  List,
  TextField,
  TextInput,
  FunctionField,
  SimpleForm,
  Button,
  useNotify,
  useDataProvider,
  useRefresh,
  FileInput,
  FileField,
  BooleanField,
  useTranslate,
} from 'react-admin';
import { useState, useEffect } from 'react';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import get from 'lodash/get';

import AddCardIcon from '@mui/icons-material/AddCard';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Drawer from '@mui/material/Drawer';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';

import { CardHolder } from './types';
import { displayExternalId, displayString } from './index';
import { KuruListActions, KuruEditToolbar } from '../../components/toolbars';
import { getErrorHandler } from '../../utils';
import { BulkActionParams, FormFileUpload, KuruListFilter } from '../../types';
import AutoCompleteReference from '../rules/AutoCompleteReference';
import { KuruDataProvider, SendInvitationsParams } from '../../dataProvider/kuruDataProvider';
import ArrayChipsField from '../../components/ArrayChipsField';
import { SelectAllBulkActions } from '../../components/SelectAllBulkActions';
import { NumberInput } from 'react-admin';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// TODO: handle error on search tags with wrong format
const cardHoldersFilters = [
  <TextInput label="Filtrar por etiquetas" source="tags" alwaysOn resettable={true} placeholder="etiqueta1 etiqueta2" />,
  <TextInput label="Nombre de colaborador/a" source="nameStartsWith" resettable />,
  <TextInput label="Apellido de colaborador/a" source="surnameStartsWith" resettable />,
];

const CardHolderImportButton = () => {
  const [showCardHoldersImport, setShowCardHoldersImport] = useState(false);
  const dataProvider = useDataProvider();

  const notify = useNotify();

  const importCardholders = async ({ importFile }: { importFile: FormFileUpload }) => {
    try {
      await dataProvider.importFile('cardHolders', { importFile });
    } catch (error: unknown) {
      getErrorHandler(notify)(error, { resource: 'cardHolders' });
      return;
    }

    notify('resources.cardHolders.importUpload');
    setShowCardHoldersImport(false);
  };

  // TODO: cambiar el botón que dice "Guardar"
  return (
    <>
      <Button label="Importar colaboradores" onClick={() => setShowCardHoldersImport(true)} startIcon={<FileUploadIcon />} />
      <Drawer className="actionDrawer" open={showCardHoldersImport} anchor="right" onClose={() => setShowCardHoldersImport(false)}>
        <h3>Importar colaboradores</h3>
        <div>Subir archivo de colaboradores (formato csv)</div>
        <SimpleForm onSubmit={importCardholders as SubmitHandler<FieldValues>}>
          {/*
              NOTE: react-dropzone declares mime type for csv is uncertain
              https://react-dropzone.org/#!/Accepting%20specific%20file%20types
            */}
          <FileInput source="importFile" accept={{ 'text/csv': ['.csv'] }} label={false}>
            <FileField source="src" title="title" />
          </FileInput>
        </SimpleForm>
      </Drawer>
    </>
  );
};

export const CardHolderImportSkeletonCsvButton = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleDownload = async () => {
    try {
      const response = await dataProvider.importCardHoldersSkeletonCsv('cardHolders');

      const csvBlob = new Blob([response.data], { type: 'text/csv' });

      const downloadUrl = URL.createObjectURL(csvBlob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = 'csv_template.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      URL.revokeObjectURL(downloadUrl);

      notify('Archivo CSV descargado correctamente', { type: 'info' });
    } catch (error) {
      getErrorHandler(notify)(error, { resource: 'downloadSkeletonCsv' });
    }
  };

  return <Button label="Descargar template CSV" onClick={handleDownload} startIcon={<FileDownloadIcon />} />;
};

export const CardHolderList = () => {
  const notify = useNotify();

  const customActions = [<CardHolderImportButton />, <CardHolderImportSkeletonCsvButton />];

  return (
    <List
      empty={false}
      actions={<KuruListActions showFilters={true}>{...customActions}</KuruListActions>}
      filters={cardHoldersFilters}
      queryOptions={{ onError: getErrorHandler(notify) }}
    >
      <Datagrid rowClick="show" bulkActionButtons={<CardHolderBulkActions />}>
        <FunctionField source="name" label="Nombre" render={displayString} sortable={false} />
        <EmailField source="email" sortable={false} />
        <TextField source="phoneNumber" label="Teléfono" sortable={false} />
        <DateField source="createdAt" label="Fecha de alta" locales="es" sortable={false} />
        <FunctionField
          label="Tarjetas"
          render={(record: CardHolder) => (
            <Badge badgeContent={record.cardsCount.toString()}>
              <CreditCardIcon />
            </Badge>
          )}
        />
        <ArrayChipsField source="tags" />
        <BooleanField source="phoneValidated" label="Teléfono validado" sortable={false} />
        <FunctionField source="externalId" label="Id externa" render={displayExternalId} sortable={false} />
      </Datagrid>
    </List>
  );
};

const CardHolderBulkActions = () => (
  <SelectAllBulkActions omitFiltersOnDisplay={['ruleId']}>
    {(selectedIds, filterValues, useFullFilterResults) => (
      <>
        <CreateCardsButton selectedIds={selectedIds} filterValues={filterValues} useFullFilterResults={useFullFilterResults} />
        <SendSelectedInvitationsButton selectedIds={selectedIds} filterValues={filterValues} useFullFilterResults={useFullFilterResults} />
      </>
    )}
  </SelectAllBulkActions>
);

const CreateCardsButton = ({ selectedIds, filterValues, useFullFilterResults }: BulkActionParams) => {
  const [showCardsCreation, setShowCardsCreation] = useState(false);
  const toggleShowCardsCreation = (value: boolean) => {
    setShowCardsCreation(value);
  };
  const [showGiftCardsCreation, setShowGiftCardsCreation] = useState(false);
  const toggleShowGiftCardsCreation = (value: boolean) => {
    setShowGiftCardsCreation(value);
  };
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const translate = useTranslate();
  const refresh = useRefresh();
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const incompatibleFilters = ['nameStartsWith', 'surnameStartsWith'];
    const appliedFiltersNames = Object.keys(filterValues);
    const incompatibleFiltersAreApplied = incompatibleFilters.some((filter) => appliedFiltersNames.includes(filter));
    setIsDisabled(incompatibleFiltersAreApplied && !!useFullFilterResults);
  }, [filterValues, setIsDisabled, useFullFilterResults]);

  // returns the handler to create cards with the selected cardholders already bound
  const createCardsForSelection = (selectedIds: string[], filterValues: KuruListFilter) => {
    let params: Partial<{ ids: string[] } & KuruListFilter>;

    if (useFullFilterResults) {
      params = filterValues;
    } else {
      params = { ids: selectedIds };
    }

    return async ({ ruleId }: { ruleId: string }) => {
      params.ruleId = ruleId;

      try {
        await dataProvider.bulkCreateCards('cardHolders', params);
      } catch (error: unknown) {
        getErrorHandler(notify)(error, { resource: 'cardHolders' });
        return;
      }

      notify('resources.cardHolders.createCards');
      toggleShowCardsCreation(false);
      refresh();
    };
  };

  const createGiftCardsForSelection = (selectedIds: string[], filterValues: KuruListFilter) => {
    let params: Partial<{ ids: string[]; amount: number } & KuruListFilter>;

    if (useFullFilterResults) {
      params = filterValues;
    } else {
      params = { ids: selectedIds };
    }

    return async ({ amount }: { amount: number }) => {
      params.amount = amount;

      try {
        await dataProvider.bulkCreateGiftCards('cardHolders', params);
      } catch (error: unknown) {
        getErrorHandler(notify)(error, { resource: 'cardHolders' });
        return;
      }

      notify('resources.cardHolders.createGiftCards');
      toggleShowGiftCardsCreation(false);
      refresh();
    };
  };

  return (
    <>
      <Tooltip title={isDisabled ? 'Acción no disponible seleccionando todos los resultados por nombre o apellido de colaborador' : false}>
        {/* span provides anchorage for the tooltip */}
        <span>
          <Button disabled={isDisabled} label="Crear tarjetas MasterCard" onClick={() => toggleShowCardsCreation(true)} startIcon={<AddCardIcon />} />
          &nbsp;&nbsp;
          {import.meta.env.VITE_FEATURE_GIFTCARD == 'enabled' ? (
            <Button disabled={isDisabled} label="Crear GiftCard" onClick={() => toggleShowGiftCardsCreation(true)} startIcon={<AddCardIcon />} />
          ) : (
            <span></span>
          )}
        </span>
      </Tooltip>
      <Drawer className="actionDrawer" open={showCardsCreation} anchor="right" onClose={() => toggleShowCardsCreation(false)}>
        <h3>Crear tarjetas para colaboradores</h3>
        <div>
          Vas a crear tarjetas para {selectedIds.length} colaboradores
          {useFullFilterResults ? (
            <>
              , todos los resultantes filtrando por:
              <ul>
                {Object.keys(filterValues).map((filter) => {
                  const filterLabel = translate('filters.' + filter);
                  return (
                    <li>
                      {filterLabel}: {get(filterValues, filter)}
                    </li>
                  );
                })}
              </ul>
            </>
          ) : (
            '.'
          )}
        </div>
        <SimpleForm
          onSubmit={createCardsForSelection(selectedIds, filterValues) as SubmitHandler<FieldValues>}
          toolbar={<KuruEditToolbar showDelete={false} />}
        >
          <AutoCompleteReference />
        </SimpleForm>
      </Drawer>
      <Drawer className="actionDrawer" open={showGiftCardsCreation} anchor="right" onClose={() => toggleShowGiftCardsCreation(false)}>
        <h3>Crear GiftCards para colaboradores</h3>
        <div>
          Vas a crear GiftCards para {selectedIds.length} colaboradores
          {useFullFilterResults ? (
            <>
              , todos los resultantes filtrando por:
              <ul>
                {Object.keys(filterValues).map((filter) => {
                  const filterLabel = translate('filters.' + filter);
                  return (
                    <li>
                      {filterLabel}: {get(filterValues, filter)}
                    </li>
                  );
                })}
              </ul>
            </>
          ) : (
            '.'
          )}
        </div>
        <SimpleForm
          onSubmit={createGiftCardsForSelection(selectedIds, filterValues) as SubmitHandler<FieldValues>}
          toolbar={<KuruEditToolbar showDelete={false} />}
        >
          <NumberInput source="amount" label="Monto" />
        </SimpleForm>
      </Drawer>
    </>
  );
};

// Send invitations to the current selection of users (regardless of their phone verification status)
const SendSelectedInvitationsButton = ({ selectedIds, filterValues, useFullFilterResults }: BulkActionParams) => {
  const dataProvider: KuruDataProvider = useDataProvider();
  const notify = useNotify();

  const sendInvitations = async () => {
    let params: SendInvitationsParams = {};

    if (useFullFilterResults) {
      params = filterValues;
    } else {
      params = { ids: selectedIds };
    }

    await dataProvider
      .sendInvitations('cardHolders', params)
      .then(() => {
        notify('resources.cardHolders.invitationSent');
      })
      .catch((error: unknown) => getErrorHandler(notify)(error, { resource: 'cards' }));
  };

  return <Button label="Enviar invitaciones" onClick={sendInvitations} startIcon={<MailOutlineIcon />} />;
};
