export const conditionsGridStyles = {
  '.MuiGrid-item .MuiPaper-root': {
    border: '1px solid #ccc',
    marginBottom: '1rem',
    padding: '1rem',
    h3: {
      margin: '0 0 1rem',
    },
  },
};

export const conditionDescriptionStyles = {
  backgroundColor: '#eee',
  borderRadius: '5px',
  color: '#555',
  padding: '10px',
  marginBottom: '1rem',
};

export const dateConditionDetailsStyles = {
  flexBasis: '65%',
  padding: '1rem',
  fontSize: '0.75rem',
};
