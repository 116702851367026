import {
  Toolbar,
  SaveButton,
  DeleteButton,
  Button,
  CreateButton,
  useListContext,
  useDataProvider,
  useNotify,
  TopToolbar,
  EditButton,
  FilterButton,
  useTranslate,
  ShowButton,
} from 'react-admin';
import { ReactNode, useState } from 'react';

import DownloadIcon from '@mui/icons-material/GetApp';
import Drawer from '@mui/material/Drawer';

import { dateTimeLocaleString, getErrorHandler } from '../utils';
import { CommonProps } from '../types';

type ActionsProps = CommonProps & {
  filters?: ReactNode[];
  exporter?: boolean | string;
  create?: boolean;
};

export const KuruCreateToolbar = () => (
  <Toolbar>
    <SaveButton alwaysEnable />
  </Toolbar>
);

export const KuruEditToolbar = ({ showDelete = true }: CommonProps) => (
  <Toolbar sx={{ justifyContent: 'space-between' }}>
    <SaveButton alwaysEnable />
    {showDelete && <DeleteButton />}
  </Toolbar>
);

// TODO remove, replace with above edit with prop
export const KuruNoDeleteEditToolbar = () => (
  <Toolbar sx={{ justifyContent: 'space-between' }}>
    <SaveButton alwaysEnable label="Confirmar" icon={<></>} />
  </Toolbar>
);

export const KuruCustomToolbar = ({ children, ..._ }: CommonProps) => {
  return <Toolbar sx={{ justifyContent: 'space-between' }}>{children}</Toolbar>;
};

export const KuruResourceActions = ({ children = undefined, showDelete = true }: CommonProps) => (
  <TopToolbar>
    {children}
    <EditButton />
    {showDelete && <DeleteButton mutationMode="pessimistic" />}
  </TopToolbar>
);

export const KuruEditCancelToolbar = () => (
  <TopToolbar>
    <ShowButton startIcon={false} label="Cancelar" />
  </TopToolbar>
);

// exporter='confirm' shows confirmation drawer for the export action
// true shows an export button without confirmation, false hides it
export const KuruListActions = ({ children, showFilters = false, exporter = true, create = true, ..._ }: ActionsProps & { showFilters?: boolean }) => {
  // NOTE: this marks buttons as disabled just visually; some buttons (like CreateButton) support the disabled property, but some others
  // like FilterButton don't, so a proper implementation will take some extra work.
  const { selectedIds } = useListContext();
  const disabled = selectedIds?.length > 0 ? true : false;

  return (
    <TopToolbar className={disabled ? 'disabled' : ''}>
      {children}
      {showFilters && <FilterButton disableSaveQuery />}
      {exporter && <KuruExportButton confirm={exporter === 'confirm'} />}
      {create && <CreateButton />}
    </TopToolbar>
  );
};

const createActionDrawer = () => {
  // TODO
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [showDrawer, setShowDrawer] = useState(false);
  const toggleVisibility = (value: boolean) => {
    setShowDrawer(value);
  };

  const KuruDrawer = ({ children, ..._ }: CommonProps) => (
    <Drawer className="actionDrawer" open={showDrawer} anchor="right" onClose={() => toggleVisibility(false)}>
      {children}
    </Drawer>
  );

  return {
    toggleVisibility,
    drawerComponent: KuruDrawer,
  };
};

// If confirm is false, it returns a button that triggers the resource export.
// If it's true, it returns a button+drawer combo, for the user to review the export
// configuration and confirm the operation.
// TODO
// eslint-disable-next-line react/prop-types
const KuruExportButton = ({ confirm = false, ...props }) => {
  const context = useListContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const translate = useTranslate();
  const { filterValues } = context;

  let onClickHandler, KuruDrawer, toggleDrawer: (value: boolean) => void;

  if (confirm) {
    const { toggleVisibility, drawerComponent } = createActionDrawer();
    toggleDrawer = toggleVisibility;
    KuruDrawer = drawerComponent;
  }

  const onSubmit = () => {
    dataProvider
      .export(context.resource, filterValues)
      .then(() => {
        notify('ra.notification.exportStarted');
        if (confirm) toggleDrawer(false);
      })
      .catch((error: unknown) => getErrorHandler(notify)(error, { resource: 'exports' }));
  };

  if (confirm) {
    onClickHandler = () => toggleDrawer(true);
  } else {
    onClickHandler = onSubmit;
  }

  return (
    <>
      <Button onClick={onClickHandler} label="ra.action.export" {...props}>
        {<DownloadIcon />}
      </Button>
      {confirm && KuruDrawer && (
        <KuruDrawer>
          <h3>Exportar transacciones</h3>
          <div>
            Vas a exportar un listado de transacciones aplicando los siguientes filtros:
            <ul>
              {(Object.keys(filterValues).length &&
                Object.keys(filterValues).map((filterName) => {
                  const value = filterValues[filterName];

                  switch (filterName) {
                    case 'status':
                      return <li>Estado: {translate('resources.transactions.status.' + value)}</li>;
                    case 'type':
                      return <li>Tipo: {translate('resources.transactions.type.' + value)}</li>;
                    case 'cardHolderTags':
                      return <li>Etiquetas de colaborador/a: {value}</li>;
                    case 'fromDate':
                      return <li>Fecha desde: {dateTimeLocaleString(value)}</li>;
                    case 'toDate':
                      return <li>Fecha hasta: {dateTimeLocaleString(value)}</li>;
                    case 'nameStartsWith':
                      return <li>Nombre de colaborador/a: {value}</li>;
                    case 'surnameStartsWith':
                      return <li>Apellido de colaborador/a: {value}</li>;
                    case 'cardHolderNameStartsWith':
                      return <li>Nombre de colaborador/a: {value}</li>;
                    case 'cardHolderSurnameStartsWith':
                      return <li>Apellido de colaborador/a: {value}</li>;
                    case 'ruleName':
                      return <li>Regla: {value}</li>;
                    default:
                      return;
                  }
                })) || <li>todas las transacciones</li>}
            </ul>
            <Button onClick={onSubmit} label="ra.action.export" variant="contained" size="medium">
              {<DownloadIcon />}
            </Button>
          </div>
        </KuruDrawer>
      )}
    </>
  );
};
