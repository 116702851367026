// TODO: consider making fields conditionally controlled
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React, { ChangeEvent, ChangeEventHandler, useContext, useEffect, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import get from 'lodash.get';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import { KuruFormContext } from '.';

// TODO: derive defaultValues from children inside KuruForm? Can you, if
// children is a function?
// TODO: check that there are enough defaultValues for all children. maybe with
// an intermediary component
type KuruFormProps = {
  children: ({ control, setValue, trigger }: KuruFormContext) => React.ReactNode;
  onSubmit: SubmitHandler<Record<string, any>>;
  defaultValues: Record<string, any>;
  className?: string;
};
export const KuruForm = (props: KuruFormProps) => {
  const { children, onSubmit, defaultValues = {}, className = '' } = props;

  const { handleSubmit, control, setValue, trigger } = useForm({
    defaultValues,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <KuruFormContext.Provider value={{ control, setValue, trigger }}>{children({ control, setValue, trigger })}</KuruFormContext.Provider>
      <Button type="submit">Confirmar</Button>
    </form>
  );
};

type KuruInputProps<T, V> = {
  changeHandler?: ChangeEventHandler<T>;
  name: string;
  className?: string;
  readOnly?: boolean;
  rules?: Record<string, any>;
  label?: string;
  disabled?: boolean;
  defaultValue?: V;
};

// TODO: make labels add '*' if rule required is applied

// changed boolean default value type to string to appease Swtich component props typing, but looks
// improper and risky
export const KuruCheckbox = (props: KuruInputProps<HTMLInputElement, string>) => {
  const { control } = useContext(KuruFormContext);
  const { name, changeHandler, className, readOnly, rules = {}, label, ...restProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value, ...fieldProps } }) => {
        const mixedChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
          if (readOnly) return;
          if (changeHandler) changeHandler(event);
          onChange(event);
        };
        return (
          <FormControlLabel
            control={<Checkbox {...restProps} {...fieldProps} onChange={mixedChangeHandler} checked={value} />}
            label={label}
            className={readOnly ? 'read-only' : ''}
          />
        );
      }}
    />
  );
};

const formatFloat = (value: string) => {
  // Keep only digits and commas
  // negative number support?
  value = value.replace(/[^0-9,]/g, '');

  const parts = value.split(',');

  if (parts.length > 2) {
    return; // Does nothing if there's more than one comma?
  }

  // Format the integer part with thousands separator (.)
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const decimalPart = parts[1] !== undefined ? ',' + parts[1] : '';

  return integerPart + decimalPart;
};
const floatTextToNumber = (floatAsText: string) => {
  if (floatAsText.length === 0) return undefined;
  const normalizedAmount = floatAsText.replace(/\./g, '').replace(',', '.');
  return Number(normalizedAmount).toFixed(2);
};
// TODO: support number validations (max, min, etc)
export const KuruFormattedNumberInput = (props: KuruInputProps<HTMLInputElement, number> & { defaultValue?: string | number | boolean }) => {
  const { control, setValue, trigger } = useContext(KuruFormContext);
  const { name, changeHandler, readOnly, rules = {}, defaultValue = '', ...restProps } = props;
  // TODO: take defaultValue from form, remove or integrate this other mechanism
  const [formattedValue, setFormattedValue] = useState(defaultValue);

  useEffect(() => {
    setValue(name, defaultValue ?? undefined);
    setFormattedValue(defaultValue ?? '');
    if (control.getFieldState(name).error)
      trigger(name).catch((e) => {
        throw e;
      });
  }, [defaultValue, control, name, setValue, trigger]);

  const visibleFieldChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { value } = target;
    if (readOnly) return;
    if (changeHandler) changeHandler(event);
    setValue(name, floatTextToNumber(value));
    setFormattedValue(formatFloat(value) || '');
    if (control.getFieldState(name).error)
      trigger(name).catch((e) => {
        throw e;
      });
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => {
          return (
            <>
              <TextField {...restProps} onChange={visibleFieldChangeHandler} value={formattedValue} error={!!error} helperText={error?.message ?? ' '} />
              <input type="hidden" {...restProps} {...fieldProps} value={value} onChange={onChange} />
            </>
          );
        }}
      />
    </>
  );
};

export const KuruTextInput = (props: KuruInputProps<HTMLInputElement, string> & { helperText?: string }) => {
  const { control } = useContext(KuruFormContext);
  const { name, changeHandler, readOnly, rules = {}, ...restProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => {
        const customProps = {} as TextFieldProps;
        const mixedChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
          if (readOnly) return;
          if (changeHandler) changeHandler(event);
          onChange(event);
        };
        if (error?.message) customProps.helperText = error?.message;
        return <TextField {...restProps} {...fieldProps} {...customProps} onChange={mixedChangeHandler} value={value} error={!!error} />;
      }}
    />
  );
};

export const KuruDateInput = (props: KuruInputProps<HTMLInputElement, string>) => {
  const { control } = useContext(KuruFormContext);
  const { name, changeHandler, readOnly, rules = {}, ...restProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => {
        const customProps = { type: 'date' } as TextFieldProps;
        const mixedChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
          if (readOnly) return;
          if (changeHandler) changeHandler(event);
          onChange(event);
        };
        if (error?.message) customProps.helperText = error?.message;
        return (
          <TextField
            {...restProps}
            {...fieldProps}
            {...customProps}
            onChange={mixedChangeHandler}
            value={value}
            error={!!error}
            InputLabelProps={{ shrink: true }}
          />
        );
      }}
    />
  );
};

export const KuruTimeInput = (props: KuruInputProps<HTMLInputElement, string>) => {
  const { control } = useContext(KuruFormContext);
  const { name, changeHandler, readOnly, rules = {}, ...restProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => {
        const customProps = { type: 'time' } as TextFieldProps;
        const mixedChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
          if (readOnly) return;
          if (changeHandler) changeHandler(event);
          onChange(event);
        };
        if (error?.message) customProps.helperText = error?.message;
        return (
          <TextField
            {...restProps}
            {...fieldProps}
            {...customProps}
            onChange={mixedChangeHandler}
            value={value}
            error={!!error}
            InputLabelProps={{ shrink: true }}
          />
        );
      }}
    />
  );
};

// TODO missing red color styling for error messages on this component
export const KuruSelectInput = (
  props: KuruInputProps<HTMLSelectElement, string> & {
    choices: Record<string, any>[];
    optionText?: string;
  },
) => {
  const { control } = useContext(KuruFormContext);
  const { name, changeHandler, readOnly, rules = {}, choices, optionText = 'name', label, defaultValue, ...restProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => {
        const mixedChangeHandler = (event: SelectChangeEvent<HTMLSelectElement>, child: React.ReactNode) => {
          if (readOnly) return;
          // shady casting between the SelectChangeEvent<HTMLSelectElement> from the MUI component to
          // React's ChangeEvent<HTMLSelectElement>
          if (changeHandler) changeHandler(event as unknown as ChangeEvent<HTMLSelectElement>);
          onChange(event, child);
        };

        // NOTE: FormControl styling added because I didn't get to figure out yet
        // how to provide it from the form context
        return (
          <FormControl sx={{ display: 'flex' }} margin="dense">
            <InputLabel id={name + 'Select'}>{label}</InputLabel>
            <Select {...restProps} {...fieldProps} label={label} onChange={mixedChangeHandler} value={value} error={!!error} labelId={name + 'Select'}>
              {...choices.map((choice) => {
                return <MenuItem value={choice.id}>{get(choice, optionText)}</MenuItem>;
              })}
            </Select>
            <FormHelperText>{error?.message ?? ''}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

// changed boolean default value type to string to appease Swtich component props typing, but looks
// improper and risky
export const KuruSwitchInput = (props: KuruInputProps<HTMLInputElement, string>) => {
  const { control } = useContext(KuruFormContext);
  const { name, changeHandler, readOnly, rules = {}, label, ...restProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value, ...fieldProps } }) => {
        const mixedChangeHandler = (event: ChangeEvent<HTMLInputElement>, child: React.ReactNode) => {
          if (readOnly) return;
          if (changeHandler) changeHandler(event);
          onChange(event, child);
        };

        return (
          <FormControl component="fieldset">
            <FormControlLabel control={<Switch {...fieldProps} {...restProps} onChange={mixedChangeHandler} />} label={label} />
          </FormControl>
        );
      }}
    />
  );
};
