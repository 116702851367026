import {
  Datagrid,
  TextField,
  ReferenceField,
  NumberField,
  SelectInput,
  ReferenceArrayInput,
  Show,
  Create,
  SimpleForm,
  useRecordContext,
  Labeled,
  useNotify,
  FunctionField,
  useTranslate,
} from 'react-admin';

import { Stack, Box } from '@mui/system';

import { displayString as cardHolderDisplayString } from '../cardHolders';
import { KuruEditToolbar } from '../../components/toolbars';
import { getErrorHandler } from '../../utils';
import { CommonProps } from '../../types';
import { kuruTeal } from '../../theme/kuru-theme';
import { GiftCard } from './types';
import { TextInput, NumberInput } from 'react-admin';
import { displayString } from '.';

export { GiftCardList } from './GiftCardList';

/*
  For styling:
  Dtagrid root has the gray border (RaDatagrid-root kuru-cards-table css-1gryonl-RaDatagrid-root)
  round border come from datagrid cells
 */
export const GiftCardSimpleList = () => {
  return (
    <Datagrid
      bulkActionButtons={false}
      rowClick="show"
      className="kuru-cards-table"
      sx={{
        '.RaDatagrid-headerCell': {
          backgroundColor: kuruTeal.light,
        },
      }}
    >
      <TextField source="giftCardId" label="ID" />
      <NumberField source="originalBalance" label="Saldo Original" locales="es-AR" options={{ style: 'currency', currency: 'ARS', minimumFractionDigits: 2 }} />
      <TextField source="product" label="Tipo Producto" />
    </Datagrid>
  );
};

const GiftCardTitle = () => {
  const record: GiftCard | undefined = useRecordContext();
  if (!record) return;
  return <span>GiftCard {record ? displayString(record) : ''}</span>;
};

const GiftCardForm = ({ children = undefined }: CommonProps) => {
  return <SimpleForm toolbar={<KuruEditToolbar showDelete={false} />}>{children}</SimpleForm>;
};

export const GiftCardCreate = () => {
  const notify = useNotify();

  return (
    <Create redirect="show" mutationOptions={{ onError: getErrorHandler(notify) }}>
      <GiftCardForm>
        <ReferenceArrayInput source="cardHolderId" reference="cardHolders">
          <SelectInput optionText={(holder) => cardHolderDisplayString(holder)} label="Colaborador/a" />
        </ReferenceArrayInput>
        <NumberInput source="amount" label="Monto" />
        <TextInput source="producto" label="Producto" />
      </GiftCardForm>
    </Create>
  );
};

export const GiftCardShow = () => {
  const notify = useNotify();
  const translate = useTranslate();

  return (
    <Show title={<GiftCardTitle />} queryOptions={{ onError: getErrorHandler(notify) }}>
      <Stack className="kuru-fields-group" spacing={3} divider={<Box className="kuru-fields-group-divider" component="hr" />}>
        <Stack spacing={6} direction={'row'}>
          <Labeled>
            <ReferenceField source="cardHolderId" reference="cardHolders" link="show" label="Colaborador/a" />
          </Labeled>
          <Labeled>
            <TextField source="lastFour" label="Últimos dígitos" />
          </Labeled>
          <Labeled>
            <FunctionField label="Tipo" render={({ type }) => translate('resources.giftcards.type.' + type)} />
          </Labeled>
          <Labeled>
            <NumberField source="balance" label="Saldo" locales="es-AR" options={{ style: 'currency', currency: 'ARS', minimumFractionDigits: 2 }} />
          </Labeled>
        </Stack>
      </Stack>
    </Show>
  );
};
