export interface HttpOptions {
  user?: {
    authenticated: boolean;
    token: string;
  };
  method?: string;
  body?: string;
}

export enum ResourceType {
  ADMINISTRATOR = 'administrators',
  CARD_HOLDERS = 'cardHolders',
  CARD = 'cards',
  GIFTCARD = 'giftcards',
  RULES = 'rules',
  TRANSACTIONS = 'transactions',
  COMPANY_TRANSACTIONS = 'companyTransactions',
  CATEGORIES = 'categories',
  COMPANIES = 'companies',
  NOTIFICATIONS = 'notifications',
  DASHBOARD = 'dashboard',
  SCHEDULED_JOBS = 'scheduledJobs',
}

export type ResourceHandlerContext = {
  request: (url: string, options: HttpOptions) => Promise<{ json: any }>;
};
