import { AutocompleteInput, ReferenceInput, required } from 'react-admin';
import { Link as RaLink } from 'react-admin';
import Typography from '@mui/material/Typography';

const AutoCompleteReference = () => {
  return (
    <>
      <ReferenceInput source="ruleId" reference="rules">
        <AutocompleteInput
          label="Regla"
          optionValue="id"
          optionText="name"
          noOptionsText="No existen reglas" // Texto a mostrar cuando no hay opciones
          validate={required('La regla es requerida')} // Validación del campo
          sx={{
            '& .MuiInputBase-root': {
              padding: 0,
            },
            '& .MuiAutocomplete-input': {
              padding: 0,
            },
            '& .MuiFormLabel-root': {
              top: '-8px', // Ajusta la posición del label
            },
            '& .MuiInputLabel-outlined': {
              '&.MuiInputLabel-shrink': {
                top: '0px',
              },
            },
          }}
        />
      </ReferenceInput>
      <Typography variant="body2" sx={{ color: 'grey.600', paddingLeft: 1 }}>
        Si ninguna de tus reglas vigentes te sirve, podés <RaLink to="/rules">ir a crear una nueva regla</RaLink> para asignar al crear la tarjeta.
      </Typography>
      <Typography variant="body2" sx={{ color: 'grey.600', paddingLeft: 1, paddingTop: 2 }}>
        Tené en cuenta que la creación en la plataforma es para tarjetas virtuales. Si querés solicitar tarjetas físicas, contactate con tu administrador.
      </Typography>
    </>
  );
};

export default AutoCompleteReference;
