// Copied from ra-language-spanish that lacks ts support

const spanishMessages = {
  ra: {
    action: {
      add_filter: 'Agregar filtro',
      add: 'Agregar',
      back: 'Regresar',
      bulk_actions: '%{smart_count} seleccionado',
      cancel: 'Cancelar',
      clear_input_value: 'Limpiar valor',
      create_item: 'Crear "%{item}"',
      create: 'Crear',
      delete: 'Eliminar',
      edit: 'Editar',
      list: 'Listar',
      refresh: 'Refrescar',
      remove_filter: 'Remover este filtro',
      remove: 'Remover',
      save: 'Guardar',
      show: 'Mostrar',
      sort: 'Ordenar',
      undo: 'Deshacer',
      expand: 'Expandir',
      close: 'Cerrar',
    },
    auth: {
      logout: 'Cerrar sesión',
      password: 'Contraseña',
      sign_in_error: 'Error de autenticación, por favor reintenta',
      sign_in: 'Ingresar',
      username: 'Usuario',
    },
    boolean: {
      false: 'No',
      true: 'Sí',
    },
    configurable: {
      customize: 'Personalizar',
    },
    input: {
      file: {
        upload_several: 'Arrastra algunos archivos para empezar la subida, o presiona aquí para seleccionarlos.',
        upload_single: 'Arrastra un archivo para empezar la subida, or presiona aquí para seleccionarlo.',
      },
      image: {
        upload_several: 'Arrastra algunas images para empezar la subida, or presiona aquí para seleccionar una.',
        upload_single: 'Arrastra una imagen para empezar la subida, or presiona aquí para seleccionarla.',
      },
      references: {
        all_missing: 'No se encontró ninguna referencia.',
        many_missing: 'Al menos una de las referencias asociadas parece ya no estar disponible.',
        single_missing: 'La referencia asociada parece ya no estar disponible.',
      },
    },
    message: {
      about: 'Acerca de',
      are_you_sure: '¿Estás seguro?',
      bulk_delete_content: '¿Estás seguro de que quieres borrar este %{name}? |||| ¿Estás seguro de que quieres borrar estos %{smart_count} elementos?',
      bulk_delete_title: 'Borrar %{name} |||| Borrar %{smart_count} %{name} elementos',
      delete_content: '¿Estás seguro de que quieres borrar este elemento?',
      delete_title: 'Borrar #%{id} de %{name}',
      invalid_form: 'El formulario no es válido. Por favor corrige los errores.',
      loading: 'La página esta cargando, espera un momento por favor',
      no: 'No',
      not_found: 'Seguiste un enlace erróneo o ingresaste una dirección equivocada',
      yes: 'Sí',
    },
    navigation: {
      next: 'Siguiente',
      no_more_results: 'La página número %{page} está fuera de los límites. Prueba con la página anterior.',
      no_results: 'No se encontraron resultados',
      page_out_from_begin: 'No se puede navegar antes de la página 1',
      page_out_from_end: 'No se puede navegar después de la última página',
      page_out_of_boundaries: 'La página número %{page} se encuentra fuera de los límites',
      page_range_info: '%{offsetBegin}-%{offsetEnd} de %{total}',
      prev: 'Previo',
    },
    notification: {
      bad_item: 'Elemento incorrecto',
      canceled: 'Acción cancelada',
      created: 'Elemento creado',
      deleted: 'Elemento borrado |||| %{smart_count} elementos borrados',
      http_error: 'Error de comunicación con el servidor',
      item_doesnt_exist: 'El elemento no existe',
      updated: 'Elemento actualizado |||| %{smart_count} elementos actualizados',
    },
    page: {
      create: 'Crear %{name}',
      dashboard: 'Dashboard',
      edit: '%{name} #%{id}',
      list: 'Lista de %{name}',
      loading: 'Cargando',
      not_found: 'No encontrado',
      show: '%{name} #%{id}',
    },
    sort: {
      ASC: 'ascendente',
      DESC: 'descendente',
      sort_by: 'Ordenar %{field} %{order}',
    },
    validation: {
      email: 'Debe ser un correo electrónico válido',
      maxLength: 'Deber tener %{max} caracteres o menos',
      maxValue: 'Debe ser %{max} o menos',
      minLength: 'Debe tener al menos %{min} caracteres',
      minValue: 'Debe ser al menos %{min}',
      number: 'Debe ser un número',
      oneOf: 'Debe ser uno de los siguientes valores: %{options}',
      regex: 'Debe seguir un formato específico (regexp): %{pattern}',
      required: 'Requerido',
    },
  },
};

export default spanishMessages;
