import { SelectInput, SimpleForm, TextInput, email, regex, required } from 'react-admin';
import { KuruCreateToolbar, KuruEditToolbar } from '../../components/toolbars';
import { Company, RequestCompany } from './types';
import { Stack, Box } from '@mui/system';
import { regexesConfig } from '../../utils';

export const formatCompanyRequest = (company: Company): RequestCompany => {
  return {
    legal_name: company.name.trim(),
    trade_name: company.trade_name.trim(),
    type: company.type,
    operation_country: 'ARG',
    tax_identification_type: 'CUIT',
    tax_identification_value: company.tax_identification_value.trim(),
    phone: company.phone,
    email: company.email,
    legal_address: company.legal_address,
    tax_condition: company.tax_condition,
  } as RequestCompany;
};

export const CompanyForm = ({ mode }: { mode: string }) => {
  let toolbar, editionMode;
  if (mode === 'create') {
    toolbar = <KuruCreateToolbar />;
    editionMode = false;
  } else if (mode === 'edit') {
    toolbar = <KuruEditToolbar showDelete={false} />;
    editionMode = true;
  }

  const nameRegex = new RegExp(regexesConfig.names.p);
  const nameRegexMsg = regexesConfig.names.msg;
  const phoneRegex = new RegExp(regexesConfig.phonePomelo.p);
  const phoneRegexMsg = regexesConfig.phonePomelo.msg;
  const taxIdRegex = new RegExp(regexesConfig.taxId.p);
  const taxIdRegexMsg = regexesConfig.taxId.msg;
  const emailNoTildePattern = regexesConfig.invalidAccents.p;
  const emailNoTildeMsg = regexesConfig.invalidAccents.msg;

  return (
    <SimpleForm toolbar={toolbar}>
      <Stack className="kuru-fields-group" spacing={3} divider={<Box className="kuru-fields-group-divider" component="hr" />}>
        <Stack spacing={3} direction={'row'}>
          <TextInput source="name" label="Razon Social" validate={[required(), regex(nameRegex, nameRegexMsg)]} readOnly={editionMode} />
          {!editionMode && (
            <TextInput source="trade_name" label="Nombre Comercial" validate={[required(), regex(nameRegex, nameRegexMsg)]} readOnly={editionMode} />
          )}
          <SelectInput
            source="type"
            label="Tipo"
            defaultValue={'SA'}
            choices={[
              { id: 'SA', name: 'SA - Sociedad Anónima' },
              { id: 'SRL', name: 'SRL - Empresa con responsabilidad limitada' },
              { id: 'SAS', name: 'SAS - Sociedad por Acciones Simplificada' },
              { id: 'SELF_COMPANY', name: 'Empresa unipersonal' },
              { id: 'RI', name: 'Responsable Inscripto' },
              { id: 'SELF_EMPLOYED', name: 'Monotribustista' },
              { id: 'SH', name: 'SH - Sociedad en Comandita Simple' },
              { id: 'CAP_I_SECC_IV', name: 'CAP_I_SECC_IV - Sociedad de Capital e Industria Sección IV' },
              { id: 'SIMPLE_SOC', name: 'Sociedad Simple' },
              { id: 'SAU', name: 'SAU - Sociedad Anónima Unipersonal' },
              { id: 'SGR', name: 'SGR - Sociedad de Garantía Recíproca' },
              { id: 'ESCROW', name: 'Fideicomiso' },
              { id: 'CONSORTIUM', name: 'Consorcio' },
              { id: 'COOPERATIVE', name: 'Cooperativa' },
              { id: 'CIVIL_SOCIETY', name: 'Sociedad Civil' },
              { id: 'CIVIL_ASSOCIATION', name: 'Asociación Civil' },
              { id: 'SACIFIA', name: 'SACIFIA - Sociedad Anónima con Participación Estatal Mayoritaria' },
            ]}
            validate={required()}
          />
          {!editionMode && (
            <TextInput source="tax_identification_value" label="CUIT" validate={[required(), regex(taxIdRegex, taxIdRegexMsg)]} readOnly={editionMode} />
          )}
          <SelectInput
            source="tax_condition"
            label="Condicion ante IVA"
            defaultValue={'VAT_REGISTERED'}
            choices={[
              { id: 'VAT_REGISTERED', name: 'Inscripto' },
              { id: 'OTHERS', name: 'Otra' },
            ]}
            validate={required()}
          />
        </Stack>
        {!editionMode && (
          <>
            <Stack spacing={3} direction={'row'}>
              <TextInput source="email" validate={[required(), email(), regex(emailNoTildePattern, emailNoTildeMsg)]} />
              <TextInput source="phone" label="Teléfono" validate={[required(), regex(phoneRegex, phoneRegexMsg)]} />
            </Stack>
            <Stack spacing={3} direction={'row'}>
              <TextInput source="legal_address.street_name" validate={[required(), regex(nameRegex, nameRegexMsg)]} label="Calle" />
              <TextInput source="legal_address.street_number" validate={[required(), regex(nameRegex, nameRegexMsg)]} label="Número" />
              <TextInput source="legal_address.floor" label="Piso" />
              <TextInput source="legal_address.apartment" label="Departamento" />
              <TextInput source="legal_address.additional_info" label="Información adicional" />
            </Stack>
            <Stack spacing={3} direction={'row'}>
              <TextInput source="legal_address.zip_code" validate={[required(), regex(nameRegex, nameRegexMsg)]} label="Código postal" />
              <TextInput source="legal_address.neighborhood" validate={[required(), regex(nameRegex, nameRegexMsg)]} label="Barrio/Localidad" />
              <TextInput source="legal_address.city" validate={[required(), regex(nameRegex, nameRegexMsg)]} label="Ciudad" />
              <TextInput source="legal_address.region" validate={[required(), regex(nameRegex, nameRegexMsg)]} label="Región" />
              <TextInput source="legal_address.country" validate={[required(), regex(nameRegex, nameRegexMsg)]} label="País" />
            </Stack>
          </>
        )}
      </Stack>
    </SimpleForm>
  );
};
