import Stack from '@mui/material/Stack';
import {
  KuruCheckbox,
  KuruTextInput,
  KuruDateInput,
  KuruTimeInput,
  KuruSwitchInput,
  required as setRequired,
  useFieldWatch,
  KuruFormContext,
} from './KuruForm';

export type SchedulingParams = {
  enableScheduling: boolean;
  date: string;
  time: string;
  repeatMonthly?: boolean;
  lastDoM?: boolean;
  schedulingEntryName: string;
};
export const SchedulingControls = ({ form }: { form: KuruFormContext }) => {
  const { control, setValue } = form;
  const lastDoMValue = useFieldWatch(control, 'lastDoM');
  const enableScheduling = useFieldWatch(control, 'enableScheduling');

  return (
    <>
      <Stack>
        <KuruSwitchInput name="enableScheduling" label="Programar operación" />
      </Stack>
      {enableScheduling && (
        <>
          <KuruTextInput
            name="schedulingEntryName"
            rules={setRequired()}
            label="Nombre *"
            helperText="Con este nombre aparecerá en el listado de acciones programadas."
          />
          <Stack direction={'row'}>
            <KuruTimeInput name="time" label="Hora *" rules={setRequired()} />
            <KuruDateInput name="date" disabled={lastDoMValue} label="Fecha" />
          </Stack>
          <Stack>
            <KuruCheckbox name="repeatMonthly" label="Repetir todos los meses en el día elegido" readOnly={Boolean(lastDoMValue)} />
            <KuruCheckbox
              name="lastDoM"
              label="Repetir el último día de cada mes"
              changeHandler={(event) => {
                if (event.target.checked) {
                  setValue('repeatMonthly', true);
                  setValue('date', '');
                }
              }}
            />
          </Stack>
        </>
      )}
    </>
  );
};

SchedulingControls.defaultValues = {
  enableScheduling: false,
  schedulingEntryName: '',
  time: '',
  date: '',
  repeatMonthly: false,
  lastDoM: false,
};
