import { GetListParams, GetListResult } from 'react-admin';
import { getQueryString } from '../../dataProvider/utils';
import { ResourceHandlerContext } from '../../dataProvider/types';
import { ScheduledJob } from './types';

export async function getList(context: ResourceHandlerContext, companyId: string, params: GetListParams): Promise<GetListResult<ScheduledJob>> {
  const { json } = await context.request(`companies/${companyId}/scheduled-jobs` + getQueryString(params?.pagination), {});
  const response: { total: number; scheduledJobs: ScheduledJob[] } = json;
  return {
    total: response.total,
    data: response.scheduledJobs,
  };
}
