import polyglotI18nProvider from 'ra-i18n-polyglot';
import en from 'ra-language-english';
import es from './kuruSpanishMessages';

const translations = { es, en };

export const i18nProvider = polyglotI18nProvider(
  (locale) => translations[locale],
  'es', // default locale
  [
    { locale: 'es', name: 'Español' },
    // { locale: 'en', name: 'English' },
  ],
);
