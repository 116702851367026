import {
  Datagrid,
  List,
  Show,
  TextField,
  Create,
  Button,
  SimpleForm,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
  NumberField,
  TopToolbar,
} from 'react-admin';
import { Labeled } from 'react-admin';
import { useState } from 'react';
import { FieldValues, SubmitHandler } from 'react-hook-form';

import { Stack, Box } from '@mui/system';
import Drawer from '@mui/material/Drawer';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { Company } from './types';
import { CompanyForm } from './CompanyForm';
import { KuruEditToolbar } from '../../components/toolbars';
import { getErrorHandler } from '../../utils';
import { Impersonation } from '../../components/Impersonation';
import FormattedFloatInput from '../../components/FormattedFloatInput';

export const displayString = (record: Pick<Company, 'name'>) => `${record?.name}`;

export const CompanyList = () => {
  const notify = useNotify();

  return (
    <>
      <Impersonation />
      <List queryOptions={{ onError: getErrorHandler(notify) }} exporter={false}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
          <TextField source="name" label="Razon Social" sortable={false} />
          <TextField source="tax_identification_value" label="CUIT" sortable={false} />
          <TextField source="id" label="Id" sortable={false} />
          <TextField source="pomeloId" label="Id Pomelo" sortable={false} />
          <NumberField
            source="balance"
            label="Saldo"
            locales="es-AR"
            options={{ style: 'currency', currency: 'ARS', minimumFractionDigits: 2 }}
            sortable={false}
          />
        </Datagrid>
      </List>
    </>
  );
};

const FundCompanyButton = () => {
  const record = useRecordContext();
  const [showFunding, setShowFunding] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  if (!record) return null;

  const toggleShowFunding = (value: boolean) => {
    setShowFunding(value);
  };

  const increaseCompanyBalance = async ({ balanceIncrease }: { balanceIncrease: string }) => {
    const normalizedAmount = balanceIncrease.replace(/\./g, '').replace(',', '.');
    const balanceValue = Number(normalizedAmount).toFixed(2);
    dataProvider
      .increaseBalance('companies', { id: record.id, balanceIncrease: balanceValue })
      .then(() => {
        notify('resources.companies.balance.increased');
        toggleShowFunding(false);
        refresh();
      })
      .catch((error: unknown) => getErrorHandler(notify)(error, { resource: 'companies' }));
  };

  return (
    <>
      <Button label="Incrementar balance" onClick={() => toggleShowFunding(true)} startIcon={<AttachMoneyIcon />} />
      <Drawer className="actionDrawer" open={showFunding} anchor="right" onClose={() => toggleShowFunding(false)}>
        <h3>Incrementar balance de compañía</h3>
        {/* TODO: look into removing type cast, look for other instances */}
        <SimpleForm onSubmit={increaseCompanyBalance as SubmitHandler<FieldValues>} toolbar={<KuruEditToolbar showDelete={false} />}>
          <FormattedFloatInput source="balanceIncrease" label="Monto" isRequired />
        </SimpleForm>
      </Drawer>
    </>
  );
};

const CompanyShowActions = () => (
  <TopToolbar>
    <FundCompanyButton />
  </TopToolbar>
);

const CompanyTitle = () => {
  const record: Company | undefined = useRecordContext();
  return <span>Empresa {record ? record.name : ''}</span>;
};

export const CompanyShow = () => {
  const notify = useNotify();

  return (
    <Show actions={<CompanyShowActions />} title={<CompanyTitle />} queryOptions={{ onError: getErrorHandler(notify) }}>
      <Stack className="kuru-fields-group" spacing={3} divider={<Box className="kuru-fields-group-divider" component="hr" />}>
        <Stack spacing={3} direction={'row'}>
          <Labeled>
            <TextField source="name" label="Razon Social" />
          </Labeled>
          <Labeled>
            <NumberField source="balance" label="Saldo" locales="es-AR" options={{ style: 'currency', currency: 'ARS', minimumFractionDigits: 2 }} />
          </Labeled>
        </Stack>
        <Stack spacing={3} direction={'row'}>
          <Labeled>
            <TextField source="id" label="Id" />
          </Labeled>
          <Labeled>
            <TextField source="pomeloId" label="Id Pomelo" />
          </Labeled>
        </Stack>
      </Stack>
    </Show>
  );
};

export const CompanyCreate = () => {
  const notify = useNotify();

  return (
    <Create title="Crear Empresa" redirect="show" mutationOptions={{ onError: getErrorHandler(notify) }}>
      <CompanyForm mode="create" />
    </Create>
  );
};
