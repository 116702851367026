import { GetListParams, GetListResult } from 'react-admin';
import { ResourceHandlerContext } from '../../dataProvider/types';
import { getQueryString } from '../../dataProvider/utils';
import { Transaction, cardTransactionTypes, TransactionRequestFilters, TransactionRequestFiltersForApi } from '.';

export async function getList(context: ResourceHandlerContext, companyId: string, params: GetListParams): Promise<GetListResult<Transaction>> {
  const filterQueryParams = [];
  const { cardHolderTags, toDate, fromDate, status, type } = params?.filter || {};
  const cardHolderTagsArray: string[] = cardHolderTags?.trim().split(' ');

  if (cardHolderTagsArray) {
    for (const tag of cardHolderTagsArray) {
      const trimmedTag = tag.trim();
      if (trimmedTag) {
        filterQueryParams.push('cardHolderTags=' + trimmedTag);
      }
    }
  }

  if (fromDate) {
    filterQueryParams.push('fromDate=' + Date.parse(fromDate));
  }

  if (toDate) {
    filterQueryParams.push('toDate=' + Date.parse(toDate));
  }

  if (status) {
    filterQueryParams.push('status=' + status);
  }

  const typeArray = type ? [type] : cardTransactionTypes;
  for (const t of typeArray) {
    filterQueryParams.push('type=' + t);
  }

  const cardHolderNameStartsWith = params?.filter?.cardHolderNameStartsWith?.trim();
  if (cardHolderNameStartsWith) {
    filterQueryParams.push('cardHolderNameStartsWith=' + cardHolderNameStartsWith);
  }

  const cardHolderSurnameStartsWith = params?.filter?.cardHolderSurnameStartsWith?.trim();
  if (cardHolderSurnameStartsWith) {
    filterQueryParams.push('cardHolderSurnameStartsWith=' + cardHolderSurnameStartsWith);
  }

  const ruleId = params?.filter?.ruleId?.trim();
  if (ruleId) {
    filterQueryParams.push('ruleId=' + ruleId);
  }

  const { json } = await context.request(`companies/${companyId}/transactions` + getQueryString(params?.pagination, filterQueryParams), {});
  const response: { total: number; transactions: Transaction[] } = json;
  return { total: response.total, data: json.transactions };
}

export async function requestExport(context: ResourceHandlerContext, companyId: string, params: TransactionRequestFilters): Promise<Record<string, any>> {
  const requestBody = ({
    type,
    status,
    cardHolderTags,
    fromDate,
    toDate,
    cardHolderNameStartsWith,
    cardHolderSurnameStartsWith,
    ruleId,
  }: TransactionRequestFilters) => {
    const cardHolderTagsArray: string[] | undefined = cardHolderTags?.trim().split(' ');
    const body: TransactionRequestFiltersForApi = {};

    if (status) {
      body.status = status;
    }

    if (type) {
      body.type = [type];
    }

    if (cardHolderTagsArray) {
      body.cardHolderTags = cardHolderTagsArray;
    }

    if (fromDate) {
      body.fromDate = Date.parse(fromDate);
    }

    if (toDate) {
      body.toDate = Date.parse(toDate);
    }

    if (cardHolderNameStartsWith) {
      body.cardHolderNameStartsWith = cardHolderNameStartsWith;
    }

    if (cardHolderSurnameStartsWith) {
      body.cardHolderSurnameStartsWith = cardHolderSurnameStartsWith;
    }

    if (ruleId) {
      body.ruleId = ruleId;
    }

    return body;
  };

  const { json } = await context.request(`companies/${companyId}/jobs/export-transactions`, {
    method: 'POST',
    body: JSON.stringify(requestBody(params)),
  });

  return { data: json };
}
