import { FormattedNumber, useIntl } from 'react-intl';

import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

import WrapWithRef from './WrapWithRef';
import { Currency } from '../resources/transactions';
import { Transaction, PomeloTxData, kuruTransactionTypes } from '../resources/transactions/types';
import { usePermissions } from 'react-admin';

type CurrencyDisplayProps = {
  amount: number | string;
  currency?: string;
};

// A styling component for currency amounts
export default function CurrencyDisplay({ amount, currency = Currency.ARS }: CurrencyDisplayProps) {
  const value = Number(amount);
  return <FormattedNumber value={value} style="currency" currency={currency} currencyDisplay="narrowSymbol" />;
}

// Currency-formatting hook for when a plain string is necessary
export const useFormatCurrency = (defaultCurrency?: string | undefined) => {
  const intl = useIntl();

  return (value: number, currency: string | undefined = defaultCurrency) =>
    intl.formatNumber(value, {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'narrowSymbol',
    });
};

export const displayTxAmount = (record: Transaction) => {
  const { type, data, amount } = record;

  if (kuruTransactionTypes.includes(type)) {
    const currency = Currency.ARS;
    return (
      <>
        {currency} <CurrencyDisplay amount={amount} currency={currency} />
      </>
    );
  }

  return DisplayPomeloTxAmount(data);
};

const DisplayPomeloTxAmount = (data: PomeloTxData) => {
  const { permissions } = usePermissions();
  if (!data?.amount) {
    return '(formato de transacción no soportado)';
  }

  const detailIsBase = (detail: Record<string, any>) => detail.type === 'BASE';
  const calculateExchangeRate = (localBase: number, usdTotal: number) => parseFloat(Number(localBase / usdTotal).toFixed(2));
  const { details, transaction, local, settlement } = data.amount;
  const isForeignCurrencyPurchase = transaction.currency !== Currency.ARS;
  const filteredDetails = details.filter((detail) => permissions.is_kuru_admin || detail.name !== 'MARKUP');

  if (isForeignCurrencyPurchase || details.length > 1) {
    const base = details.find(detailIsBase);
    if (!base) return '(formato de transacción no soportado)';
    const localBase = parseFloat(base.amount);
    // settlement is the amount in USD settled for the original transaction
    const usdTotal = parseFloat(settlement.total);
    // Can be NaN
    const exchangeRate = calculateExchangeRate(localBase, usdTotal);

    return (
      <Tooltip
        title={
          <div>
            {isForeignCurrencyPurchase && (
              <>
                <p>
                  Monto original: {transaction.currency} <CurrencyDisplay amount={transaction.total} currency={transaction.currency} />
                </p>
                <p>
                  Monto en {settlement.currency}: <CurrencyDisplay amount={usdTotal} currency={settlement.currency} />
                </p>
                <p>
                  Tipo de cambio: {Currency.ARS} {exchangeRate ? <CurrencyDisplay amount={exchangeRate} /> : 'N/A'}
                </p>
              </>
            )}
            <p>
              Monto base en {base.currency}: <CurrencyDisplay amount={localBase} currency={base.currency} />
            </p>
            {/* Check tx has other details besides base */}
            {filteredDetails.length > 1 && <p>Otros importes:</p>}
            {filteredDetails.map((detail) => {
              if (detailIsBase(detail)) return;
              const { amount, currency, name, type } = detail;

              return (
                <p>
                  {name} [{type}]: {currency} <CurrencyDisplay amount={parseFloat(amount)} currency={currency} />
                </p>
              );
            })}
            <p>
              TOTAL en {local.currency}: <CurrencyDisplay amount={local.total} currency={local.currency} />
            </p>
          </div>
        }
      >
        <WrapWithRef>
          {transaction.currency} <CurrencyDisplay amount={transaction.total} currency={transaction.currency} />
          <InfoIcon className="txAmountInfo" />
        </WrapWithRef>
      </Tooltip>
    );
  } else {
    return (
      <>
        {transaction.currency} <CurrencyDisplay amount={transaction.total} currency={transaction.currency} />
      </>
    );
  }
};
