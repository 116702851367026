import { AutocompleteArrayInput } from 'react-admin';

export const TagsCreationInput = ({ source = 'tags', choices = [], ...props }: { source?: string; choices?: { id: string }[] }) => {
  const newTagCreation = (tag = '') => {
    const newTag = { id: tag };
    choices.push(newTag);
    return newTag;
  };

  const validateTags = (tags: string[]) => {
    const tagRegexPattern = /^([a-z0-9áéíóúüñ]|_|-|:|\.)+$/;
    const tagRegex = new RegExp(tagRegexPattern);
    const tagRegexMsg = 'Pueden contener letras minúsculas, dígitos, _ - : .';

    const validates = tags.every((tag) => tagRegex.test(tag));
    return validates ? undefined : tagRegexMsg;
  };

  return (
    <AutocompleteArrayInput
      {...props}
      choices={choices}
      onCreate={newTagCreation}
      validate={validateTags}
      source={source}
      label="Etiquetas"
      optionText={(tag) => tag.id}
      helperText="Puede contener hasta 5 etiquetas, compuestas de letras minúsculas, dígitos, _ - : ."
    />
  );
};
