import { List, Datagrid, TextField, ReferenceField, NumberField, useNotify, TextInput, FunctionField, useTranslate } from 'react-admin';

import { KuruListActions } from '../../components/toolbars';
import { getErrorHandler } from '../../utils';
import { CardHolderTagsFilter } from '../../components/filters';
import ArrayChipsField from '../../components/ArrayChipsField';

const cardsFilters = [
  <CardHolderTagsFilter alwaysOn source="cardHolderTags" />,
  <TextInput label="Nombre de colaborador/a" source="cardHolderNameStartsWith" alwaysOn />,
  <TextInput label="Apellido de colaborador/a" source="cardHolderSurnameStartsWith" alwaysOn />,
];

export const GiftCardList = () => {
  const notify = useNotify();
  const translate = useTranslate();

  return (
    <>
      <List actions={<KuruListActions />} queryOptions={{ onError: getErrorHandler(notify) }} filters={cardsFilters}>
        <Datagrid rowClick="show">
          <ReferenceField source="cardHolderId" reference="cardHolders" link={false} label="Colaborador/a" sortable={false} />
          <ReferenceField source="cardHolderId" reference="cardHolders" link={false} label="Etiquetas de Colaborador/a" sortable={false}>
            <ArrayChipsField source="tags" />
          </ReferenceField>
          <TextField source="lastFour" label="Últimos dígitos" sortable={false} />
          <FunctionField label="Tipo" render={({ type }) => translate('resources.giftcards.type.' + type)} />
          <NumberField
            source="balance"
            sortable={false}
            label="Saldo"
            locales="es-AR"
            options={{ style: 'currency', currency: 'ARS', minimumFractionDigits: 2 }}
          />
        </Datagrid>
      </List>
    </>
  );
};
