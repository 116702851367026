import { Layout, LayoutProps } from 'react-admin';

import { KuruMenu } from '../components/menu';
import { KuruAppBar } from '../components/appBar';
import { JSX } from 'react/jsx-runtime';

export const KuruLayout = (props: JSX.IntrinsicAttributes & LayoutProps) => {
  return (
    <Layout
      {...props}
      menu={KuruMenu}
      appBar={KuruAppBar}
      sx={{
        '#main-content': {
          borderLeft: 'solid',
          borderLeftWidth: 1,
          borderLeftColor: '#3a3939',
        },
        '.MuiTablePagination-toolbar': {
          bottom: 0,
          right: 0,
          marginBottom: 3,
        },
        '.kuru-fields-group': {
          marginLeft: 1,
        },
        '.kuru-fields-group-divider': {
          borderWidth: 1,
          borderColor: '#3a393920',
          borderStyle: 'solid',
        },
        '.kuru-cards-table': {
          borderWidth: 1,
          borderColor: '#3a393920',
          borderStyle: 'solid',
          marginRight: 1,
          marginBottom: 1,
        },
        '.impersonation-form': {
          position: 'absolute',
          zIndex: '1000',
          '& > div': {
            maxHeight: '2rem',
            overflow: 'hidden',
            margin: '0',
            '& div': {
              padding: '0',
            },
            '& p': {
              display: 'none',
            },
            '& label': {
              marginTop: '-.7rem',
              '&.MuiInputLabel-shrink': {
                display: 'none',
              },
            },
          },
        },
      }}
    />
  );
};
