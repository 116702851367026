import { List, SimpleList, useDataProvider, useNotify } from 'react-admin';

import Link from '@mui/material/Link';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AddCardIcon from '@mui/icons-material/AddCard';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ScheduleIcon from '@mui/icons-material/Schedule';

import { dateTimeLocaleString, downloadFile, getErrorHandler } from '../../utils';
import { ScheduledDistributionData, scheduledDistributionNotification } from '../cards';

export type Notification = {
  id: string;
  companyId: string;
  type: NotificationType;
  createdAt: string;
  data?: Record<string, any>;
};

enum NotificationType {
  Static = 'STATIC',
  JobUpdate = 'JOB_UPDATE',
}

enum JobType {
  CardHoldersExport = 'CARD_HOLDERS_EXPORT',
  CardHoldersImport = 'CARD_HOLDERS_IMPORT',
  CardsExport = 'CARDS_EXPORT',
  CardsCreation = 'CARDS_CREATION',
  GiftCardsCreation = 'GIFT_CARDS_CREATION',
  TransactionsExport = 'TRANSACTIONS_EXPORT',
  CardsDistribution = 'DISTRIBUTION',
}

enum JobStatus {
  New = 'NEW',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
}

type JobUpdateNotification = Notification & {
  data: JobUpdateNotificationData;
};

type JobUpdateNotificationData = {
  data: Record<string, any> & {
    withFailures: boolean;
  };
  jobId: string;
  jobType: JobType;
  jobStatus: JobStatus;
};

const DownloadExportNotificationBody = ({ data }: { data: JobUpdateNotificationData }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const config: Record<string, any> = jobNotificationConfig[data.jobType];

  const onClickHandler = () => {
    dataProvider
      .getExportUrl(config.resource, data)
      .then((result: any) => {
        downloadFile(result.data.url, `#${config.filename}.csv`);
      })
      .catch((error: unknown) => getErrorHandler(notify)(error, { resource: 'fileDownload' }));
  };

  return (
    <>
      El archivo está listo para{' '}
      <Link onClick={onClickHandler} sx={{ cursor: 'pointer' }}>
        descargar
      </Link>
      .
    </>
  );
};

const DownloadImportErrorsNotificationBody = ({ data }: { data: JobUpdateNotificationData }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const config: Record<string, any> = jobNotificationConfig[data.jobType];

  const onClickHandler = () => {
    dataProvider
      .getImportErrorsUrl(config.resource, data)
      .then((result: any) => {
        downloadFile(result.data.url, `#${config.filename}.csv`);
      })
      .catch((error: unknown) => getErrorHandler(notify)(error, { resource: 'fileDownload' }));
  };

  return (
    <>
      Hubo errores al procesar el archivo, podés{' '}
      <Link onClick={onClickHandler} sx={{ cursor: 'pointer' }}>
        descargar el detalle
      </Link>{' '}
      para verlos.
    </>
  );
};

const jobNotificationConfig = {
  [JobType.CardHoldersExport]: {
    icon: <FileDownloadIcon />,
    resource: 'cardHolders',
    filename: 'colaboradores',
    title: 'Exportar listado de Colaboradores',
    body: {
      [JobStatus.New]: 'El archivo está siendo generado. Cuando esté listo recibirás una notificación desde la cual podrás descargarlo.',
      [JobStatus.Failed]: 'Hubo un error creando el archivo.',
      [JobStatus.Completed]: (notification: JobUpdateNotification) => <DownloadExportNotificationBody data={notification.data} />,
    },
  },
  [JobType.CardHoldersImport]: {
    icon: <FileUploadIcon />,
    resource: 'cardHolders',
    filename: 'colaboradores',
    title: 'Importar listado de Colaboradores',
    body: {
      [JobStatus.New]: 'El archivo está siendo procesado. Recibirás otra notificación cuando esté listo.',
      [JobStatus.Failed]: 'Hubo un error procesando el archivo.',
      [JobStatus.Completed]: (notif: JobUpdateNotification) => {
        if (notif.data.data.withFailures) {
          return <DownloadImportErrorsNotificationBody data={notif.data} />;
        } else {
          return 'La importación de colaboradores se completó exitosamente.';
        }
      },
    },
  },
  [JobType.CardsExport]: {
    icon: <FileDownloadIcon />,
    resource: 'cards',
    filename: 'tarjetas',
    title: 'Exportar listado de Tarjetas',
    body: {
      [JobStatus.New]: 'El archivo está siendo generado. Cuando esté listo recibirás una notificación desde la cual podrás descargarlo.',
      [JobStatus.Failed]: 'Hubo un error creando el archivo.',
      [JobStatus.Completed]: (notification: JobUpdateNotification) => <DownloadExportNotificationBody data={notification.data} />,
    },
  },
  [JobType.CardsCreation]: {
    icon: <AddCardIcon />,
    title: 'Creación de Tarjetas',
    body: {
      [JobStatus.New]: 'Las tarjetas están siendo creadas. Recibirás otra notificación cuando estén listas.',
      [JobStatus.Failed]: 'Hubo un error creando las tarjetas.', // TODO: more info, remediation
      [JobStatus.Completed]: (notification: JobUpdateNotification) => {
        const { withFailures } = notification.data.data;
        return 'Las tarjetas fueron creadas ' + (withFailures ? 'con algunos errores' : 'con éxito'); // TODO: errores parciales? remediation?
      },
    },
  },
  [JobType.GiftCardsCreation]: {
    icon: <AddCardIcon />,
    title: 'Creación de GiftCards',
    body: {
      [JobStatus.New]: 'Las GiftCards están siendo creadas. Recibirás otra notificación cuando estén listas.',
      [JobStatus.Failed]: 'Hubo un error creando las GiftCards', // TODO: more info, remediation
      [JobStatus.Completed]: (notification: JobUpdateNotification) => {
        const { withFailures } = notification.data.data;
        return 'Las GiftCards fueron creadas ' + (withFailures ? 'con algunos errores' : 'con éxito'); // TODO: errores parciales? remediation?
      },
    },
  },
  [JobType.TransactionsExport]: {
    icon: <FileDownloadIcon />,
    resource: 'transactions',
    filename: 'transacciones',
    title: 'Exportar listado de Transacciones',
    body: {
      [JobStatus.New]: 'El archivo está siendo generado. Cuando esté listo recibirás una notificación desde la cual podrás descargarlo.',
      [JobStatus.Failed]: 'Hubo un error creando el archivo.',
      [JobStatus.Completed]: (notification: JobUpdateNotification) => <DownloadExportNotificationBody data={notification.data} />,
    },
  },
  [JobType.CardsDistribution]: {
    icon: <ScheduleIcon />,
    title: 'Distribución programada',
    body: {
      [JobStatus.New]: (notification: JobUpdateNotification) => {
        const {
          data: { data: scheduledDistributionData },
        } = notification;
        return (
          <>
            <p>
              Comenzando a ejecutar la distribución programada:{' '}
              {scheduledDistributionNotification(scheduledDistributionData as unknown as ScheduledDistributionData)}
            </p>
          </>
        );
      },
      [JobStatus.Failed]: 'Hubo un error al ejecutar la acción programada.',
      [JobStatus.Completed]: (notification: JobUpdateNotification) => {
        const {
          data: { data: scheduledDistributionData },
        } = notification;
        return (
          <>
            <p>
              Se completó la distribución programada: {scheduledDistributionNotification(scheduledDistributionData as unknown as ScheduledDistributionData)}
            </p>
          </>
        );
      },
    },
  },
};

const jobNotificationTitle = (notification: JobUpdateNotification) => {
  return (
    <>
      {jobNotificationConfig[notification.data.jobType]?.title || 'Notificación'}
      <span style={{ color: '#aaa' }}> [{dateTimeLocaleString(notification.createdAt)}]</span>
    </>
  );
};

const notificationTitle = (notification: Notification) => {
  if (notification.type === NotificationType.JobUpdate) {
    return jobNotificationTitle(notification as JobUpdateNotification);
  }

  // TODO: handle static notifications
  return 'Notificación';
};

const jobNotificationBody = (notification: JobUpdateNotification) => {
  const { jobType, jobStatus }: Partial<JobUpdateNotificationData> = notification.data;

  const body = jobNotificationConfig[jobType]?.body[jobStatus] || 'Error recuperando la notificación.';

  return typeof body === 'function' ? body(notification) : body;
};

const notificationBody = (notification: Notification) => {
  if (notification.type === NotificationType.JobUpdate) {
    return jobNotificationBody(notification as JobUpdateNotification);
  }

  // TODO: handle static notifications
  return 'N/A';
};

const notificationIcon = (notification: Notification) => {
  if (notification.type == NotificationType.JobUpdate) {
    return jobNotificationConfig[(notification as JobUpdateNotification).data.jobType]?.icon || <NotificationsIcon />;
  } else {
    return <NotificationsIcon />;
  }
};

export const NotificationsList = () => {
  const notify = useNotify();

  return (
    <List actions={false} queryOptions={{ onError: getErrorHandler(notify) }}>
      <SimpleList
        primaryText={notificationTitle}
        secondaryText={notificationBody}
        leftIcon={notificationIcon}
        linkType={false}
        sx={{
          'li > div.MuiListItemText-root': {
            padding: '.5rem 1rem',
          },
          'span.MuiTypography-root': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      />
    </List>
  );
};
