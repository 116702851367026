import spanishMessages from './spanishMessages';
import merge from 'lodash/merge';

import { TransactionStatus, TransactionReceiptStatus, TransactionType } from '../resources/transactions';
import { Category } from '../resources/rules';
import { CardType } from '../resources/cards';
import { GiftCardType } from '../resources/giftcards';

// Original english messages showing variables use in ../../node_modules/ra-language-english/src/index.ts

const kuruSpanishMessages = {
  ra: {
    action: {
      create: 'Crear',
      export: 'Exportar',
      confirm: 'Confirmar',
      remove_all_filters: 'Quitar todos los filtros',
      unselect: 'Deseleccionar',
    },
    page: {
      empty: 'No hay %{name} aún.',
      error: 'Algo salió mal',
      invite: '¿Querés agregar?',
      list: 'Listado de %{name}',
    },
    navigation: {
      clear_filters: 'Quitar filtros',
      no_filtered_results: 'No hay resultados para los filtros aplicados.',
      page_rows_per_page: 'Filas por página',
    },
    message: {
      delete_title: '¿Borrar de %{name}?',
      error: 'Ocurrió un error en la aplicación y tu consulta no pudo completarse.',
      invalid_form: 'El formulario no es válido. Por favor corrija los errores.',
    },
    notification: {
      exportStarted: 'Exportando archivo, podés seguir el progreso en Notificaciones',
    },
  },
  filters: {
    tags: 'Etiquetas',
    nameStartsWith: 'Nombre de colaborador/a',
    surnameStartsWith: 'Apellido de colaborador/a',
  },
  resources: {
    companies: {
      name: 'Empresas',
      balance: {
        increased: 'Saldo de compañía actualizado',
      },
    },
    administrators: {
      name: 'Administradores',
    },
    cards: {
      name: 'Tarjetas',
      updateMany: {
        rule: 'Se actualizó la regla para las tarjetas seleccionadas.',
        balance: 'Se actualizó el saldo para las tarjetas seleccionadas.',
      },
      scheduleUpdateMany: {
        balance: 'Se programó la actualización de saldo para las tarjetas seleccionadas',
      },
      balance: {
        increased: 'Saldo de tarjeta actualizado',
      },
      type: {
        [CardType.PHYSICAL]: 'Física',
        [CardType.VIRTUAL]: 'Virtual',
      },
    },
    giftcards: {
      name: 'GiftCards',
      balance: {
        increased: 'Saldo de tarjeta actualizado',
      },
      type: {
        [GiftCardType.PHYSICAL]: 'GifCard Física',
        [GiftCardType.VIRTUAL]: 'GiftCard Virtual',
      },
    },
    cardHolders: {
      name: 'Colaboradores',
      createCards: 'Creando tarjetas. Podés seguir el progreso en Notificaciones.',
      createGiftCards: 'Creando GiftCards. Podés seguir el progreso en Notificaciones.',
      importUpload: 'Importando colaboradores. Podés seguir el progreso en Notificaciones.',
      invitationsSent: 'Estamos enviando las invitaciones',
      invitationSent: 'Estamos enviando la invitación',
    },
    rules: {
      name: 'Reglas',
      conditions: {
        days: 'Días habilitados',
        maxAmount: 'Monto máximo',
        time: 'Horario habilitado',
        transactionLimits: 'Límite de transacciones',
        currency: 'Tipo de moneda',
        receiptRequired: 'Comprobante requerido',
        expirationDate: 'Período de uso',
        categories: 'Rubros',
      },
      errors: {
        deleteRule: 'Error al eliminar una regla. Verificá que la regla no esté en uso.',
      },
      success: {
        deleteRule: 'Regla eliminada con éxito.',
      },
    },
    transactions: {
      name: 'Transacciones',
      updateStatus: 'Comprobante actualizado',
      status: {
        [TransactionStatus.APPROVED]: 'Aprobada',
        [TransactionStatus.REJECTED_INSUFFICIENT_FUNDS]: 'Rechazada - Fondos insuficientes',
        [TransactionStatus.REJECTED_INVALID_MERCHANT]: 'Rechazada - Comercio no permitido',
        [TransactionStatus.REJECTED_INVALID_AMOUNT]: 'Rechazada - Monto supera límite',
        [TransactionStatus.REJECTED_SYSTEM_ERROR]: 'Rechazada - Error del sistema',
        [TransactionStatus.REJECTED_BY_RULE]: 'Rechazada - Rechazada por regla',
        [TransactionStatus.REJECTED_CARD_IS_DISABLED]: 'Rechazada - Tarjeta deshabilitada',
      },
      receiptStatus: {
        [TransactionReceiptStatus.NO_RECEIPT_REQUIRED]: 'No requerido',
        [TransactionReceiptStatus.RECEIPT_REQUIRED]: 'Requerido',
        [TransactionReceiptStatus.RECEIPT_PROVIDED]: 'Adjunto',
      },
      type: {
        [TransactionType.PURCHASE]: 'Compra',
        [TransactionType.DISTRIBUTION]: 'Modificación de saldo',
        [TransactionType.CREDIT]: 'Ajuste de Crédito',
        [TransactionType.DEBIT]: 'Ajuste de Débito',
        [TransactionType.CASH_IN]: 'Cash in',
        [TransactionType.WITHDRAWAL]: 'Retiro de efectivo en cajero',
        [TransactionType.EXTRACASH]: 'Retiro de efectivo en comercio',
        [TransactionType.GIFT_CARD_CREATION]: 'Activación de GiftCard',
      },
    },
    categories: {
      name: 'Rubros',
      labels: {
        [Category.Aerolineas]: 'Aerolíneas',
        [Category.Agencias_y_servicios_de_viajes]: 'Agencias y servicios de viajes',
        [Category.Almuerzo]: 'Almuerzo',
        [Category.Alquiler_de_autos]: 'Alquiler de autos',
        [Category.Belleza_y_cosmetica]: 'Belleza y cosmética',
        [Category.Clubes_y_gimnasios]: 'Clubes y gimnasios',
        [Category.Combustible]: 'Combustible',
        [Category.Electronica]: 'Electrónica',
        [Category.Entretenimiento]: 'Entretenimiento',
        [Category.Farmacia]: 'Farmacia',
        [Category.Ferreteria_y_construccion]: 'Ferreteria y construcción',
        [Category.Ferreterias_y_construccion]: 'Ferreterias y construcción',
        [Category.Free_shop]: 'Free shop',
        [Category.Gimnasios]: 'Gimnasios',
        [Category.Hogar_y_deco]: 'Hogar y deco',
        [Category.Hoteles]: 'Hoteles',
        [Category.Indumentaria]: 'Indumentaria',
        [Category.Informatica]: 'Informática',
        [Category.Instituciones_educativas]: 'Instituciones educativas',
        [Category.Internet_y_telefonia]: 'Internet y telefonía',
        [Category.Juego_y_azar]: 'Juego y azar',
        [Category.Mantenimiento_de_vehiculo]: 'Mantenimiento de vehículo',
        [Category.Mantenimiento_de_vehiculos]: 'Mantenimiento de vehículos',
        [Category.Marketing_digital]: 'Marketing digital',
        [Category.Mascotas]: 'Mascotas',
        [Category.Medicina_y_salud]: 'Medicina y salud',
        [Category.Peajes_y_estacionamientos]: 'Peajes y estacionamientos',
        [Category.Salud_y_medicina]: 'Salud y medicina',
        [Category.Seguros]: 'Seguros',
        [Category.Servicios_e_impuestos]: 'Servicios e impuestos',
        [Category.Supermercado]: 'Supermercado',
        [Category.Transporte_publico]: 'Transporte público',
        [Category.Vuelta_al_cole]: 'Vuelta al cole',
        [Category.Vueltaalcole]: 'Vuelta al cole',
      },
    },
    notifications: {
      name: 'Notificaciones',
    },
    scheduledJobs: {
      success: {
        save: 'La acción programada ha sido guardada exitosamente',
        delete: 'La acción programada ha sido eliminada exitosamente',
      },
      name: 'Acciones programadas',
      errors: {
        delete: 'Hubo un error al eliminar la acción programada',
      },
    },
  },
  rules: {
    edit: {
      title: 'Editar regla',
    },
    create: {
      title: 'Crear regla',
      defaultName: 'Nueva regla',
      defaultCategory: 'Almuerzo',
    },
  },
  Unauthorized: 'Sin autorización',
  kuru: {
    actions: {
      terroristMatches: {
        clearPersonSuccess: 'Colaborador actualizado exitosamente',
        clearCompanySuccess: 'Compañía actualizada exitosamente',
      },
    },
  },
};

const es = {};

// Remove default messages to see translation keys in the app
// merge(es, en);
merge(es, spanishMessages, kuruSpanishMessages);

export default es;
