import { defaultTheme } from 'react-admin';

const white = 'rgb(255, 255, 255)';
const error = {
  main: '#BA1A1A',
  contrastText: white,
};

const dividerColor = '#D5C2C6';

const kuruDarkGray = {
  lightest: 'rgb(218, 220, 224)',
  lighter: 'rgb(142, 151, 160)',
  light: 'rgb(96, 104, 114)',
  main: 'rgb(53, 58, 64)',
  dark: 'rgb(32, 35, 38)',
  contrastText: white,
};

export const kuruPurple = {
  main: '#8C4A60',
  contrastText: white,
  light: '#FFD9E2',
  dark: '#3A071D',
};

export const kuruTeal = {
  main: '#116682',
  light: '#BDE9FF',
  dark: '#001F2A',
  contrastText: white,
  lighter: '#BDE9FF',
  lightest: '#FFFBFF',
};

const kuruViolet = {
  main: '#745086',
  light: '#F6D9FF',
  dark: '#E2B7F4',
  contrastText: white,
  lighter: '#FDEBFF',
  lightest: '#FFFBFF',
};

const kuruChips = {
  main: kuruViolet.light,
  contrastText: kuruDarkGray.main,
};

const customTheme = {
  ...defaultTheme,
  palette: {
    background: {
      default: '#fcfdff',
    },
    primary: kuruPurple,
    secondary: kuruTeal,
    tertiary: kuruViolet,
    kuruChips,
    error: error,
    text: {
      primary: kuruDarkGray.main,
      secondary: kuruDarkGray.light,
      disabled: kuruDarkGray.lighter,
    },
    action: {
      active: kuruTeal.main,
      hover: kuruTeal.lightest,
      selected: kuruTeal.lighter,
      disabled: kuruTeal.lightest,
      focus: kuruTeal.main,
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h6: {
      fontWeight: 300,
    },
  },
  sidebar: {
    width: 240,
    closedWidth: 50,
  },
  components: {
    ...defaultTheme.components,
    RaLayout: {
      styleOverrides: {
        root: {
          '& #main-content.RaLayout-content': {
            background: 'rgb(214, 214, 214)',
            border: 'none',
            padding: '.5rem 1rem',
          },
          '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
          },
          '.hideHelperText': {
            'p.MuiFormHelperText-root': {
              display: 'none',
              '&.Mui-error': {
                display: 'revert',
              },
            },
          },
        },
      },
    },
    RaAppBar: {
      styleOverrides: {
        root: {
          background: white,
          '& h6.MuiTypography-root.MuiTypography-h6': {
            color: kuruDarkGray.main,
          },
          '& button': {
            color: kuruDarkGray.main,
          },
        },
      },
    },
    RaConfirm: {
      styleOverrides: {
        root: {
          '& .RaConfirm-confirmPrimary': {
            color: white,
          },
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          background: white,
          border: 'none',
          '& .RaMenuItemLink-active': {
            borderLeft: '5px solid ' + kuruTeal.main,
            fontWeight: '700',
          },
          '& header ': {
            color: kuruDarkGray.main,
          },
        },
      },
    },
    RaMenu: {
      styleOverrides: {
        root: {
          height: '100%',
          display: 'grid',
          '.support-items': {
            alignSelf: 'end',
          },
        },
      },
    },
    RaToolbar: {
      styleOverrides: {
        root: {
          background: 'none',
          backgroundColor: 'transparent',
        },
      },
    },
    RaTopToolbar: {
      styleOverrides: {
        root: {
          background: 'none',
          backgroundColor: 'transparent',
          '&.disabled': {
            '.MuiButton-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.12)',
              boxShadow: 'none',
            },
          },
        },
      },
    },
    MuiChip: {
      defaultProps: {
        color: 'kuruChips',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          input: {
            // padding: '.5rem .8rem'
          },
          'label[data-shrink=false]': {
            // marginTop: '-.5rem'
          },
          '&.display-field .Mui-disabled': {
            color: 'inherit',
            fieldset: {
              border: '0',
            },
            input: {
              color: 'inherit',
              WebkitTextFillColor: 'inherit',
            },
          },
          '&.MuiFormControl-root': {
            margin: '0 .25rem',
            '.MuiSelect-select': {
              // padding: '.5rem .8rem'
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: 'white',
          border: '1px solid #ccc',
          color: '#000',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          opacity: '.7',
          height: '1rem',
          width: '1rem',
          marginLeft: '.1rem',
          '&.RaBooleanField-trueIcon': {
            color: 'green',
          },
          '&.RaBooleanField-falseIcon': {
            color: 'crimson',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          border: `1px solid ${dividerColor}`,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '&.actionDrawer .MuiDrawer-paper': {
            padding: '2rem',
            width: '25vw',
            minWidth: '360px',
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          '&.ruleConditionToggle': {
            display: 'inline',
            marginLeft: '1rem',
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          '.distributionForm &': {
            // TODO: fix KuruForm styling
            '&.MuiTextField-root': {
              margin: 'unset',
              marginBottom: '1rem',
              '&+.MuiTextField-root': { marginLeft: '1rem' },
            },
            marginBottom: '1rem',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          '.distributionForm &': {
            margin: '1rem',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '.distributionForm &.read-only': {
            opacity: '.5',
          },
        },
      },
    },
    RaAuthError: {
      styleOverrides: {
        root: {
          h1: {
            visibility: 'hidden',
            '& + div': {
              visibility: 'hidden',
              '& + a': {
                padding: '1rem',
                fontSize: '1rem',
                span: {
                  margin: '0',
                },
                svg: {
                  display: 'none',
                },
              },
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          margin: '1rem 0',
          padding: '1rem',
          borderRadius: '10px',
          '&+.MuiCard-root': {
            marginTop: '0',
          },
          '.MuiGrid-root &': {
            margin: '0',
          },
        },
      },
    },
  },
};
// TODO: white sidebar/top bar, colored content background, wrap content in white background cards

export const kuruTheme = customTheme;
