import { FormDataOperation } from './types';

export * from './ScheduledOperationsList';

// TODO: move this logic to the backend, closer the implementation and the library that requires this transformation.
// Keep the API agnostic of the backend solution, let the frontend handle the parameters as its libraries and the
// users use them
export const createCronExpression = (formData: FormDataOperation) => {
  const [hours, minutes] = formData.time.split(':'); // Extraer hora y minutos
  const day = formData.date.split('-')[2];

  const dayOfMonthDate = parseInt(day).toString();
  const hoursParse = parseInt(hours);
  const minutesParse = parseInt(minutes);
  // Verifica si "Repetir último día del mes" está activado
  const dayOfMonth = formData.repeatLastDay ? 'L' : dayOfMonthDate; // 'L' si repetir último día está activado

  // Si "Repetir mensualmente" está activado, usa '*', si no, extrae el mes de la fecha
  const month = formData.repeatMonthly || formData.repeatLastDay ? '*' : formData.date.split('-')[1];

  // Si "Repetir último día" o "Repetir mensualmente" está activado, usar '*' para el año
  const year = formData.repeatLastDay || formData.repeatMonthly ? '*' : formData.date.split('-')[0];

  // Estructura de la expresión cron: "minuto hora día del mes mes año"
  return `${minutesParse} ${hoursParse} ${dayOfMonth} ${month} ? ${year}`;
};
