import { DataProvider, fetchUtils, GetListResult, GetOneResult } from 'react-admin';
import { jwtDecode } from 'jwt-decode';
import { JwtPayload } from 'jsonwebtoken';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { httpClient as raAuth0HttpClient } from 'ra-auth-auth0';

import { ResourceMethodConfig as BaseMethodConfig, ResourceMethodWithBodyConfig as MethodConfigWithBody, getResourceConfig } from './resourcesDataConfig';
import { ResourceMethodConfigForProvider as ResourceConfig } from './resourcesDataConfig';
import { TerroristMatchEntity } from '../views/TerroristMatches';
import { FormFileUpload, FundingRequestForApi } from '../types';
import { HttpOptions, ResourceType } from './types';
import { getAdministrators, getCategories, getNotifications, getRules, getCompanyTransactions } from './resources';
// Not happy at all with these names
import * as CompaniesData from '../resources/companies/dataHandling';
import * as CardHoldersData from '../resources/cardHolders/dataHandling';
import * as CardsData from '../resources/cards/dataHandling';
import * as GiftCardsData from '../resources/giftcards/dataHandling';
import * as TransactionsData from '../resources/transactions/dataHandling';
import * as ScheduledOperationsData from '../resources/scheduledOperations/dataHandling';
import { OperationBody } from '../resources/scheduledOperations/types';

const apiUrl = import.meta.env.VITE_KURU_API_SERVER_URL;

type KuruDataProviderMethod<Params = void> = (resource: string, params: Params) => Promise<Record<string, any>>;
type IncreaseCompanyBalanceParams = { id: string; balanceIncrease: number };
type IncreaseCardBalanceParams = CardsData.IncreaseBalanceParams;
type ExportParams = Record<string, any>;
type GetExportUrlParams = { jobId: string };
type GetTransactionReceiptParams = { transactionId: string };
type GetImportErrorsUrlParams = { jobId: string };
type BulkApplyRuleParams = Partial<{ ids: string[]; ruleId: string; filters: Record<string, any> }>;
type IncreaseBalanceParams = IncreaseCompanyBalanceParams | IncreaseCardBalanceParams;
type BulkCreateCardsParams = Partial<{ ids: string[]; tags: string; ruleId: string }>;
type BulkCreateGiftCardsParams = Partial<{ ids: string[]; tags: string; ruleId: string }>;
type ImportFileParams = { importFile: FormFileUpload; importId: string; importUrl: string };
export type SendInvitationsParams = Partial<{ tags: string; nameStartsWith: string; surnameStartsWith: string; ids: string[] }>;
type GetTerroristMatchesMethod = (entity: string) => Promise<Record<string, any>>;
type MarkNotTerristMethod = (entity: string, ids: string[]) => Promise<Record<string, any>>;
type UpdateTransactionReceiptParams = { transactionId: string; data: { receiptStatus: string } };

export type KuruDataProvider = DataProvider & {
  getCurrentCompanyName: () => Promise<string>;
  export: KuruDataProviderMethod<ExportParams>;
  getExportUrl: KuruDataProviderMethod<GetExportUrlParams>;
  getTransactionReceipt: KuruDataProviderMethod<GetTransactionReceiptParams>;
  getImportErrorsUrl: KuruDataProviderMethod<GetImportErrorsUrlParams>;
  bulkApplyRule: KuruDataProviderMethod<Partial<BulkApplyRuleParams>>;
  increaseBalance: KuruDataProviderMethod<IncreaseBalanceParams>;
  bulkCreateCards: KuruDataProviderMethod<Partial<BulkCreateCardsParams>>;
  bulkCreateGiftCards: KuruDataProviderMethod<Partial<BulkCreateGiftCardsParams>>;
  importFile: KuruDataProviderMethod<ImportFileParams>;
  sendInvitations: KuruDataProviderMethod<SendInvitationsParams>;
  getDashboardData: KuruDataProviderMethod;
  getTerroristMatches: GetTerroristMatchesMethod;
  markNotTerrist: MarkNotTerristMethod;
  updateReceipt: KuruDataProviderMethod<UpdateTransactionReceiptParams>;
  sendReceiptRequiredReminder: KuruDataProviderMethod;
  importCardHoldersSkeletonCsv: KuruDataProviderMethod;
};

export const createDataProvider = (authClient: Auth0Client): DataProvider => {
  let companyId: string;

  const getToken = async () => {
    if (!(await authClient.isAuthenticated())) {
      return;
    }

    return await authClient.getTokenSilently().catch(async (_) => {
      await authClient.logout();
    });
  };

  const setCompanyId = (token: string | void) => {
    console.debug('Getting company from token');
    if (!token) return;

    const decodedToken = jwtDecode<JwtPayload | { company_access: Record<string, string>; is_kuru_admin?: boolean }>(token);
    const companyAccessKeys = Object.keys(decodedToken.company_access);
    const extractedCompanyId = companyAccessKeys[0];
    const companyImpersonation = JSON.parse(sessionStorage.getItem('impersonationCompany') || '{}').id;
    const assignCompany = companyAccessKeys.includes(companyImpersonation) ? companyImpersonation : extractedCompanyId;
    const isKuruAdmin = decodedToken.is_kuru_admin;

    if (companyImpersonation && companyImpersonation !== assignCompany && !isKuruAdmin) {
      sessionStorage.removeItem('impersonationCompany');
      sessionStorage.setItem('impersonationCompany', JSON.stringify({ id: assignCompany, name: decodedToken.company_access[assignCompany] }));

      window.location.reload();
    }

    companyId = JSON.parse(sessionStorage.getItem('impersonationCompany') || '{}').id || extractedCompanyId;

    if (!companyId) throw new Error(`No company id.`);
  };

  const baseHttpclient = raAuth0HttpClient(authClient);
  const httpClient = async (url: string, options: HttpOptions = {}) => {
    const fullUrl = apiUrl + url;
    return baseHttpclient(fullUrl, options);
  };

  const getCompanyId = async () => {
    if (!companyId) {
      setCompanyId(await getToken());
    }

    return companyId;
  };

  const resourceUrl = async (resourceConfig: ResourceConfig<BaseMethodConfig>, params?: any) => {
    if (resourceConfig.isCompanyResource && !companyId) {
      setCompanyId(await getToken());
    }

    const rootPath = resourceConfig.isCompanyResource ? `companies/${companyId}/` : '';

    return rootPath + resourceConfig.url(params);
  };

  const decorateHttpError = (error: any, resource: string, method: string) => {
    error.details = {
      resource,
      method,
    };

    const { status, body, message } = error;
    console.debug(`Error [${status}] on ${resource} ${method}, message: ${body?.error}`, error);

    if (message) {
      error.details.description = error.message;
    } else if (error.status >= 500) {
      error.details.description = 'Error en el servidor, por favor reintentá luego';
    } else if (error.status == 403) {
      error.details.description = 'Autorización expirada';
    }
    return error;
  };

  // TODO use named parameters for this
  const basicHttpErrorDecorator = (error: any, resource: string, method: string) => {
    throw decorateHttpError(error, resource, method);
  };

  const dataProvider: KuruDataProvider = {
    getList: async (resource, params): Promise<GetListResult<any>> => {
      try {
        let result: GetListResult<any>;
        switch (resource as ResourceType) {
          case ResourceType.ADMINISTRATOR: {
            const companyId = await getCompanyId();
            result = await getAdministrators({ request: httpClient }, companyId, params);
            break;
          }
          case ResourceType.CARD_HOLDERS: {
            const companyId = await getCompanyId();
            result = await CardHoldersData.getList({ request: httpClient }, companyId, params);
            break;
          }
          case ResourceType.CARD: {
            const companyId = await getCompanyId();
            result = await CardsData.getList({ request: httpClient }, companyId, params);
            break;
          }
          case ResourceType.GIFTCARD: {
            const companyId = await getCompanyId();
            result = await GiftCardsData.getList({ request: httpClient }, companyId, params);
            break;
          }
          case ResourceType.TRANSACTIONS: {
            const companyId = await getCompanyId();
            result = await TransactionsData.getList({ request: httpClient }, companyId, params);
            break;
          }
          case ResourceType.COMPANY_TRANSACTIONS: {
            const companyId = await getCompanyId();
            result = await getCompanyTransactions({ request: httpClient }, companyId, params);
            break;
          }
          case ResourceType.RULES: {
            const companyId = await getCompanyId();
            result = await getRules({ request: httpClient }, companyId, params);
            break;
          }
          case ResourceType.NOTIFICATIONS: {
            const companyId = await getCompanyId();
            result = await getNotifications({ request: httpClient }, companyId, params);
            break;
          }

          case ResourceType.SCHEDULED_JOBS: {
            const companyId = await getCompanyId();
            result = await ScheduledOperationsData.getList({ request: httpClient }, companyId, params);
            break;
          }

          case ResourceType.COMPANIES: {
            result = await CompaniesData.getList({ request: httpClient }, params);
            break;
          }
          case ResourceType.CATEGORIES: {
            result = await getCategories({ request: httpClient }, params);
            break;
          }
          default: {
            throw new Error(`Not implemented: ${resource}:getList`);
          }
        }

        return result;
      } catch (error: any) {
        throw decorateHttpError(error, resource, 'getList');
      }
    },

    getOne: async (resource, params): Promise<GetOneResult<any>> => {
      try {
        let result: GetOneResult<any>;
        switch (resource as ResourceType) {
          case ResourceType.COMPANIES: {
            result = await CompaniesData.getCompany({ request: httpClient }, params);
            break;
          }
          default: {
            const method = 'getOne';
            const resourceConfig = getResourceConfig<BaseMethodConfig>(method, resource);

            const url = await resourceUrl(resourceConfig, params.id);

            return httpClient(url)
              .then(({ json }) => {
                return { data: resourceConfig.responseHandler(json) };
              })
              .catch((error) => basicHttpErrorDecorator(error, resource, method));
            // TODO: integrate this error handler into the utils one
          }
        }

        return result;
      } catch (error: any) {
        throw decorateHttpError(error, resource, 'getOne');
      }
    },

    getMany: async (resource, params) => {
      console.debug(`No getMany for ${resource}, using getList instead.`);
      return dataProvider.getList(resource, params);
    },

    getManyReference: async (resource, params) => {
      const method = 'getManyReference';
      const resourceConfig = getResourceConfig<BaseMethodConfig>(method, resource);

      const url = await resourceUrl(resourceConfig, params.id);
      return httpClient(url)
        .then(({ json }) => {
          return {
            data: resourceConfig.responseHandler(json),
            total: json.total,
          };
        })
        .catch((error) => basicHttpErrorDecorator(error, resource, method));
    },

    update: async (resource, params) => {
      const method = 'update';
      const resourceConfig = getResourceConfig<MethodConfigWithBody>(method, resource);

      const { data } = params;
      const url = await resourceUrl(resourceConfig, params);
      const requestBody = resourceConfig.requestBody(data);

      return httpClient(url, {
        method: 'PATCH',
        body: JSON.stringify(requestBody),
      })
        .then((response) => {
          return {
            data: resourceConfig.responseHandler(response),
          };
        })
        .catch((error) => basicHttpErrorDecorator(error, resource, method));
    },

    updateMany: async () => {
      throw Error('Not implemented');
    },

    create: async (resource, params) => {
      const method = 'create';
      const resourceConfig = getResourceConfig<MethodConfigWithBody>(method, resource);

      const { data } = params;
      const url = await resourceUrl(resourceConfig, data);

      return httpClient(url, {
        method: 'POST',
        body: JSON.stringify(resourceConfig.requestBody(data)),
      })
        .then((response) => {
          return {
            data: resourceConfig.responseHandler(response),
          };
        })
        .catch((error) => basicHttpErrorDecorator(error, resource, method));
    },

    delete: async (resource, params) => {
      const method = 'delete';
      const resourceConfig = getResourceConfig<BaseMethodConfig>(method, resource);

      const url = await resourceUrl(resourceConfig, params.id);

      return httpClient(url, {
        method: 'DELETE',
      })
        .then(({ json }) => {
          return { data: resourceConfig.responseHandler(json) };
        })
        .catch((error) => basicHttpErrorDecorator(error, resource, method));
    },

    deleteMany: async () => {
      throw Error('Not implemented');
    },

    // Begin custom methods
    getCurrentCompanyName: async () => {
      const resource = 'companies';
      const method = 'getOne';
      const resourceConfig = getResourceConfig<BaseMethodConfig>(method, resource);

      const url = await resourceUrl(resourceConfig, await getCompanyId());

      return httpClient(url)
        .then(({ json }) => {
          return resourceConfig.responseHandler(json).name;
        })
        .catch((error) => basicHttpErrorDecorator(error, resource, method));
    },

    export: async (resource, params) => {
      try {
        let result: Record<string, any>;
        switch (resource as ResourceType) {
          case ResourceType.CARD_HOLDERS: {
            const companyId = await getCompanyId();
            result = await CardHoldersData.requestExport({ request: httpClient }, companyId);
            break;
          }
          case ResourceType.CARD: {
            const companyId = await getCompanyId();
            result = await CardsData.requestExport({ request: httpClient }, companyId);
            break;
          }
          case ResourceType.TRANSACTIONS: {
            const companyId = await getCompanyId();
            result = await TransactionsData.requestExport({ request: httpClient }, companyId, params);
            break;
          }
          default: {
            throw new Error(`Not implemented: ${resource}:export`);
          }
        }
        return result;
      } catch (error: any) {
        throw decorateHttpError(error, resource, 'export');
      }
    },

    getExportUrl: async (resource, params) => {
      const method = 'getExportUrl';
      const resourceConfig = getResourceConfig<BaseMethodConfig>(method, resource);

      const url = await resourceUrl(resourceConfig, params.jobId);

      return httpClient(url, {
        method: 'POST',
      })
        .then(({ json }) => {
          return { data: resourceConfig.responseHandler(json) };
        })
        .catch((error) => basicHttpErrorDecorator(error, resource, method));
    },

    getTransactionReceipt: async (resource, params) => {
      const method = 'getTransactionReceipt';
      const resourceConfig = getResourceConfig<BaseMethodConfig>(method, resource);
      const url = await resourceUrl(resourceConfig, params.transactionId);
      return httpClient(url, {
        method: 'POST',
      })
        .then((response) => {
          return {
            data: resourceConfig.responseHandler(response),
          };
        })
        .catch((error) => basicHttpErrorDecorator(error, resource, method));
    },

    sendReceiptRequiredReminder: async (resource) => {
      const companyId = await getCompanyId();

      return httpClient(`companies/${companyId}/jobs/send-receipt-required-reminder`, {
        method: 'POST',
      })
        .then((response) => {
          return {
            data: response.json,
          };
        })
        .catch((error) => basicHttpErrorDecorator(error, resource, 'sendReceiptRequiredReminder'));
    },

    getImportErrorsUrl: async (resource, params) => {
      const method = 'getImportErrorsUrl';
      const resourceConfig = getResourceConfig<BaseMethodConfig>(method, resource);

      const url = await resourceUrl(resourceConfig, params.jobId);

      return httpClient(url, {
        method: 'POST',
      })
        .then(({ json }) => {
          return { data: resourceConfig.responseHandler(json) };
        })
        .catch((error) => basicHttpErrorDecorator(error, resource, method));
    },

    bulkApplyRule: async (resource, params) => {
      const method = 'bulkApplyRule';
      const resourceConfig = getResourceConfig<MethodConfigWithBody>(method, resource);

      const url = await resourceUrl(resourceConfig, params);
      const requestBody = resourceConfig.requestBody(params);

      return httpClient(url, {
        method: 'POST',
        body: JSON.stringify(requestBody),
      })
        .then((response) => {
          return {
            data: response,
          };
        })
        .catch((error) => basicHttpErrorDecorator(error, resource, method));
    },

    increaseBalance: async (resource, params) => {
      try {
        let result: Record<string, any>;
        switch (resource as ResourceType) {
          case ResourceType.CARD: {
            const companyId = await getCompanyId();
            result = await CardsData.increaseBalance({ request: httpClient }, companyId, params as IncreaseCardBalanceParams);
            break;
          }
          default: {
            const method = 'increaseBalance';
            const resourceConfig = getResourceConfig<MethodConfigWithBody>(method, resource);

            const url = await resourceUrl(resourceConfig, params);
            const requestBody = resourceConfig.requestBody(params);

            return httpClient(url, {
              method: 'POST',
              body: JSON.stringify(requestBody),
            })
              .then((response) => {
                return {
                  data: resourceConfig.responseHandler(response),
                };
              })
              .catch((error) => basicHttpErrorDecorator(error, resource, method));
          }
        }
        return result;
      } catch (error: any) {
        throw decorateHttpError(error, resource, 'increaseBalance');
      }
    },

    bulkCreateCards: async (resource, params) => {
      const method = 'bulkCreateCards';
      const resourceConfig = getResourceConfig<MethodConfigWithBody>(method, resource);

      const url = await resourceUrl(resourceConfig, params);
      const requestBody = resourceConfig.requestBody(params);

      return httpClient(url, {
        method: 'POST',
        body: JSON.stringify(requestBody),
      })
        .then((response) => response)
        .catch((error) => basicHttpErrorDecorator(error, resource, method));
    },

    bulkCreateGiftCards: async (resource, params) => {
      const method = 'bulkCreateGiftCards';
      const resourceConfig = getResourceConfig<MethodConfigWithBody>(method, resource);

      const url = await resourceUrl(resourceConfig, params);
      const requestBody = resourceConfig.requestBody(params);

      return httpClient(url, {
        method: 'POST',
        body: JSON.stringify(requestBody),
      })
        .then((response) => response)
        .catch((error) => basicHttpErrorDecorator(error, resource, method));
    },

    importFile: async (resource, params) => {
      const configToCreate = getResourceConfig<BaseMethodConfig>('importFileCreate', resource);
      const createUrl = await resourceUrl(configToCreate, params);
      let jobId, uploadUrl;

      try {
        const { json } = await httpClient(createUrl, {
          method: 'POST',
        });

        jobId = json.jobId;
        uploadUrl = json.uploadUrl;
      } catch (error) {
        basicHttpErrorDecorator(error, resource, 'importFileCreate');
      }

      const { importFile } = params;

      try {
        await fetchUtils.fetchJson(uploadUrl, {
          method: 'PUT',
          body: await importFile.rawFile.text(),
        });
      } catch (error) {
        basicHttpErrorDecorator(error, resource, 'importFileUpload');
      }

      const configToStart = getResourceConfig<BaseMethodConfig>('importFileStart', resource);
      const startUrl = await resourceUrl(configToStart, { jobId });

      try {
        const { json } = await httpClient(startUrl, {
          method: 'POST',
        });

        return json;
      } catch (error) {
        basicHttpErrorDecorator(error, resource, 'importFileStart');
      }
    },

    sendInvitations: async (resource, params = {}) => {
      const method = 'sendInvitations';
      const resourceConfig = getResourceConfig<MethodConfigWithBody>(method, resource);

      const url = await resourceUrl(resourceConfig, params);
      const requestBody = resourceConfig.requestBody(params);

      return httpClient(url, {
        method: 'POST',
        body: JSON.stringify(requestBody),
      })
        .then((response) => response)
        .catch((error) => basicHttpErrorDecorator(error, resource, method));
    },

    getDashboardData: async (resource) => {
      const method = 'getDashboardData';
      const resourceConfig = getResourceConfig<MethodConfigWithBody>(method, resource);
      const url = await resourceUrl(resourceConfig);
      const requestBody = resourceConfig.requestBody();
      return httpClient(url, {
        method: 'POST',
        body: JSON.stringify(requestBody),
      })
        .then((response) => {
          return {
            data: resourceConfig.responseHandler(response),
          };
        })
        .catch((error) => basicHttpErrorDecorator(error, resource, method));
    },

    updateReceipt: async (resource, params) => {
      const method = 'updateReceipt';
      const resourceConfig = getResourceConfig<MethodConfigWithBody>(method, resource);
      const url = await resourceUrl(resourceConfig, params.transactionId);
      const requestBody = params.data;
      return httpClient(url, {
        method: 'PATCH',
        body: JSON.stringify(requestBody),
      })
        .then((response) => {
          return {
            data: resourceConfig.responseHandler(response),
          };
        })
        .catch((error) => basicHttpErrorDecorator(error, resource, method));
    },

    // getTerroristMatches: async (entity: TerroristMatchEntity) => {
    getTerroristMatches: async (entity) => {
      return httpClient(`admin/terrorist-matches/${entity}`)
        .then((response) => {
          return {
            data: response.json,
          };
        })
        .catch((error) => basicHttpErrorDecorator(error, entity, 'getTerroristMatches'));
    },

    // TODO: probably need to adjust declaration of type to be able to use
    // markNotTerrist: async (entity: TerroristMatchEntity, ids: string[]) => {
    // otherwise it throws an error for not finding a matching method
    markNotTerrist: async (entity, ids) => {
      const entityPath = entity === TerroristMatchEntity.COMPANIES ? 'mark-companies-as-not-terrorist' : 'mark-card-holders-as-not-terrorist';

      return httpClient(`admin/terrorist-matches/${entityPath}`, {
        method: 'POST',
        body: JSON.stringify({ ids }),
      })
        .then((response) => {
          return {
            data: response.json,
          };
        })
        .catch((error) => basicHttpErrorDecorator(error, entity, 'markNotTerrist'));
    },

    requestFunding: async (fundingRequest: FundingRequestForApi) => {
      const companyId = await getCompanyId();
      return httpClient(`companies/${companyId}/funding-requests`, {
        method: 'POST',
        body: JSON.stringify(fundingRequest),
      })
        .then((response) => {
          return {
            data: response.json,
          };
        })
        .catch((error) => basicHttpErrorDecorator(error, '', 'requestFunding'));
    },

    editOperationSchedule: async (operationBody: OperationBody, idOperation: string) => {
      const companyId = await getCompanyId();
      return httpClient(`companies/${companyId}/scheduled-jobs/${idOperation}`, {
        method: 'PATCH',
        body: JSON.stringify(operationBody),
      })
        .then((response) => {
          return {
            data: response.json,
          };
        })
        .catch((error) => basicHttpErrorDecorator(error, '', 'editOperationSchedule'));
    },

    importCardHoldersSkeletonCsv: async (resource) => {
      return httpClient('import-card-holders-skeleton-csv', {
        method: 'GET',
      })
        .then((response) => {
          return {
            data: response.body,
          };
        })
        .catch((error) => basicHttpErrorDecorator(error, resource, 'importCardHoldersSkeletonCsv'));
    },
  };

  return dataProvider;
};
