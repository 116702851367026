export type MaxAmountCondition = {
  maxAmount?: number;
  byDay?: number;
  byWeek?: number;
  byMonth?: number;
};

export type DaysCondition = {
  daysAllowed: string[];
};
export type TimeCondition = number[];
export type TransactionLimitsCondition = Record<'limitByDay' | 'limitByWeek' | 'limitByMonth', number>;
export type CategoriesCondition = { categories: string[] };
export type ExtractionMaxAmountCondition = Record<'maxAmount' | 'byDay' | 'byWeek' | 'byMonth', number>;

export type ApiDaysCondition = Record<'daysAllowed', number[]>;
export type TimePair = Record<'hours' | 'minutes', number>;
export type ApiTimeCondition = Record<'from' | 'to', TimePair>;
export type ApiTransactionLimitsCondition = Partial<TransactionLimitsCondition>;
export type ApiMaxAmountCondition = {
  maxAmount?: number;
  byDay?: number;
  byWeek?: number;
  byMonth?: number;
};

export type ApiExtractionMaxAmountCondition = Partial<ExtractionMaxAmountCondition>;

export type TxConditions = {
  days?: DaysCondition;
  maxAmount?: MaxAmountCondition;
  time?: TimeCondition;
  transactionLimits?: TransactionLimitsCondition;
  currency?: Record<'allowLocalCurrency' | 'allowForeignCurrency', boolean>;
  categories?: CategoriesCondition;
  extractionMaxAmount?: ExtractionMaxAmountCondition;
};

export type ExpirationDate = string | null;
type ApiExpirationDate = number | null;

type CardConditions = {
  receiptRequired: boolean;
  expirationDate: ExpirationDate; // TODO: deactivationDate
  isPhysicalCardDefault?: boolean;
};

type RuleBase = {
  id: string;
  companyId: string;
  name: string;
};

export type Rule = RuleBase &
  CardConditions & {
    conditions: TxConditions;
  };

export type RuleFormRecord = Rule & {
  enabled: ConditionsEnablement;
};

export type ApiTxConditions = Omit<TxConditions, 'days' | 'time' | 'transactionLimits' | 'extractionMaxAmount'> & {
  days?: ApiDaysCondition;
  time?: ApiTimeCondition;
  transactionLimits?: ApiTransactionLimitsCondition;
  extractionMaxAmount?: ApiExtractionMaxAmountCondition;
};

export type ApiCardConditions = Omit<CardConditions, 'expirationDate'> & {
  expirationDate: ApiExpirationDate;
};

export type ApiRule = RuleBase &
  ApiCardConditions & {
    conditions: ApiTxConditions;
  };

export type RequestRule = Omit<ApiRule, 'id' | 'companyId'>;

type ConditionsEnablement = Record<string, boolean>;

export enum Category {
  Aerolineas = 'AEROLINEAS',
  Agencias_y_servicios_de_viajes = 'AGENCIAS Y SERVICIOS DE VIAJES',
  Almuerzo = 'ALMUERZO',
  Alquiler_de_autos = 'ALQUILER DE AUTOS',
  Belleza_y_cosmetica = 'BELLEZA Y COSMETICA',
  Clubes_y_gimnasios = 'CLUBES Y GIMNASIOS',
  Combustible = 'COMBUSTIBLE',
  Electronica = 'ELECTRONICA',
  Entretenimiento = 'ENTRETENIMIENTO',
  Farmacia = 'FARMACIA',
  Ferreteria_y_construccion = 'FERRETERIA Y CONSTRUCCION',
  Ferreterias_y_construccion = 'FERRETERIAS Y CONSTRUCCION',
  Free_shop = 'FREE SHOP',
  Gimnasios = 'GIMNASIOS',
  Hogar_y_deco = 'HOGAR Y DECO',
  Hoteles = 'HOTELES',
  Indumentaria = 'INDUMENTARIA',
  Informatica = 'INFORMATICA',
  Instituciones_educativas = 'INSTITUCIONES EDUCATIVAS',
  Internet_y_telefonia = 'INTERNET Y TELEFONIA',
  Juego_y_azar = 'JUEGO Y AZAR',
  Mantenimiento_de_vehiculo = 'MANTENIMIENTO DE VEHICULO',
  Mantenimiento_de_vehiculos = 'MANTENIMIENTO DE VEHICULOS',
  Marketing_digital = 'MARKETING DIGITAL',
  Mascotas = 'MASCOTAS',
  Medicina_y_salud = 'MEDICINA Y SALUD',
  Peajes_y_estacionamientos = 'PEAJES Y ESTACIONAMIENTOS',
  Salud_y_medicina = 'SALUD Y MEDICINA',
  Seguros = 'SEGUROS',
  Servicios_e_impuestos = 'SERVICIOS E IMPUESTOS',
  Supermercado = 'SUPERMERCADO',
  Transporte_publico = 'TRANSPORTE PUBLICO',
  Vueltaalcole = 'VUELTA_AL_COLE',
  Vuelta_al_cole = 'VUELTA AL COLE',
}
