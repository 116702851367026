import get from 'lodash/get';

import { formatRuleRequest, formatRuleResponse } from '../resources/rules/dataHelpers';
import { formatCompanyRequest } from '../resources/companies/CompanyForm';
import { ApiCardHolder } from '../resources/cardHolders';
import { BulkActionRequestBody } from '../types';
import { getLastMonthDateRange } from '../utils';

type ResourceMethod =
  | 'getList'
  | 'getOne'
  | 'getMany'
  | 'getManyReference'
  | 'update'
  | 'updateMany'
  | 'create'
  | 'delete'
  | 'deleteMany'
  | 'export'
  | 'getExportUrl'
  | 'bulkApplyRule'
  | 'increaseBalance'
  | 'bulkCreateCards'
  | 'bulkCreateGiftCards'
  | 'getImportErrorsUrl'
  | 'importFileCreate'
  | 'importFileStart'
  | 'sendInvitations'
  | 'getDashboardData'
  | 'getTransactionReceipt'
  | 'updateReceipt'
  | 'sendReceiptRequiredReminder';

// TODO: make customizable with generics to specify types according to the
// data provider method the config is for (and get rid of ResourceMethodWithBodyConfig)
export type ResourceMethodConfig = {
  url: (param?: any) => string;
  responseHandler: (param?: any, filter?: any) => any;
};

export type ResourceMethodWithBodyConfig = ResourceMethodConfig & {
  requestBody: (param?: any) => any;
};

export type ResourceMethodConfigForProvider<T> = T & {
  isCompanyResource: boolean;
};

type ResourceMethodsConfig = Partial<Record<ResourceMethod, ResourceMethodConfig | ResourceMethodWithBodyConfig>> & {
  isNotCompanyResource?: boolean;
};

export function getResourceConfig<T>(method: string, resourceName: string): ResourceMethodConfigForProvider<T> {
  console.debug(`Attempting ${method} for ${resourceName}`);
  const resourceConfig = resourcesConfig.get(resourceName);

  if (!resourceConfig || !Object.hasOwn(resourceConfig, method) || !get(resourceConfig, method).url || !get(resourceConfig, method).responseHandler) {
    throw new Error(`Missing configuration for resource '${resourceName}'.`);
  }

  return {
    ...get(resourceConfig, method),
    isCompanyResource: !resourceConfig.isNotCompanyResource,
  };
}

// more like noOpHandover
const noOp = (param: any) => param;

// @param.filterQueryParams: array of query params (e.g. ['tags=a-tag'])
const administratorsDataConfig: ResourceMethodsConfig = {
  getOne: {
    url: (administratorId: string) => `administrators/${administratorId}`,
    responseHandler: (user) => {
      return {
        ...user,
        id: user.user_id,
        role: user.app_metadata.roles && user.app_metadata.roles[0], // TODO: support more than one role?
      };
    },
  },
  create: {
    url: () => 'administrators',
    requestBody: noOp,
    responseHandler: (json) => {
      return {
        id: json.user_id,
      };
    },
  },
  delete: {
    url: (adminId: string) => `administrators/${adminId}`,
    responseHandler: noOp,
  },
};

const cardHoldersDataConfig: ResourceMethodsConfig = {
  getOne: {
    url: (cardHolderId: string) => `card-holders/${cardHolderId}`,
    responseHandler: noOp,
  },
  create: {
    url: () => 'card-holders',
    requestBody: (data: ApiCardHolder) => {
      const trimFields = (data: ApiCardHolder) => {
        return {
          ...data,
          name: data.name?.trim(),
          surname: data.surname?.trim(),
          email: data.email?.trim(),
          phoneNumber: data.phoneNumber?.trim(),
          taxIdentificationValue: data.taxIdentificationValue?.trim(),
          identificationValue: data.identificationValue?.trim(),
          externalId: data.externalId?.trim(),
        };
      };
      const trimmedData = trimFields(data);
      const { tags, ...rest } = trimmedData;
      const actualTags = tags?.filter((t) => !!t); // filter out nulls that the input component may produce

      const body: Partial<ApiCardHolder> = {
        ...(actualTags && actualTags.length > 0 && { tags: actualTags }),
        ...rest,
      };

      return body;
    },
    responseHandler: (response) => response.json,
  },
  update: {
    url: ({ data }: Record<string, any>) => `card-holders/${data.id}`,
    requestBody: (data: ApiCardHolder) => {
      const trimFieldsForUpdate = (data: ApiCardHolder) => {
        return {
          ...data,
          name: data.name?.trim(),
          surname: data.surname?.trim(),
          email: data.email?.trim(),
          phoneNumber: data.phoneNumber?.trim(),
          externalId: data.externalId?.trim(),
        };
      };
      const trimmedData = trimFieldsForUpdate(data);
      const { phoneNumber, tags, name, surname, email, externalId } = trimmedData;
      const actualTags = tags?.filter((t) => t); // filter out nulls that the input component may produce
      return {
        phoneNumber,
        tags: actualTags && actualTags.length > 0 ? tags : null,
        name: name,
        surname: surname,
        email: email,
        externalId,
      };
    },
    responseHandler: (response) => response.json,
  },
  delete: {
    url: (cardHolderId: string) => `card-holders/${cardHolderId}`,
    responseHandler: noOp,
  },
  getExportUrl: {
    url: (jobId: string) => `jobs/export-card-holders/${jobId}/download-url`,
    responseHandler: noOp,
  },
  getImportErrorsUrl: {
    url: (jobId: string) => `jobs/import-card-holders/${jobId}/failures-download-url`,
    responseHandler: noOp,
  },
  bulkCreateCards: {
    url: () => `jobs/cards-creation`,
    requestBody: ({ tags, nameStartsWith, surnameStartsWith, ids, ruleId }) => {
      const body: BulkActionRequestBody = { query: {} };

      if (ids && ids.length > 0) {
        body.query.ids = ids;
      } else if (tags || nameStartsWith || surnameStartsWith) {
        const tagsArray: string[] = tags?.trim().split(' ');
        if (tagsArray.length > 0) body.query.tags = tagsArray;
        if (nameStartsWith) body.query.nameStartsWith = nameStartsWith;
        if (surnameStartsWith) body.query.surnameStartsWith = surnameStartsWith;
      } else {
        throw Error('No tags or ids provided for bulk creation of cards');
      }

      if (ruleId) {
        body.operation = { ruleId };
      }

      return body;
    },
    responseHandler: noOp,
  },
  bulkCreateGiftCards: {
    url: () => `jobs/gift-cards-creation`,
    requestBody: ({ tags, nameStartsWith, surnameStartsWith, ids, amount }) => {
      const body: BulkActionRequestBody = { query: {} };

      if (ids && ids.length > 0) {
        body.query.ids = ids;
      } else if (tags || nameStartsWith || surnameStartsWith) {
        const tagsArray: string[] = tags?.trim().split(' ');
        if (tagsArray.length > 0) body.query.tags = tagsArray;
        if (nameStartsWith) body.query.nameStartsWith = nameStartsWith;
        if (surnameStartsWith) body.query.surnameStartsWith = surnameStartsWith;
      } else {
        throw Error('No tags or ids provided for bulk creation of gift cards');
      }

      // TODO: de-harcdode product id
      body.operation = { amount, product: '2031' };

      return body;
    },
    responseHandler: noOp,
  },
  importFileCreate: {
    url: () => 'jobs/import-card-holders',
    responseHandler: noOp,
  },
  importFileStart: {
    url: ({ jobId }) => `jobs/import-card-holders/${jobId}/start`,
    responseHandler: noOp,
  },
  sendInvitations: {
    url: () => `jobs/send-phone-validation-emails`,
    requestBody: (params) => {
      // No params => empty body
      if (Object.keys(params).length === 0) return {};

      const { tags, nameStartsWith, surnameStartsWith, ids } = params;
      const body: BulkActionRequestBody = { query: {} };
      const tagsArray: string[] = tags?.trim().split(' ');

      if (ids && ids.length > 0) {
        body.query = { ids };
      } else {
        if (tagsArray && tagsArray.length > 0) {
          body.query.tags = tagsArray;
        }
        if (nameStartsWith) {
          body.query.nameStartsWith = nameStartsWith;
        }
        if (surnameStartsWith) {
          body.query.surnameStartsWith = surnameStartsWith;
        }
      }

      return body;
    },
    responseHandler: noOp,
  },
};

const cardsDataConfig: ResourceMethodsConfig = {
  getOne: {
    url: (cardId: string) => `cards/${cardId}`,
    responseHandler: noOp,
  },
  getManyReference: {
    url: (cardHolderId: string) => `card-holders/${cardHolderId}/cards`,
    responseHandler: (json) => json.cards,
  },
  create: {
    url: (params) => `card-holders/${params.cardHolderId}/cards`,
    requestBody: ({ ruleId }) => ({
      card_type: 'VIRTUAL',
      rule_id: ruleId,
    }),
    responseHandler: (response) => response.json,
  },
  update: {
    url: ({ id: cardId, data }: Record<string, any>) => `card-holders/${data.cardHolderId}/cards/${cardId}`,
    requestBody: (data) => {
      const { ruleId } = data;
      return { ruleId };
    },
    responseHandler: (response) => response.json,
  },
  bulkApplyRule: {
    url: ({ ruleId }) => `actions/apply-rule/${ruleId}`,
    requestBody: ({ ids, filters = {} }) => {
      const body: BulkActionRequestBody = { query: {} };
      const anyFilterIsApplied = Object.keys(filters).some((filterName) => filters[filterName].length > 0);

      if (ids && ids.length > 0) {
        body.query = { ids };
      } else if (anyFilterIsApplied) {
        // const { ruleId, cardHolderTags, cardHolderNameStartsWith, cardHolderSurnameStartsWith } = filters;
        const { ruleId, cardHolderTags } = filters;

        const queryFilters: Record<string, any> = {};

        if (ruleId) queryFilters.ruleId = ruleId;

        /* Temporarily commented-out, not supported yet
        if (cardHolderNameStartsWith) queryFilters.cardHolderNameStartsWith = cardHolderNameStartsWith;
        if (cardHolderSurnameStartsWith) queryFilters.cardHolderSurnameStartsWith = cardHolderSurnameStartsWith;
         */

        if (cardHolderTags) {
          const tags: string[] = cardHolderTags.trim().split(' ');
          const trimmedTags = [];

          for (const tag of tags) {
            const trimmedTag = tag.trim();
            if (trimmedTag) {
              trimmedTags.push(trimmedTag);
            }
          }

          queryFilters.cardHolderTags = trimmedTags;
        }

        body.query.filters = queryFilters;
      } else {
        throw Error('No ids or filter values provided for bulk balance update');
      }

      return body;
    },
    responseHandler: noOp,
  },
  getExportUrl: {
    url: (jobId: string) => `jobs/export-cards/${jobId}/download-url`,
    responseHandler: noOp,
  },

  delete: {
    url: (cardId: string) => `cards/${cardId}`,
    responseHandler: noOp,
  },
};

const giftCardsDataConfig: ResourceMethodsConfig = {
  getOne: {
    url: (cardId: string) => `gift-cards/${cardId}`,
    responseHandler: noOp,
  },
  getManyReference: {
    url: (cardHolderId: string) => `card-holders/${cardHolderId}/gift-cards`,
    responseHandler: (json) => json.cards,
  },
};

const rulesDataConfig: ResourceMethodsConfig = {
  getOne: {
    url: (ruleId: string) => `rules/${ruleId}`,
    responseHandler: formatRuleResponse,
  },
  create: {
    url: () => `rules`,
    requestBody: formatRuleRequest,
    responseHandler: (response) => response.json,
  },
  update: {
    url: ({ id }: Record<string, string>) => `rules/${id}`,
    requestBody: formatRuleRequest,
    responseHandler: (response) => response.json,
  },
  delete: {
    url: (ruleId: string) => `rules/${ruleId}`,
    responseHandler: noOp,
  },
};

const transactionsDataConfig: ResourceMethodsConfig = {
  getExportUrl: {
    url: (jobId: string) => `jobs/export-transactions/${jobId}/download-url`,
    responseHandler: noOp,
  },
  getTransactionReceipt: {
    url: (transactionId: string) => `transactions/${transactionId}/receipt`,
    responseHandler: (response) => response.json,
  },
  updateReceipt: {
    url: (transactionId: string) => `transactions/${transactionId}`,
    responseHandler: (response) => response.json,
  },
};

const companiesDataConfig: ResourceMethodsConfig = {
  isNotCompanyResource: true,
  getOne: {
    url: (companyId: string) => `companies/${companyId}`,
    responseHandler: noOp,
  },
  create: {
    url: () => 'admin/companies',
    requestBody: formatCompanyRequest,
    responseHandler: (response) => response.json,
  },
  delete: {
    url: (companyId: string) => `admin/companies/${companyId}`,
    responseHandler: noOp,
  },
  increaseBalance: {
    url: (params) => `companies/${params.id}/actions/add-balance`,
    requestBody: (params: { balanceIncrease: number }) => ({
      amount: params.balanceIncrease,
    }),
    responseHandler: noOp,
  },
};

const dashboardDataConfig: ResourceMethodsConfig = {
  getDashboardData: {
    url: () => `dashboard`,
    requestBody: () => {
      const { fromDate, toDate } = getLastMonthDateRange();
      return {
        fromDate,
        toDate,
      };
    },
    responseHandler: (response) => response.json,
  },
};

const scheduledJobsDataConfig: ResourceMethodsConfig = {
  delete: {
    url: (idOperation: string) => `scheduled-jobs/${idOperation}`,
    responseHandler: noOp,
  },
};

const resourcesConfig: Map<string, ResourceMethodsConfig> = new Map([
  ['administrators', administratorsDataConfig],
  ['cardHolders', cardHoldersDataConfig],
  ['cards', cardsDataConfig],
  ['giftcards', giftCardsDataConfig],
  ['rules', rulesDataConfig],
  ['transactions', transactionsDataConfig],
  ['companies', companiesDataConfig],
  ['dashboard', dashboardDataConfig],
  ['scheduledJobs', scheduledJobsDataConfig],
]);
