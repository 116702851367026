import { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { PieChart } from '@mui/x-charts';
import { FormattedNumber } from 'react-intl';
import { getErrorHandler } from '../../utils';
import { kuruPurple, kuruTeal } from '../../theme/kuru-theme';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

const ClientUserDashboard = () => {
  const dataProvider = useDataProvider();
  const [balances, setBalances] = useState({ cards: 0, company: 0 });
  const [loading, setLoading] = useState(true);
  const notify = useNotify();

  const currencyFormatter = (value: number) => {
    const formatter = new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
    });

    const formattedValue = formatter.format(value);

    return formattedValue;
  };

  const totalBalance = balances.cards + balances.company;
  useEffect(() => {
    dataProvider
      .getDashboardData('dashboard')
      .then((response: any) => {
        setBalances({
          cards: response.data.balance.cards,
          company: response.data.balance.company,
        });
        setLoading(false);
      })
      .catch((error: any) => getErrorHandler(notify)(error));
  }, [dataProvider, notify]);

  return (
    <>
      <Card sx={{ padding: '1rem', border: '1px solid black' }}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={6}>
            <h3 style={{ marginBlockEnd: '0.3em', marginBlockStart: '0.3em' }}>Saldo de la compañía</h3>
            {loading ? (
              <Skeleton variant="rounded" width={300} height={200} animation="wave" />
            ) : (
              <>
                <Typography variant="body1">
                  <FormattedNumber value={totalBalance} style="currency" currency="ARS" currencyDisplay="narrowSymbol" />
                </Typography>

                <PieChart
                  slotProps={{
                    legend: {
                      hidden: true,
                    },
                  }}
                  colors={[kuruTeal.main, kuruPurple.main]}
                  series={[
                    {
                      data: [
                        { id: 0, value: balances.cards, label: 'Asignado' },
                        { id: 1, value: balances.company, label: 'Sin asignar' },
                      ],
                      innerRadius: 30,
                      outerRadius: 100,
                      paddingAngle: 5,
                      cornerRadius: 5,

                      cx: 150,
                      cy: 130,
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                      valueFormatter: (v) => currencyFormatter(v.value),
                    },
                  ]}
                  width={400}
                  height={270}
                  sx={{
                    ['& .MuiPieArcLabel-root']: {
                      fill: 'white',
                    },
                  }}
                />
                <Grid container direction="column" justifyContent="center" spacing={3}>
                  <Grid item>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ width: 16, height: 40, backgroundColor: kuruTeal.main, mr: 1, borderRadius: 1 }} />
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body2" sx={{ fontSize: '1rem', color: 'black' }}>
                          Asignado
                        </Typography>
                        <Typography variant="body2">
                          <FormattedNumber value={balances.cards} style="currency" currency="ARS" currencyDisplay="narrowSymbol" />
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ width: 16, height: 40, backgroundColor: kuruPurple.main, mr: 1, borderRadius: 1 }} />
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body2" sx={{ fontSize: '1rem', color: 'black' }}>
                          Sin asignar
                        </Typography>
                        <Typography variant="body2">
                          <FormattedNumber value={balances.company} style="currency" currency="ARS" currencyDisplay="narrowSymbol" />
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ClientUserDashboard;
