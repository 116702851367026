import {
  Datagrid,
  FieldProps,
  FunctionField,
  Link,
  List,
  SelectInput,
  TextField,
  useNotify,
  useRecordContext,
  useTranslate,
  useDataProvider,
  TextInput,
  useListContext,
  useGetList,
  AutocompleteInput,
  useRefresh,
  SelectInputProps,
  AutocompleteInputProps,
  Button,
} from 'react-admin';
import { MouseEvent, useState, FC, ChangeEvent } from 'react';

import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';

import { Transaction, TransactionStatus, TransactionReceiptStatus, ReceiptIconProps, cardTransactionTypes } from './types';
import { displayString as cardHolderDisplayString } from '../cardHolders';
import { displayDateTime, getErrorHandler } from '../../utils';
import { CardHolderTagsFilter, DateTimeFilter, TxTypeFilter } from '../../components/filters';
import { KuruListActions } from '../../components/toolbars';
import receiptDownload from '../../images/receiptDownload.svg';
import receiptRequired from '../../images/receiptRequired.svg';
import receiptNotRequired from '../../images/receiptNotRequired.svg';
import receiptColumn from '../../images/receipt_long_24dp_5F6368.svg';
import SvgIcon from '@mui/material/SvgIcon';
import { displayTxAmount } from '../../components/CurrencyDisplay';
import Stack from '@mui/material/Stack';
import ArrayChipsField from '../../components/ArrayChipsField';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

export const displayTxCardLink = (record: Transaction) => {
  // TODO remove when confident we seriously won't have cash-in here
  // TODO podriamos mostrar el id de giftcard en cardId de transaction si el backend lo manda, not sure.
  if (record?.type === 'cash-in' || record?.type === 'gift-card-creation') return null;

  return <CardLink label="Tarjeta" />;
};

const displayCardHolder = (record: Transaction) => {
  if (record?.type === 'cash-in') return null;

  return (
    <>
      <div>{cardHolderDisplayString(record?.cardHolder)}</div>
      <ArrayChipsField source="cardHolder.tags" />
    </>
  );
};

export const DisplayCardType = (record: Transaction) => {
  const translate = useTranslate();

  return <div>{translate('resources.cards.type.' + record?.card?.type)}</div>;
};

const StatusFilter = (props: SelectInputProps) => {
  const translate = useTranslate();

  const { source, ...otherProps } = props;
  const choices = Object.values(TransactionStatus).map((status) => ({
    id: status,
    name: translate('resources.transactions.status.' + status),
  }));

  return <SelectInput {...otherProps} choices={choices} source={source} label="Estado" />;
};

export const TransactionList = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);

  const displayStatus = (record: Transaction) => {
    if (record?.type === 'cash-in') return null;

    const status: TransactionStatus = record?.status;

    const tooltipText =
      record?.status === TransactionStatus.REJECTED_BY_RULE ? (
        <>
          <div>{`Regla: ${displayRuleName(record)}.`}</div>
          <div>{`Condición: ${displayCondition(record)}`}</div>
        </>
      ) : (
        false
      );

    return (
      <Tooltip title={tooltipText} placement="top-end">
        <span>{translate(`resources.transactions.status.${status}`)}</span>
      </Tooltip>
    );
  };

  const displayRuleName = (record: Transaction) => {
    if (record?.status === TransactionStatus.REJECTED_BY_RULE) {
      return record?.rejectReasonRuleName;
    } else {
      return '-';
    }
  };

  const displayCondition = (record: Transaction) => {
    if (record?.status === TransactionStatus.REJECTED_BY_RULE) {
      const condition: string = record?.rejectReasonConditionName;
      return translate(`resources.rules.conditions.${condition}`);
    } else {
      return '-';
    }
  };

  const receiptIcons = {
    [TransactionReceiptStatus.RECEIPT_PROVIDED]: receiptDownload,
    [TransactionReceiptStatus.RECEIPT_REQUIRED]: receiptRequired,
    [TransactionReceiptStatus.NO_RECEIPT_REQUIRED]: receiptNotRequired,
  };

  const ReceiptIcon: FC<ReceiptIconProps> = ({ receiptStatus, record, loading, getTransactionReceiptUrl }) => {
    const iconHref = receiptIcons[receiptStatus];
    const [updating, setUpdating] = useState(false);
    const refresh = useRefresh();

    const handleUpdateReceipt = async (transactionId: string, newStatus: string) => {
      setUpdating(true);
      try {
        await dataProvider.updateReceipt('transactions', {
          transactionId,
          data: { receiptStatus: newStatus },
        });
        notify('resources.transactions.updateStatus', { type: 'info' });
        refresh();
      } catch (error) {
        getErrorHandler(notify)(error);
      } finally {
        setUpdating(false);
      }
    };

    return (
      <Stack direction="row" alignItems="center" gap={2}>
        {receiptStatus === TransactionReceiptStatus.RECEIPT_PROVIDED ? (
          <button
            onClick={() => getTransactionReceiptUrl(record.id)}
            disabled={loading || updating}
            style={{ background: 'none', border: 'none', cursor: 'pointer', padding: 0 }}
          >
            <SvgIcon>
              <image href={iconHref} height="100%" width="100%" />
            </SvgIcon>
          </button>
        ) : (
          <SvgIcon>
            <image href={iconHref} height="100%" width="100%" />
          </SvgIcon>
        )}

        {receiptStatus === TransactionReceiptStatus.RECEIPT_PROVIDED && (
          <button
            onClick={() => handleUpdateReceipt(record.id, 'required')}
            disabled={updating}
            style={{ background: 'none', border: 'none', cursor: 'pointer', padding: 0 }}
          >
            <DeleteIcon />
          </button>
        )}

        {receiptStatus === TransactionReceiptStatus.RECEIPT_REQUIRED && (
          <Tooltip title="Reportar comprobante faltante" arrow>
            <button
              onClick={() => handleUpdateReceipt(record.id, 'not-required')}
              disabled={updating}
              style={{ background: 'none', border: 'none', cursor: 'pointer', padding: 0 }}
            >
              <ReportGmailerrorredIcon />
            </button>
          </Tooltip>
        )}
      </Stack>
    );
  };

  const downloadReceipt = async (url: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const contentType = response.headers.get('Content-Type') || undefined;
      const urlBlob = window.URL.createObjectURL(new Blob([blob], { type: contentType }));
      const link = document.createElement('a');
      link.href = urlBlob;
      link.setAttribute('download', 'receipt');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      void getErrorHandler(notify)(error);
    }
  };

  const getTransactionReceiptUrl = async (transactionId: string) => {
    setLoading(true);
    dataProvider
      .getTransactionReceipt('transactions', { transactionId })
      .then((response: any) => {
        void downloadReceipt(response.data.url);
        setLoading(false);
      })
      .catch((error: unknown) => {
        getErrorHandler(notify)(error);
        setLoading(false);
      });
  };

  const displayReceiptStatus = (record: Transaction) => {
    if (record?.type === 'cash-in') return null;

    const receiptStatus: TransactionReceiptStatus = record?.receiptStatus;
    return <ReceiptIcon receiptStatus={receiptStatus} record={record} loading={loading} getTransactionReceiptUrl={getTransactionReceiptUrl} />;
  };

  type ChangeHandler = (event: { target: any; type?: any }) => Promise<void | boolean>;

  const RuleFilter = (props: AutocompleteInputProps) => {
    const { filterValues } = useListContext();
    const { data = [], isLoading } = useGetList('rules');
    const choices = isLoading ? [] : data;

    const onRuleSelect = (_: ChangeEvent<HTMLInputElement>, newRuleName: string) => {
      filterValues.ruleName = newRuleName;
    };

    return (
      <AutocompleteInput
        {...props}
        label="Filtrar por regla"
        choices={choices}
        optionValue="id"
        optionText="name"
        onInputChange={onRuleSelect as ChangeHandler}
        noOptionsText="No existen reglas"
      />
    );
  };

  const transactionListFilters = [
    <RuleFilter alwaysOn source="ruleId" />,
    <CardHolderTagsFilter alwaysOn source="cardHolderTags" />,
    <StatusFilter alwaysOn source="status" />,
    <TxTypeFilter alwaysOn source="type" values={cardTransactionTypes} label="Tipo" />,
    <DateTimeFilter source="date" label="Fecha" />,
    <TextInput label="Nombre de colaborador/a" source="cardHolderNameStartsWith" />,
    <TextInput label="Apellido de colaborador/a" source="cardHolderSurnameStartsWith" />,
  ];

  const handleSendReceiptReminder = () => {
    dataProvider
      .sendReceiptRequiredReminder('transactions')
      .then(() => {
        notify('Se han enviado las solicitudes de comprobantes pendientes');
      })
      .catch(() => {
        notify('Error al enviar las solicitudes de comprobantes', { type: 'warning' });
      });
  };

  return (
    <List
      queryOptions={{ onError: getErrorHandler(notify) }}
      actions={
        <KuruListActions exporter="confirm" create={false} showFilters={true}>
          <Button onClick={handleSendReceiptReminder} label="Reclamar comprobantes pendientes" />
        </KuruListActions>
      }
      filters={transactionListFilters}
    >
      <Datagrid rowClick={false} bulkActionButtons={false}>
        <FunctionField label="Tarjeta" render={displayTxCardLink} />
        <FunctionField label="Tipo de tarjeta" render={DisplayCardType} />
        <FunctionField source="time" label="Fecha" render={displayDateTime} sortable={false} />
        <FunctionField label="Colaborador" render={displayCardHolder} />
        <FunctionField source="data" label="Monto" render={displayTxAmount} sortable={false} />
        <TextField source="merchantName" label="Comercio" emptyText="-" sortable={false} />
        <FunctionField label="Estado" render={displayStatus} />
        {/* // TODO replace with icons */}
        <FunctionField
          label={
            <SvgIcon>
              <image href={receiptColumn} height="100%" width="100%" />
            </SvgIcon>
          }
          render={displayReceiptStatus}
        />
        <FunctionField label="Tipo" render={({ type }: Record<string, string>) => translate('resources.transactions.type.' + type)} />
        <TextField source="extra" label="Info Adicional" emptyText="-" sortable={false} />
      </Datagrid>
    </List>
  );
};

const CardLink = (props: Omit<FieldProps, 'source'>) => {
  const record = useRecordContext(props);
  if (!record) return null;
  // Stop propagation so that the link will work without triggering the datagrid row's linking
  const stopPropagation = (e: MouseEvent<HTMLAnchorElement>) => e.stopPropagation();

  return (
    <Typography>
      <Link to={`/cards/${record.card.id}/show`} onClick={stopPropagation}>
        {record.card.lastFour}
      </Link>
    </Typography>
  );
};
