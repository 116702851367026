import React, { useEffect, useState } from 'react';
import { useInput, InputProps, NumberInput } from 'react-admin';
import './CustomInput.css';
import { useIntl } from 'react-intl';

const FormattedFloatInput: React.FC<InputProps> = ({ source, label, defaultValue = undefined, disabled, isRequired, ...props }) => {
  const { field } = useInput({ source, ...props });
  const [inputValue, setInputValue] = useState(defaultValue || '');
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(inputValue !== '');

  useEffect(() => {
    // Inicializa el valor de inputValue cuando field.value cambia o se recibe un nuevo defaultValue
    setInputValue(field.value || (defaultValue !== undefined ? defaultValue : ''));
  }, [field.value, defaultValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    // Remover todo lo que no sea números o una coma
    value = value.replace(/[^0-9,]/g, '');

    const parts = value.split(',');

    if (parts.length > 2) {
      return; // Si hay más de una coma, no hace nada?
    }

    // Formatear la parte entera con separadores de miles (.)
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const decimalPart = parts[1] !== undefined ? ',' + parts[1] : '';

    // Concatenar la parte formateada
    const formattedValue = integerPart + decimalPart;

    setInputValue(formattedValue);
    field.onChange(formattedValue); // Propagar el cambio al formulario
  };

  return (
    <div className={`custom-input-container ${isFocused || inputValue ? 'focused' : ''}`}>
      <input
        disabled={disabled}
        required={isRequired}
        type="text"
        className="custom-input-field"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder=" " // Placeholder vacío para mantener el espacio
        {...props}
      />
      <label className="custom-input-label">
        {label} {isRequired && <span className="required-asterisk">*</span>}
      </label>
    </div>
  );
};

export default FormattedFloatInput;

// Trying out this version that allows usage of regular validators and props.
// The value is a number, no need to convert from string.
// It overlays a formatted display version of the input value covering the actual input
// but only once the input is valid. The input allows some content that is still
// considered an empty value, try it out with '-,' or '-.-': the input will allow it
// but it won't remove the 'Required' validation error when visible, and on focus out
// the input label will cover that content as if the input was empty). To preserve
// ux and not hide leading minus signs or decimal separators, the overlay does not
// cover that (we can't pick that content up from input value to show it in the overlay)
export const AltFormattedFloatInput = (props: InputProps) => {
  const intl = useIntl();
  const { field } = useInput(props);
  const [currentValue, setCurrentValue] = useState(props.defaultValue);

  useEffect(() => {
    setCurrentValue(field.value);
  }, [field.value]);

  const isFormattable = (value: string | number) => {
    return typeof value === 'number';
  };

  const formatValue = (value: string | number) => {
    return isFormattable(value) ? intl.formatNumber(value as number) : value;
  };

  return (
    <NumberInput
      {...props}
      inputMode="decimal"
      sx={{
        '.MuiInputBase-root.MuiOutlinedInput-root': {
          '&::before': {
            position: 'absolute',
            background: `${isFormattable(currentValue) ? '#fff' : 'transparent'}`,
            content: `"${formatValue(currentValue)}"`,
            margin: '8.5px 14px',
            width: 'calc(100% - 28px)',
            height: 'calc(100% - 17px)',
            zIndex: '1',
          },
        },
      }}
    />
  );
};
