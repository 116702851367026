import Alert from '@mui/material/Alert';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { NotificationType, UseUpdateMutateParams } from 'react-admin';
import { KuruListFilter, KuruPermissions } from './types';
import { subMonths, lastDayOfMonth, startOfDay } from 'date-fns';

// A "Kuru user" is an internal company user, a Kuru employee.
// Platform users from client companies are called "Client users".
export const isKuruUser = (permissions: KuruPermissions): boolean => {
  return permissions?.is_kuru_admin || false;
};

export const set = (object: any, key: string, value: any) => {
  object[key] = value;
};

export const dateTimeLocaleString = (date: string | number) => {
  return new Date(date).toLocaleString('es');
};

export const downloadFile = (url: string, filename: string): void => {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  // @ts-ignore
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // Manage IE11+ & Edge
    // @ts-ignore
    // window.navigator.msSaveOrOpenBlob(blob, `${filename}.csv`);
    // TODO: check solution for IE11 / Edge
  } else {
    fakeLink.setAttribute('href', url);
    fakeLink.setAttribute('download', `${filename}.csv`);
    fakeLink.click();
  }
};

type Notify = (
  message: React.ReactNode,
  options?:
    | (NotificationOptions & {
        type?: NotificationType | undefined;
      })
    | undefined,
) => void;

type ErrorHandler = (error: unknown, variables?: UseUpdateMutateParams<any>, context?: unknown) => void;

// TODO: We should work with something like an error boundary
// https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
export const getErrorHandler = (notify: Notify) => {
  const accordionStyles = {
    '&.MuiPaper-root': {
      color: '#fff',
      background: 'none',
      borderRadius: '0',
      boxShadow: 'none',
      fontSize: '.75rem',
      padding: '.5rem 0',
      margin: '0',
    },
    '& .MuiAccordionSummary-root': {
      border: 'none',
      justifyContent: 'flex-start',
      alignContent: 'flex-start',
      padding: '0',
      minHeight: 'min-content',
      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
      '& .MuiAccordionSummary-content': {
        margin: '0',
        flexGrow: '0',
      },
    },
  };

  const alertStyles = {
    color: '#fff',
    backgroundColor: '#b32300',
    '& svg': { color: '#fff' },
  };

  const errorHandler: ErrorHandler = (error, _) => {
    console.debug(JSON.stringify(error));

    // TODO: provide more specific error messages
    const message = (
      <Alert severity="error" sx={alertStyles}>
        Hubo un error
        <Accordion sx={accordionStyles}>
          <AccordionSummary expandIcon={<ChevronRightIcon />}>Ver detalles del error</AccordionSummary>
          <AccordionDetails>{JSON.stringify(error)}</AccordionDetails>
        </Accordion>
      </Alert>
    );

    notify(message, { type: 'error', autoHideDuration: null } as NotificationOptions);
  };

  return errorHandler;
};

export const filtersDisplayString = (filterValues: KuruListFilter) => {
  if (!filterValues) return 'N/A';

  const { ruleId, ruleName, cardHolderTags } = filterValues;
  let displayString = '';

  if (ruleId) {
    displayString += `"${ruleName}"`;
  }

  if (cardHolderTags) {
    displayString += (displayString && ', ') + `"${cardHolderTags}"`;
  }

  return displayString;
};

export const regexesConfig = {
  names: {
    // 'names' regex based on Pomelo docs, indicating /^[A-Za-zÀ-ÿ0-9 -.,]+$/
    // (that's letters including accents, digits, space, and !"#$%&'()*+,-.)
    // Probably meant /^[A-Za-zÀ-ÿ0-9 .,-]+$/
    // (letters including accents, digits, space, dot, comma and hyphen)
    // since "," is contained in the " -." range (not sure about the language
    // they're using, but this fits most)
    p: /^[A-Za-zÀ-ÿ0-9 .,-]+$/,
    msg: 'Sólo letras, dígitos, espacio, .,-',
  },
  phonePomelo: {
    p: /^[0-9]+$/,
    msg: 'Permite sólo dígitos',
  },
  phoneIntlMobile: {
    // All numbers start with a plus (+) sign
    // If number is from Argentina, it must start with 549
    // If number is from Mexico, it must start with 521
    // If number is not from Arg or Mexico, it can be any country code (1 or two numbers),
    // followed by 6 to 14 digits
    p: /\+(549[^0][0-9]{7,14}|521[0-9]{7,14}|(?!54)(?!52)[0-9]{1,2}[0-9]{6,14})/,
    msg: 'El formato de teléfono móvil es +<pais>(9 si es Arg)<área><número>.',
  },
  taxId: {
    // eslint-disable-next-line no-useless-escape
    p: /^[A-Za-zÀ-ÿ0-9 \/.,-]+$/,
    msg: 'Sólo letras, dígitos, espacio, .,-/',
  },
  invalidAccents: {
    p: /^[^\u00C0-\u017F]+$/,
    msg: 'El correo electrónico no debe contener tildes o caracteres acentuados',
  },
};

export function getLastMonthDateRange() {
  const now = new Date();
  const inputDate = startOfDay(now);

  const toDate = inputDate.getTime();

  // Restar un mes a la fecha
  const lastMonthDate = subMonths(inputDate, 1);

  // Obtener el último día del mes pasado
  const lastDay = lastDayOfMonth(lastMonthDate);

  // Si la fecha original es mayor que el último día del mes pasado, usar el último día del mes pasado
  const finalDate = inputDate.getDate() > lastDay.getDate() ? lastDay : lastMonthDate;

  const fromDate = finalDate.getTime();

  return { fromDate, toDate };
}

// TODO: document format
export const displayDateTime = ({ time }: { time: string }) => dateTimeLocaleString(time);
