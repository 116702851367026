import { Auth0AuthProvider } from 'ra-auth-auth0';
import { Auth0Client } from '@auth0/auth0-spa-js';

export const createAuthClient = () => {
  return new Auth0Client({
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
    authorizationParams: {
      audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    },
  });
};

export const createAuthProvider = (authClient: Auth0Client) => {
  let loginRedirectUri;
  let logoutRedirectUri;

  if (import.meta.env.VITE_CF_PAGES_URL) {
    // We are in a CloudFlare DEV environment
    // VITE_CF_PAGES_URL stores the ephemeral domain for the build
    // VITE_CF_PAGES_BRANCH stores the git branch that triggered the build
    if (import.meta.env.VITE_CF_PAGES_BRANCH === 'main') {
      // this the stable 'dev' env (branch main), so we use the plain url without SHA
      const urlWithoutSHA = import.meta.env.VITE_CF_PAGES_URL.replace(/^[^.]+\./g, '');
      loginRedirectUri = `https://${urlWithoutSHA}/auth-callback`;
      logoutRedirectUri = `https://${urlWithoutSHA}`;
    } else {
      // we are in an ephimeral build, we use the full Cloudflare URL with git SHA
      loginRedirectUri = `${import.meta.env.VITE_CF_PAGES_URL}/auth-callback`;
      logoutRedirectUri = `${import.meta.env.VITE_CF_PAGES_URL}`;
    }
  } else {
    // we are either in a local or cloudflare production env, we use static env vars
    loginRedirectUri = import.meta.env.VITE_LOGIN_REDIRECT_URL;
    logoutRedirectUri = import.meta.env.VITE_LOGOUT_REDIRECT_URL;
  }

  console.log(`CF_PAGES_BRANCH: ${import.meta.env.VITE_CF_PAGES_BRANCH}`);
  console.log(`CF_PAGES_URL: ${import.meta.env.VITE_CF_PAGES_URL}`);
  console.log(`loginURL: ${loginRedirectUri}`);
  console.log(`logoutURL: ${logoutRedirectUri}`);

  const raAuth0AuthProvider = Auth0AuthProvider(authClient, {
    loginRedirectUri: loginRedirectUri,
    logoutRedirectUri: logoutRedirectUri,
  });

  const authProvider = {
    ...raAuth0AuthProvider,
    async getPermissions() {
      if (!(await authClient.isAuthenticated())) {
        return;
      }

      const claims = await authClient.getIdTokenClaims();

      if (claims && 'is_kuru_admin' in claims && 'company_access' in claims) {
        const { is_kuru_admin, company_access } = claims;

        return {
          is_kuru_admin: is_kuru_admin,
          company_access: company_access || {},
        };
      }

      await authClient.logout({ logoutParams: { returnTo: logoutRedirectUri } });
      return undefined;
    },
  };

  return authProvider;
};
