import { GetListParams, GetListResult } from 'react-admin';
import { getQueryString } from '../../dataProvider/utils';
import { ResourceHandlerContext } from '../../dataProvider/types';
import { Card } from '.';
import { SchedulingParams } from '../../components/SchedulingControls';
import get from 'lodash.get';
import { createCronExpression } from '../scheduledOperations';

export async function getList(context: ResourceHandlerContext, companyId: string, params: GetListParams): Promise<GetListResult<Card>> {
  const filterQueryParams = [];
  const ruleId: string = params?.filter?.ruleId;
  const cardHolderTags: string[] = params?.filter?.cardHolderTags?.trim().split(' ');

  if (ruleId) {
    filterQueryParams.push('ruleId=' + ruleId);
  }

  if (cardHolderTags) {
    for (const tag of cardHolderTags) {
      const trimmedTag = tag.trim();
      if (trimmedTag) {
        filterQueryParams.push('cardHolderTags=' + trimmedTag);
      }
    }
  }

  const cardHolderNameStartsWith = params?.filter?.cardHolderNameStartsWith?.trim();
  if (cardHolderNameStartsWith) {
    filterQueryParams.push('cardHolderNameStartsWith=' + cardHolderNameStartsWith);
  }

  const cardHolderSurnameStartsWith = params?.filter?.cardHolderSurnameStartsWith?.trim();
  if (cardHolderSurnameStartsWith) {
    filterQueryParams.push('cardHolderSurnameStartsWith=' + cardHolderSurnameStartsWith);
  }

  const { json } = await context.request(`companies/${companyId}/cards` + getQueryString(params?.pagination, filterQueryParams), {});
  const response: { total: number; cards: Card[] } = json;
  return {
    total: response.total,
    data: response.cards,
  };
}

export async function requestExport(context: ResourceHandlerContext, companyId: string): Promise<Record<string, any>> {
  const { json } = await context.request(`companies/${companyId}/jobs/export-cards`, {
    method: 'POST',
  });

  return { data: json };
}

export type IncreaseBalanceParams = {
  ids?: string[];
  filters?: {
    ruleId?: string;
    cardHolderTags?: string;
  };
  operation: {
    type: string;
    amount?: number;
  };
  schedule?: SchedulingParams;
};
type IncreaseBalanceParamsForApi = Pick<IncreaseBalanceParams, 'operation'> & {
  query: {
    ids?: string[];
    filters?: QueryFilters;
  };
  schedule?: {
    expr: string;
    title: string;
  };
};
type QueryFilters = {
  ruleId?: string;
  cardHolderTags?: string[];
};
export async function increaseBalance(context: ResourceHandlerContext, companyId: string, params: IncreaseBalanceParams) {
  const getRequestBody = ({ ids, filters = {}, operation, schedule }: IncreaseBalanceParams): IncreaseBalanceParamsForApi => {
    const body: IncreaseBalanceParamsForApi = { query: {}, operation };
    const anyFilterIsApplied = Object.keys(filters).some((filterName) => get(filters, filterName).length > 0);

    if (ids && ids.length > 0) {
      body.query = { ids };
    } else if (anyFilterIsApplied) {
      // const { ruleId, cardHolderTags, cardHolderNameStartsWith, cardHolderSurnameStartsWith } = filters;
      const { ruleId, cardHolderTags } = filters;

      const queryFilters: Record<string, any> = {};

      if (ruleId) queryFilters.ruleId = ruleId;

      /* Temporarily commented-out, not supported yet
        if (cardHolderNameStartsWith) queryFilters.cardHolderNameStartsWith = cardHolderNameStartsWith;
        if (cardHolderSurnameStartsWith) queryFilters.cardHolderSurnameStartsWith = cardHolderSurnameStartsWith;
       */

      if (cardHolderTags) {
        const tags: string[] = cardHolderTags.trim().split(' ');
        const trimmedTags = [];

        for (const tag of tags) {
          const trimmedTag = tag.trim();
          if (trimmedTag) {
            trimmedTags.push(trimmedTag);
          }
        }

        queryFilters.cardHolderTags = trimmedTags;
      }

      body.query = { filters: queryFilters };
    } else {
      throw Error('No ids or filter values provided for bulk balance update');
    }

    if (schedule) {
      // TODO: move this logic to the backend, closer the implementation and the library that requires this transformation.
      // Keep the API agnostic of the backend solution, let the frontend handle the parameters as its libraries and the
      // users use them
      const { time, date, lastDoM, repeatMonthly, schedulingEntryName } = schedule;
      const expr = createCronExpression({ time, date, repeatLastDay: lastDoM, repeatMonthly, title: schedulingEntryName });

      body.schedule = { expr, title: schedulingEntryName };
    }

    return body;
  };

  return context
    .request(`companies/${companyId}/actions/distribute`, {
      method: 'POST',
      body: JSON.stringify(getRequestBody(params)),
    })
    .then((response) => {
      return {
        data: response,
      };
    });
}
