import { Card } from '../cards';
import { CardHolder } from '../cardHolders';

export enum TransactionReceiptStatus {
  NO_RECEIPT_REQUIRED = 'not-required',
  RECEIPT_PROVIDED = 'provided',
  RECEIPT_REQUIRED = 'required',
}

export enum TransactionStatus {
  APPROVED = 'approved',
  REJECTED_BY_RULE = 'rejected-by-rule',
  REJECTED_CARD_IS_DISABLED = 'card-disabled',
  REJECTED_INSUFFICIENT_FUNDS = 'insufficent-fund',
  REJECTED_INVALID_AMOUNT = 'invalid-amount',
  REJECTED_INVALID_MERCHANT = 'invalid-merchant',
  REJECTED_SYSTEM_ERROR = 'system-error',
}

export enum Currency {
  ARS = 'ARS',
  USD = 'USD',
}

export enum TxDetailType {
  BASE = 'BASE',
  FEE = 'FEE',
  TAX = 'TAX',
  EXTRACASH = 'EXTRACASH',
  ATM_FEE = 'ATM_FEE',
  GRATUITY = 'GRATUITY',
}

export type PomeloTxData = {
  amount: {
    local: {
      currency: Currency;
      total: number;
    };
    details: [
      {
        amount: string;
        currency: Currency;
        name: string;
        type: TxDetailType;
      },
    ];
    settlement: {
      currency: Currency;
      total: string;
    };
    transaction: {
      currency: Currency;
      total: number;
    };
  };
  card: Record<string, any>;
  extra_data: Record<string, any>;
  merchant: Record<string, any>;
  transaction: Record<string, any>;
  user: Record<string, any>;
};

export type Transaction = {
  amount: number;
  card: Pick<Card, 'id' | 'lastFour' | 'type'>;
  cardHolder: Pick<CardHolder, 'name' | 'surname' | 'tags'>;
  data: PomeloTxData;
  id: string;
  merchantName: string;
  receiptStatus: TransactionReceiptStatus;
  rejectReasonConditionName: string;
  rejectReasonRuleName: string;
  ruleName: string;
  status: TransactionStatus;
  time: number;
  type: TransactionType;
};

export enum TransactionType {
  CASH_IN = 'cash-in',
  CREDIT = 'credit',
  DEBIT = 'debit',
  DISTRIBUTION = 'distribution',
  PURCHASE = 'purchase',
  WITHDRAWAL = 'withdrawal',
  EXTRACASH = 'extracash',
  GIFT_CARD_CREATION = 'gift-card-creation',
}

export type TransactionRequestFilters = {
  cardHolderTags?: string;
  fromDate?: string;
  status?: string;
  toDate?: string;
  type?: string;
  cardHolderNameStartsWith?: string;
  cardHolderSurnameStartsWith?: string;
  ruleId?: string;
};

export type TransactionRequestFiltersForApi = Omit<TransactionRequestFilters, 'cardHolderTags' | 'toDate' | 'fromDate' | 'type'> & {
  cardHolderTags?: string[];
  fromDate?: number;
  toDate?: number;
  type?: string[];
};

export type ReceiptIconProps = {
  getTransactionReceiptUrl: (transactionId: string) => Promise<void>;
  loading: boolean;
  receiptStatus: TransactionReceiptStatus;
  record: Transaction;
};

export const cardTransactionTypes = [
  TransactionType.PURCHASE,
  TransactionType.CREDIT,
  TransactionType.DEBIT,
  TransactionType.DISTRIBUTION,
  TransactionType.WITHDRAWAL,
  TransactionType.EXTRACASH,
];
export const companyTransactionTypes = [TransactionType.CASH_IN, TransactionType.DISTRIBUTION, TransactionType.GIFT_CARD_CREATION];
export const kuruTransactionTypes = [TransactionType.CASH_IN, TransactionType.DISTRIBUTION];
