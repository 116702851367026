import { useRecordContext } from 'react-admin';

import Chip from '@mui/material/Chip';

// Show an array of strings as chips
const ArrayChipsField = ({ source }: { source: string }) => {
  const record = useRecordContext();
  if (!record) return;
  const items = getItems(record, source);
  if (!items) return;

  const chips = items.map((item: string) => <Chip label={item} size="small" />);

  // NOTE: not using `{ items.map(...) }` directly below as it causes "no unique key" error
  return <>{...chips}</>;
};

// "source" supports multiple levels within record, dot-separated
// eg: 'name' for record[name], 'names.first' for record[names][first]
// returns undefined if not found
const getItems = (record: Record<string, any>, source: string): string[] => {
  const items = source.split('.').reduce((accumulator: any, currentValue: string) => (accumulator && accumulator[currentValue]) || undefined, record);
  return items;
};

export default ArrayChipsField;
