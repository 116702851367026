import { Datagrid, FunctionField, List, TextField, useDataProvider, useNotify, useRecordContext, useRefresh, Confirm } from 'react-admin';
import { getErrorHandler } from '../../utils';
import { Stack, Box, TextField as MuiTextField, Checkbox, FormControlLabel, Button } from '@mui/material';
import ConditionsChipsField from './ConditionsChipsField';
import { useState } from 'react';
import { FormDataOperation } from './types';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { createCronExpression } from '.';

export const ScheduledOperationsList = () => {
  const notify = useNotify();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState<string | null>(null);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    event.stopPropagation();
    setSelectedOperation(id);
    setShowDeleteConfirmation(true);
  };

  const handleClose = () => {
    setShowDeleteConfirmation(false);
    setSelectedOperation(null);
  };

  const handleConfirmDelete = async () => {
    if (!selectedOperation) return;
    try {
      await dataProvider.delete('scheduledJobs', { id: selectedOperation });
      notify('resources.scheduledJobs.success.delete');
      refresh();
    } catch (_) {
      notify('resources.scheduledJobs.errors.delete');
    } finally {
      handleClose();
    }
  };

  return (
    <List queryOptions={{ onError: getErrorHandler(notify) }} exporter={false}>
      <Datagrid bulkActionButtons={false} expand={<EditRowForm />}>
        <TextField source="title" label="Titulo" sortable={false} />
        <FunctionField label="Condiciones" render={(record) => <ConditionsChipsField operation={record.data.operation} expr={record.expr} />} />
        <FunctionField
          source="id"
          label="Eliminar"
          sortable={false}
          render={(record) => (
            <Tooltip title="Eliminar acción">
              <IconButton onClick={(event) => handleDeleteClick(event, record.id)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        />
      </Datagrid>
      <Confirm
        isOpen={showDeleteConfirmation}
        title="Confirmar eliminación"
        content={
          <Box>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              ¿Estás seguro de que deseás eliminar esta acción programada?
            </Typography>
          </Box>
        }
        onConfirm={handleConfirmDelete}
        onClose={handleClose}
      />
    </List>
  );
};

const EditRowForm = () => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext();
  const [formData, setFormData] = useState<FormDataOperation>({
    title: record?.title || '',
    date: '',
    time: '',
    repeatLastDay: false,
    repeatMonthly: false,
  });
  const [errors, setErrors] = useState({
    date: false,
    time: false,
    title: false,
  });

  // Manejar cambios en los inputs
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
      ...(name === 'repeatLastDay' && checked ? { date: '', repeatMonthly: false } : {}),
      ...(name === 'repeatMonthly' && checked ? { repeatLastDay: false } : {}),
    }));
    if (errors[name as keyof typeof errors]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const cronExpression = createCronExpression(formData);
    const hasErrors = {
      date: !formData.date && !formData.repeatLastDay,
      time: !formData.time,
      title: !formData.title,
    };

    if (hasErrors.date || hasErrors.time || hasErrors.title) {
      setErrors(hasErrors);
      return;
    }

    const operationBody = {
      expr: cronExpression,
      title: formData.title,
    };

    try {
      await dataProvider.editOperationSchedule(operationBody, record?.id);
      notify('resources.scheduledJobs.success.save');

      refresh();
    } catch (error) {
      getErrorHandler(notify)(error, { resource: 'scheduledOperations' });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={1} sx={{ p: 2 }}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
          <MuiTextField
            label="Título"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            size="small"
            error={errors.title}
            helperText={errors.title ? 'El título es requerido' : ''}
            FormHelperTextProps={{
              sx: {
                position: 'absolute',
                top: '100%',
                left: 0,
                transform: 'translateY(4px)',
                fontSize: '0.75rem',
                color: 'red',
              },
            }}
          />
          <MuiTextField
            label="Hora"
            name="time"
            type="time"
            value={formData.time}
            onChange={handleInputChange}
            size="small"
            InputLabelProps={{ shrink: true }}
            sx={{ width: '22%', marginBottom: errors.time ? '20px' : '10px' }}
            error={errors.time}
            helperText={errors.time ? 'La hora es requerida' : ''}
            FormHelperTextProps={{
              sx: {
                position: 'absolute',
                top: '100%',
                left: 0,
                transform: 'translateY(4px)',
                fontSize: '0.75rem',
                color: 'red',
              },
            }}
          />
          <MuiTextField
            label="Fecha"
            name="date"
            type="date"
            value={formData.date}
            onChange={handleInputChange}
            size="small"
            InputLabelProps={{ shrink: true }}
            FormHelperTextProps={{
              sx: {
                position: 'absolute',
                top: '100%',
                left: 0,
                transform: 'translateY(4px)',
                fontSize: '0.75rem',
                color: 'red',
              },
            }}
            sx={{ width: '22%' }}
            error={errors.date}
            helperText={errors.date ? 'La fecha es requerida' : ''}
            disabled={formData.repeatLastDay} // Deshabilitar si "Repetir último día" está activo
          />

          <FormControlLabel
            control={<Checkbox name="repeatLastDay" checked={formData.repeatLastDay} onChange={handleInputChange} />}
            label="Repetir último día del mes"
            sx={{ whiteSpace: 'nowrap' }}
          />
          <FormControlLabel
            control={<Checkbox name="repeatMonthly" checked={formData.repeatMonthly} onChange={handleInputChange} />}
            label="Repetir mensualmente"
            sx={{ whiteSpace: 'nowrap' }}
          />
          <Button variant="contained" color="primary" type="submit" size="small" sx={{ width: '15%' }}>
            Guardar
          </Button>
        </Stack>
        <Box sx={{ pt: 1, fontSize: '0.875rem', color: 'gray', paddingTop: errors.date || errors.time || errors.title ? '5%' : '1%' }}>
          Si no está marcado ningún check, la acción solo se disparará una vez en la fecha seleccionada.
        </Box>
      </Stack>
    </form>
  );
};
