import { Card } from './types';

export * from './cards';
export * from './types';

export const displayString = (card: Card): string => {
  return `xxxx-xxxx-xxxx-${card.lastFour}`;
};

export type ScheduledDistributionData = {
  filters: {
    ids: string[];
  };
  operation: {
    type: string;
    amount?: number;
  };
};
export const scheduledDistributionNotification = (scheduledDistributionData: ScheduledDistributionData) => {
  console.debug(scheduledDistributionData);
  return '';
};
