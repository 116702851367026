import { filtersDisplayString, getErrorHandler } from '../../utils';
import { Button, useDataProvider, useNotify, useRecordContext, useRefresh } from 'react-admin';
import { useEffect, useState } from 'react';
import { BulkActionParams } from '../../types';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Drawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { KuruForm, KuruFormattedNumberInput, KuruSelectInput, required as setRequired, useFieldWatch, KuruFormContext } from '../../components/KuruForm';
import { SchedulingControls, SchedulingParams } from '../../components/SchedulingControls';

export const BulkUpdateBalanceButton = ({ selectedIds, filterValues, useFullFilterResults = false }: BulkActionParams) => {
  const [showUpdateBalance, setShowUpdateBalance] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const incompatibleFilters = ['cardHolderNameStartsWith', 'cardHolderSurnameStartsWith'];
    const appliedFiltersNames = Object.keys(filterValues);
    const incompatibleFiltersAreApplied = incompatibleFilters.some((filter) => appliedFiltersNames.includes(filter));
    setIsDisabled(incompatibleFiltersAreApplied && !!useFullFilterResults);
  }, [filterValues, setIsDisabled, useFullFilterResults]);

  const targets = getSelectionParams({ ids: selectedIds, filterValues, useFullFilterResults });
  const whenDone = () => {
    setShowUpdateBalance(false);
  };
  const description = (
    <div>
      Vas a modificar el saldo de {selectedIds.length} tarjetas
      {useFullFilterResults && ` (todas aquellas que coinciden con el filtro: ${filtersDisplayString(filterValues)})`}.
    </div>
  );

  return (
    <>
      <Tooltip title={isDisabled ? 'Acción no disponible seleccionando todos los resultados por nombre o apellido de colaborador' : false}>
        {/* span provides anchorage for the tooltip */}
        <span>
          <Button disabled={isDisabled} label="Modificar saldo" onClick={() => setShowUpdateBalance(true)} startIcon={<AttachMoneyIcon />} />
        </span>
      </Tooltip>
      <Drawer className="actionDrawer" open={showUpdateBalance} anchor="right" onClose={() => setShowUpdateBalance(false)}>
        <CardBalanceUpdate {...{ whenDone, targets, description }} />
      </Drawer>
    </>
  );
};

export const SingleUpdateBalanceButton = () => {
  const [showUpdateBalance, setShowUpdateBalance] = useState(false);
  const record = useRecordContext();
  if (!record) return null;

  const targets = { ids: [record.id as string] };

  const whenDone = () => {
    setShowUpdateBalance(false);
  };

  return (
    <>
      <Button label="Modificar saldo" onClick={() => setShowUpdateBalance(true)} startIcon={<AttachMoneyIcon />} />
      <Drawer className="actionDrawer" open={showUpdateBalance} anchor="right" onClose={() => setShowUpdateBalance(false)}>
        <CardBalanceUpdate {...{ targets, whenDone }} />
      </Drawer>
    </>
  );
};

type GetSelectionParamsArgs = {
  ids: string[];
  filterValues: Record<string, any>;
  useFullFilterResults: boolean | undefined;
};
type CardsSelection = {
  ids?: string[];
  filters?: Record<string, any>;
};
const getSelectionParams = ({ ids, filterValues, useFullFilterResults }: GetSelectionParamsArgs): CardsSelection => {
  type ApiParams = Partial<{ ids: string[]; filters: Record<string, any>; operation: Record<string, string | number> }>;
  const params: ApiParams = {};

  if (useFullFilterResults) {
    params.filters = filterValues;
  } else {
    params.ids = ids;
  }

  return params;
};

type CardBalanceUpdateProps = {
  targets: CardsSelection;
  whenDone: () => void;
  description?: JSX.Element;
};
const CardBalanceUpdate = ({ targets, whenDone, description }: CardBalanceUpdateProps) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSubmit = ({ operationType, amount, enableScheduling, ...otherParams }: Record<string, any>) => {
    const operation = {
      type: operationType,
      ...(operationType !== 'ZERO' && { amount }),
    };

    let schedule;
    if (enableScheduling) {
      const { date, time, repeatMonthly, lastDoM, schedulingEntryName } = otherParams as SchedulingParams;
      schedule = { date, time, repeatMonthly, lastDoM, schedulingEntryName };
    }

    dataProvider
      .increaseBalance('cards', {
        ...targets,
        operation,
        schedule,
      })
      .then(() => {
        notify(enableScheduling ? 'resources.cards.scheduleUpdateMany.balance' : 'resources.cards.updateMany.balance');
        whenDone();
        refresh();
      })
      .catch((error: unknown) => getErrorHandler(notify)(error, { resource: 'cards' }));
  };

  const defaultValues = {
    operationType: '',
    amount: '',
    ...SchedulingControls.defaultValues,
  };
  return (
    <>
      <h3>Modificar saldo a tarjetas</h3>
      {description}
      <KuruForm defaultValues={defaultValues} onSubmit={onSubmit} className="distributionForm">
        {(form) => <DistributionForm form={form} />}
      </KuruForm>
    </>
  );
};

const DistributionForm = ({ form }: { form: KuruFormContext }) => {
  const operationTypeValue = useFieldWatch(form.control, 'operationType');
  const operationTypeChoices = [
    { id: 'SET', name: 'Llevar el saldo al monto indicado' },
    { id: 'INCREMENT', name: 'Incrementar el saldo en el monto indicado' },
    { id: 'DECREMENT', name: 'Disminuir el saldo en el monto indicado' },
    { id: 'ZERO', name: 'Llevar el saldo a cero' },
  ];

  return (
    <>
      <Stack>
        <KuruSelectInput name="operationType" label="Tipo de operación *" choices={operationTypeChoices} rules={setRequired()} />
      </Stack>
      <Stack>
        <KuruFormattedNumberInput
          name="amount"
          label="Monto *"
          defaultValue={operationTypeValue === 'ZERO' ? 0 : undefined}
          disabled={operationTypeValue === 'ZERO'}
          rules={setRequired()}
        />
      </Stack>
      <SchedulingControls form={form} />
    </>
  );
};
