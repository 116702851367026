export type GiftCard = {
  id: string;
  cardHolderId: string;
  balance: number;
  isActive: boolean;
  lastFour: string;
  type: GiftCardType;
};

export enum GiftCardType {
  VIRTUAL = 'virtual',
  PHYSICAL = 'physical',
}
