import { useEffect, useState } from 'react';
import { Menu, usePermissions, useDataProvider, useNotify } from 'react-admin';

import SupportIcon from '@mui/icons-material/Support';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ListIcon from '@mui/icons-material/List';

import { getErrorHandler, isKuruUser } from '../utils';
import KuruArrowIcon from './KuruArrowIcon';
import { isImpersonating } from './Impersonation';

export const KuruMenu = () => {
  const { permissions } = usePermissions();
  const currentIsKuruUser = isKuruUser(permissions);

  const [companyName, setCompanyName] = useState<string>();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    if (!currentIsKuruUser || isImpersonating()) {
      dataProvider
        .getCurrentCompanyName()
        .then((name: string) => setCompanyName(name))
        .catch((error: any) => getErrorHandler(notify)(error));
    }
  }, [currentIsKuruUser, dataProvider, notify]);

  if (!permissions) return null;

  const companyNameSeparator = <Menu.Item primaryText={companyName} to="" />;
  const kuruUserMenuItems = [
    <Menu.ResourceItem name="companies" />,
    <Menu.Item to="/compliance-lists" primaryText="Chequeo de listas" leftIcon={<ListIcon />} />,
  ];
  const clientUserMenuItems = [
    <Menu.DashboardItem primaryText="Dashboard" />,
    <Menu.Item to="/my-kuru" primaryText="Mi Kuru" leftIcon={<KuruArrowIcon />} />,
    <Menu.ResourceItem name="notifications" />,
    <Menu.ResourceItem name="cardHolders" />,
    <Menu.ResourceItem name="cards" />,
    <Menu.ResourceItem name="rules" />,
    <Menu.ResourceItem name="transactions" />,
    <Menu.ResourceItem name="administrators" />,
    <Menu.ResourceItem name="scheduledJobs" />,
  ];
  const menuItems = currentIsKuruUser
    ? companyName
      ? kuruUserMenuItems.concat(companyNameSeparator, clientUserMenuItems)
      : kuruUserMenuItems
    : companyName
      ? [companyNameSeparator].concat(clientUserMenuItems)
      : clientUserMenuItems;

  return (
    <Menu>
      <div>{...menuItems}</div>
      <div className="support-items">
        <Menu.Item to="/support" leftIcon={<SupportIcon />} primaryText="Preguntas frecuentes" />
        <Menu.Item onClick={() => window.zE('messenger', 'open')} to="#" leftIcon={<ContactSupportIcon />} primaryText="Soporte" />
      </div>
    </Menu>
  );
};
