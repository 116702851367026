import { datadogRum } from '@datadog/browser-rum';

const ddogEnvironment = import.meta.env.VITE_DD_ENV;

console.log(`datadog env: ${ddogEnvironment}`);

if (ddogEnvironment) {
  datadogRum.init({
    applicationId: '851805e3-0d9c-4512-a33d-1d49bff948fe',
    clientToken: 'pub492aa0ad6569f335336a2fc4c1f84d51',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'kuru-admin',
    env: ddogEnvironment,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
